import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {INotestimSettingsApi} from '@models/notestim/notestim.interfaces';

@Injectable({providedIn: 'root'})
export class NotestimApiService {
    private _modelApiService = inject(ModelApiService);

    getSettings$(): Observable<INotestimSettingsApi> {
        return this._modelApiService.get$('/notestim/settings');
    }
}
