import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {BrowserGuard} from '@core/routing/guards/browser/browser-guard.service';
import {AuthRoutingModule} from '@core/routing/auth/auth-routing.module';
import {AppLayoutComponent} from '@core/routing/layout/layout.component';
import {CommonModule} from '@angular/common';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';
import {NgEskimmoBrowserComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-browser.component';
import {AuthGuard} from '@core/auth/core/auth-guard.service';
import {AppAuthenticatedComponent} from '@core/routing/authenticated/authenticated.component';
import {NgAuthStartComponent} from '@shared/angularJS/up-ng/auth/auth.start.component';
import {StartGuard} from '@core/routing/guards/start/start-guard.service';
import {
    AppLayoutAnnuaireEtudesComponent
} from '@core/routing/layout/annuaires/annuaire/etudes/layout-annuaire-etudes.component';
import {
    AppLayoutAnnuaireEtudeComponent
} from '@core/routing/layout/annuaires/annuaire/etudes/etude/layout-annuaire-etude.component';
import {
    AppLayoutAnnuaireUsersComponent
} from '@core/routing/layout/annuaires/annuaire/users/layout-annuaire-users.component';
import {AppLayoutBonvisitesComponent} from '@core/routing/layout/bonvisites/layout-bonvisites.component';
import {AppLayoutBonvisiteComponent} from '@core/routing/layout/bonvisites/bonvisite/layout-bonvisite.component';
import {
    AppLayoutBonvisiteEditComponent
} from '@core/routing/layout/bonvisites/bonvisite/edit/layout.bonvisite-edit.component';
import {
    AppLayoutBonvisiteWriteComponent
} from '@core/routing/layout/bonvisites/bonvisite/write/layout.bonvisite-write.component';
import {
    AppLayoutBonvisiteResumeComponent
} from '@core/routing/layout/bonvisites/bonvisite/resume/layout.bonvisite-resume.component';
import {
    AppLayoutBonvisiteSignComponent
} from '@core/routing/layout/bonvisites/bonvisite/sign/layout.bonvisite-sign.component';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import User from '@models/users/user/user.model';
import {NgEskimmoDashboardComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dashboard.component';
import {DemandeursRoutingModule} from '@core/routing/layout/demandeurs/demandeurs-routing.module';
import {AppLayoutDocumentsComponent} from '@core/routing/layout/documents/layout-documents.component';
import {
    NgEskimmoDocumentsAdvertisementComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-documents-advertisement.component';
import {NgEskimmoDocumentsBlankComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-documents-blank.component';
import {
    EstimationsRoutingModule
} from '@core/routing/layout/estimations/estimations-routing.module';
import {LocationsRoutingModule} from '@core/routing/layout/locations/locations-routing.module';
import {NgEskimmoMyAccountComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-my-account.component';
import {
    NgEskimmoNegociationRevenusComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-negociation-revenus.component';
import {AppLayoutOffreachatsComponent} from '@core/routing/layout/offreachats/layout-offreachats.component';
import {AppLayoutOffreachatComponent} from '@core/routing/layout/offreachats/offreachat/layout-offreachat.component';
import {
    AppLayoutOffreachatEditComponent
} from '@core/routing/layout/offreachats/offreachat/edit/layout.offreachat-edit.component';
import {
    AppLayoutOffreachatWriteComponent
} from '@core/routing/layout/offreachats/offreachat/write/layout.offreachat-write.component';
import {
    AppLayoutOffreachatResumeComponent
} from '@core/routing/layout/offreachats/offreachat/resume/layout.offreachat-resume.component';
import {
    AppLayoutOffreachatSignComponent
} from '@core/routing/layout/offreachats/offreachat/sign/layout.offreachat-sign.component';
import {AppLayoutProceduresComponent} from '@core/routing/layout/procedures/layout-procedures.component';
import {AppLayoutProcedureComponent} from '@core/routing/layout/procedures/procedure/layout-procedure.component';
import {
    ProcedureSignataireSignActionsMainComponent
} from '@features/procedures/procedure/signataires/signataire/sign/actions/procedure-signataire.sign-actions-main.component';
import {VentesRoutingModule} from '@core/routing/layout/ventes/ventes-routing.module';
import {
    AppLayoutBonvisitesWalletComponent
} from '@core/routing/layout/bonvisites/wallet/layout-bonvisites-wallet.component';
import {
    AppLayoutDossierFilesComponent
} from '@core/routing/layout/dossiers/dossier/files/layout-dossier-files.component';
import {
    AppLayoutDossierInotExportComponent
} from '@core/routing/layout/dossiers/dossier/inot/export/layout-dossier-inot-export.component';
import {
    AppLayoutOffreachatsWalletComponent
} from '@core/routing/layout/offreachats/wallet/layout-offreachats-wallet.component';
import {FeaturesModule} from '@features/features.module';
import {BiensRoutingModule} from '@core/routing/layout/biens/biens-routing.module';
import {DiffusionsRoutingModule} from '@core/routing/layout/diffusions/diffusions-routing.module';
import {AppLayoutActualitesComponent} from '@core/routing/layout/actualites/layout-actualites.component';
import {ActualitesService} from '@models/actualites/actualites.service';
import {
    AppLayoutOffreachatSignatureComponent
} from '@core/routing/layout/offreachats/offreachat/signature/layout.offreachat-signature.component';
import {NgContactsWalletCurrentComponent} from '@shared/angularJS/up-ng/contacts/contacts.wallet-current.component';
import {
    AppLayoutAnnuaireContactsComponent
} from '@core/routing/layout/annuaires/annuaire/contacts/layout-annuaire-contacts.component';
import {ConfigurationRoutingModule} from '@core/routing/layout/configuration/configuration-routing.module';

const routes: Routes = [
    {
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) => inject(BrowserGuard).canActivate(activatedRouteSnapshot, routerStateSnapshot),
        ],
        path: '',
        children: [
            {loadChildren: () => AuthRoutingModule, path: 'auth'},
            {component: NgEskimmoBrowserComponent, path: 'browser'},
            {
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) => inject(AuthGuard).canActivate(activatedRouteSnapshot, routerStateSnapshot),
                ],
                component: AppAuthenticatedComponent,
                path: '',
                children: [
                    {component: NgAuthStartComponent, path: 'start'},
                    {
                        canActivate: [
                            (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) => inject(StartGuard).canActivate(activatedRouteSnapshot, routerStateSnapshot),
                        ],
                        component: AppLayoutComponent,
                        path: 'app',
                        children: [
                            {
                                path: 'news',
                                component: AppLayoutActualitesComponent,
                            },
                            {
                                path: 'annuaires',
                                children: [
                                    {
                                        path: 'contacts',
                                        component: AppLayoutAnnuaireContactsComponent,
                                        children: [
                                            {component: NgContactsWalletCurrentComponent, path: 'portefeuille'},
                                            // Wildcard pour /app/annuaires/contacts/*
                                            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                                        ],
                                    },
                                    {
                                        path: 'etudes',
                                        children: [
                                            {component: AppLayoutAnnuaireEtudesComponent, path: ''},
                                            {component: AppLayoutAnnuaireEtudeComponent, path: ':etudeUuid'},
                                            // Wildcard pour /app/annuaires/etudes/*
                                            {path: '**', pathMatch: 'full', redirectTo: ''},
                                        ],
                                    },
                                    {
                                        path: 'utilisateurs',
                                        component: AppLayoutAnnuaireUsersComponent,
                                    },
                                    // Wildcard pour /app/annuaires/*
                                    {path: '**', pathMatch: 'full', redirectTo: 'contacts'},
                                ],
                            },
                            {
                                component: AppLayoutBonvisitesComponent,
                                path: 'bons-visite',
                                children: [
                                    {
                                        component: AppLayoutBonvisiteComponent,
                                        path: ':bonvisiteUuid',
                                        children: [
                                            {component: AppLayoutBonvisiteEditComponent, path: 'edit'},
                                            {component: AppLayoutBonvisiteWriteComponent, path: 'redaction'},
                                            {component: AppLayoutBonvisiteResumeComponent, path: 'resume'},
                                            {component: AppLayoutBonvisiteSignComponent, path: 'signature'},
                                        ],
                                    },
                                    // Wildcard pour /app/bons-visite/*
                                    {path: '**', pathMatch: 'full', redirectTo: ''},
                                ],
                            },
                            {loadChildren: () => ConfigurationRoutingModule, path: 'configuration'},
                            {
                                component: NgEskimmoDashboardComponent,
                                path: 'dashboard',
                                resolve: {news: () => inject(ActualitesService).show$()},
                            },
                            {loadChildren: () => DemandeursRoutingModule, path: 'demandeurs'},
                            {loadChildren: () => DiffusionsRoutingModule, path: 'diffusions'},
                            {
                                component: AppLayoutDocumentsComponent,
                                path: 'documents',
                                children: [
                                    {component: NgEskimmoDocumentsAdvertisementComponent, path: 'advertisement'},
                                    {component: NgEskimmoDocumentsBlankComponent, path: 'blank'},
                                    // Wildcard pour /app/documents
                                    {path: '**', pathMatch: 'full', redirectTo: '/app/documents/blank'},
                                ],
                            },
                            {loadChildren: () => EstimationsRoutingModule, path: 'estimations'},
                            {loadChildren: () => LocationsRoutingModule, path: 'locations'},
                            {component: NgEskimmoMyAccountComponent, path: 'my-account'},
                            {component: NgEskimmoNegociationRevenusComponent, path: 'negociation/revenus'},
                            {
                                component: AppLayoutOffreachatsComponent,
                                path: 'offres-achat',
                                children: [
                                    {
                                        component: AppLayoutOffreachatComponent,
                                        path: ':offreachatUuid',
                                        children: [
                                            {component: AppLayoutOffreachatEditComponent, path: 'edit'},
                                            {component: AppLayoutOffreachatWriteComponent, path: 'redaction'},
                                            {component: AppLayoutOffreachatResumeComponent, path: 'resume'},
                                            {component: AppLayoutOffreachatSignComponent, path: 'sign'},
                                            {component: AppLayoutOffreachatSignatureComponent, path: 'signature'},
                                        ],
                                    },
                                    // Wildcard pour /app/offres-achat/*
                                    {path: '**', pathMatch: 'full', redirectTo: ''},
                                ],
                            },
                            {
                                canActivate: [
                                    (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                                ],
                                data: {roles: User.cRoles.SIGNATURE_ELECTRONIQUE},
                                path: 'procedures',
                                children: [
                                    {component: AppLayoutProceduresComponent, path: 'portefeuille'},
                                    {
                                        component: AppLayoutProcedureComponent,
                                        data: {actions: {main: {component: ProcedureSignataireSignActionsMainComponent}}},
                                        path: ':procedureUuid',
                                    },
                                    // Wildcard pour /app/procedures
                                    {path: '**', pathMatch: 'full', redirectTo: '/app/procedures/portefeuille'},
                                ],
                            },
                            {loadChildren: () => VentesRoutingModule, path: 'ventes'},
                            // Wildcard pour /app
                            {path: '**', pathMatch: 'full', redirectTo: '/app/dashboard'},
                        ],
                    },
                    // Wildcard quand l'utilisateur est connecté
                    {path: '**', pathMatch: 'full', redirectTo: '/start'},
                ],
            },
        ],
    },
];

@NgModule({
    declarations: [
        AppAuthenticatedComponent,
        AppLayoutActualitesComponent,
        AppLayoutAnnuaireContactsComponent,
        AppLayoutAnnuaireEtudeComponent,
        AppLayoutAnnuaireEtudesComponent,
        AppLayoutAnnuaireUsersComponent,
        AppLayoutBonvisiteComponent,
        AppLayoutBonvisiteEditComponent,
        AppLayoutBonvisiteResumeComponent,
        AppLayoutBonvisiteSignComponent,
        AppLayoutBonvisitesComponent,
        AppLayoutBonvisitesWalletComponent,
        AppLayoutBonvisiteWriteComponent,
        AppLayoutComponent,
        AppLayoutDocumentsComponent,
        AppLayoutDossierFilesComponent,
        AppLayoutDossierInotExportComponent,
        AppLayoutOffreachatComponent,
        AppLayoutOffreachatEditComponent,
        AppLayoutOffreachatResumeComponent,
        AppLayoutOffreachatsComponent,
        AppLayoutOffreachatsWalletComponent,
        AppLayoutOffreachatSignComponent,
        AppLayoutOffreachatSignatureComponent,
        AppLayoutOffreachatWriteComponent,
        AppLayoutProcedureComponent,
        AppLayoutProceduresComponent,
    ],
    exports: [RouterModule],
    imports: [
        // @todo Valider utilité de l'import de ce module
        BiensRoutingModule,
        CommonModule,
        EskimmoModule,
        FeaturesModule,
        RouterModule.forChild(routes),
        SharedModule,
    ],
})
export class RoutingModule {
}
