import BienCadastre from '@models/bien/cadastres/cadastre/bien-cadastre.model';
import {IBienCadastreApi} from '@models/bien/cadastres/cadastre/bien-cadastre.interfaces';
import {getMockQueryParameters} from '@shared/texts/texts.fixtures';
import {
    IBienCadastreDoubleMandatApi, IBienCadastreDoubleMandatQueryParameters
} from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.interfaces';
import BienCadastreDoubleMandat from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.model';

export const getMockBienCadastre = (data = {}): BienCadastre => {
    const bienCadastreData = data as BienCadastre;
    const suffix = 'bien-cadastre';
    const bienCadastre = new BienCadastre('uuid-' + suffix, bienCadastreData.id ?? 2);

    bienCadastre.communeId = 4;
    bienCadastre.lot = 3;
    bienCadastre.parcelle = 5;
    bienCadastre.prefixe = 'prefixe-' + suffix;
    bienCadastre.section = 'section-' + suffix;
    bienCadastre.surface = 8;

    return bienCadastre;
};

export const getMockBienCadastreApi = (): IBienCadastreApi => {
    const suffix = 'bien-cadastre-api';

    return {
        _embedded: {commune: {id: 4}},
        communeId: 8,
        id: 2,
        lot: 3,
        parcelle: 5,
        prefixe: '001',
        section: 'SECTION',
        surface: 8,
        uuid: 'uuid-' + suffix,
    };
};

export const getMockBienCadastreDoubleMandat = (): BienCadastreDoubleMandat => {
    const bienCadastreDoubleMandat = new BienCadastreDoubleMandat();
    const suffix = 'bien-cadastre-double-mandat';

    bienCadastreDoubleMandat.bienReference = 'bienReference-' + suffix;
    bienCadastreDoubleMandat.etudeRaisonSociale = 'etudeRaisonSociale-' + suffix;
    bienCadastreDoubleMandat.responsable = 'responsable-' + suffix;
    bienCadastreDoubleMandat.type = 'doubleMandat';

    return bienCadastreDoubleMandat;
};

export const getMockBienCadastreDoubleMandatApi = (): IBienCadastreDoubleMandatApi => {
    const suffix = 'bien-cadastre-double-mandat-api';

    return {
        biens: [
            {
                bienReference: 'bienReference-1-' + suffix,
                etudeRaisonSociale: 'etudeRaisonSociale-1-' + suffix,
                responsableDossierNom: 'responsableDossierNom-1-' + suffix,
                responsableDossierPrenom: 'responsableDossierPrenom-1-' + suffix,
                responsableDossierTitre: 'responsableDossierTitre-1-' + suffix,
            },
            {
                bienReference: 'bienReference-2-' + suffix,
                etudeRaisonSociale: 'etudeRaisonSociale-2-' + suffix,
                responsableDossierNom: 'responsableDossierNom-2-' + suffix,
                responsableDossierPrenom: 'responsableDossierPrenom-2-' + suffix,
                responsableDossierTitre: 'responsableDossierTitre-2-' + suffix,
            },
        ],
        type: 'doubleMandat',
    };
};

export const getMockBienCadastreDoubleMandatQueryParameters = (): IBienCadastreDoubleMandatQueryParameters => {
    const suffix = 'bien-cadastre-double-mandat-query-parameters';

    return {
        ...getMockQueryParameters(),
        commune: 4,
        excludeBien: 7,
        lot: 3,
        parcelle: 5,
        section: 'section-' + suffix,
    };
};

export class MockBienCadastreDoubleMandatFactory {
    create(): void {
    }

    checkDoubleMandat$(): void {
    }
}

export class MockBienCadastreFactory {
    create(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    get$(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienCadastresApiService {
    checkDoubleMandat$(): void {
    }

    delete$(): void {
    }

    get$(): void {
    }

    save$(): void {
    }
}

export class MockBienCadastreService {
    cancelUpdate$(): void {
    }

    checkDoubleMandat$(): void {
    }

    ngSave$(): void {
    }
}

export class MockBienCadastresService {
    save$(): void {
    }
}
