import Quartier from '@models/quartiers/quartier/quartier.model';
import {IQuartierApi} from '@models/quartiers/quartier/quartier.interfaces';
import CQuartiers from '@models/quartiers/collection/quartiers.collection.model';
import {getMockLinks} from '@models/links/links.fixtures';
import {ICQuartiersApi} from '@models/quartiers/collection/quartiers.collection.interfaces';
import {getMockCollectionApi} from '@models/collection/collections.fixtures';
import {getMockCommuneApi} from '@models/communes/communes.fixtures';

export const getMockCQuartiers = (): CQuartiers => {
    const cQuartiers = new CQuartiers();

    cQuartiers.links = getMockLinks();
    cQuartiers.page = 4;
    cQuartiers.pages = 8;
    cQuartiers.perPage = 2;
    cQuartiers.results = [getMockQuartier(), getMockQuartier()];
    cQuartiers.total = 5;

    return cQuartiers;
};

export const getMockCQuartiersApi = (): ICQuartiersApi => getMockCollectionApi([
    getMockQuartierApi(),
    getMockQuartierApi(),
]);

export const getMockQuartier = (data = {}): Quartier => {
    const quartierData = data as Quartier;
    const suffix = 'quartier';
    const quartier = new Quartier('uuid-' + suffix, quartierData.id ?? 8);

    quartier.code = 'code-' + suffix;
    quartier.communeId = 3;
    quartier.nom = 'nom-' + suffix;

    return quartier;
};

export const getMockQuartierApi = (): IQuartierApi => {
    const suffix = 'quartier-api';

    return {_embedded: {commune: getMockCommuneApi()}, code: 'code-' + suffix, id: 8, name: 'name-' + suffix};
};

export class MockCQuartiersFactory {
    create(): void {
    }

    getFromCommune$(): void {
    }
}

export class MockCQuartiersService {
    searchFromCommune$(): void {
    }
}

export class MockQuartierFactory {
    create(): void {
    }

    ngCreate(): void {
    }
}

export class MockQuartiersApiService {
    getCollectionByLink$(): void {
    }

    getCollectionFromCommune$(): void {
    }
}
