import {Component, Input, ViewEncapsulation} from '@angular/core';
import {IFaStack} from '@shared/fontawesome/fa.interfaces';
import {FaIconComponent, FaStackComponent, FaStackItemSizeDirective} from '@fortawesome/angular-fontawesome';

@Component({
    /* eslint-disable */
    encapsulation: ViewEncapsulation.None,
    /* eslint-enable */
    imports: [FaIconComponent, FaStackComponent, FaStackItemSizeDirective],
    selector: 'app-fa-shortcut',
    standalone: true,
    styleUrls: ['fontawesome-shortcut.component.scss'],
    templateUrl: 'fontawesome-shortcut.component.html',
})
export class AppFaShortcutComponent {
    static readonly initFaStack: IFaStack = {
        action_background_color: 'tw-text-noty-green',
        action_background_name: 'circle',
        action_background_name_prefix: 'fas',
        action_background_transform: 'up-13 right-13',
        action_icon_color: 'tw-text-white',
        action_icon_name_prefix: 'fas',
        action_icon_transform: 'shrink-36 up-26 right-30',
        main_background_color: 'tw-text-noty-green',
        main_background_name: 'circle',
        main_background_name_prefix: 'fas',
        main_background_transform: 'left-5',
        main_icon_color: 'tw-text-white',
        main_icon_name: 'circle',
        main_icon_name_prefix: 'fas',
        main_icon_transform: '',
    };
    private _faStack = AppFaShortcutComponent.initFaStack;

    get faStack(): IFaStack {
        return this._faStack;
    }

    @Input()
    set faStack(value: IFaStack) {
        this._faStack = {...AppFaShortcutComponent.initFaStack, ...value};
    }
}
