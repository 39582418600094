import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IBienLogementApi} from '@models/bien/logements/logement/bien-logement.interfaces';
import BienLogement from '@models/bien/logements/logement/bien-logement.model';

@Injectable({providedIn: 'root'})
export class BienLogementsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(dossierBienId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/biens/${dossierBienId}/logements/${id}`);
    }

    get$(dossierBienId: string, id: string): Observable<IBienLogementApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/logements/${id}`);
    }

    save$(dossierBienId: string, id: string, bienLogementApi: IBienLogementApi): Observable<IBienLogementApi> {
        if (id === BienLogement.STATUT_NEW) {
            return this._modelApiService.post$(`/biens/${dossierBienId}/logements`, bienLogementApi);
        }

        return this._modelApiService.put$(`/biens/${dossierBienId}/logements/${id}`, bienLogementApi)
            .pipe(switchMap(_ => this.get$(dossierBienId, id)));
    }
}
