import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {AppIframeComponent} from '@shared/iframe/iframe.component';
import {EstimationNotestimService} from '@models/estimations/estimation/notestim/estimation-notestim.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import {from, interval, Subject, switchMap} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import Etude from '@models/etudes/etude/etude.model';
import {Router} from '@angular/router';

@Component({
    imports: [AppIframeComponent],
    selector: 'app-estimation-evaluation-notestim',
    standalone: true,
    templateUrl: 'estimation.evaluation-notestim.component.html',
})
export class AppEstimationEvaluationNotestimComponent implements OnDestroy, OnInit {
    private _estimationNotestimService = inject(EstimationNotestimService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _estimation!: Estimation;
    private _estimationNotestim!: EstimationNotestim;
    private readonly _onDestroy$ = new Subject<void>();

    @Input({required: true})
    set estimation(value: Estimation) {
        this._estimation = value;
    }

    get estimationNotestim(): EstimationNotestim {
        return this._estimationNotestim;
    }

    @Input({required: true})
    set estimationNotestim(value: EstimationNotestim) {
        this._estimationNotestim = value;
    }

    ngOnInit(): void {
        let waitingUser = false;

        interval(2000).pipe(
            filter(() => !waitingUser),
            switchMap(() => this._estimationNotestimService.checkIfCurrentRapportChanged$(this._estimation)),
            filter(estimationNotestim => !!estimationNotestim),
            tap(() => waitingUser = true),
            switchMap(() => this._modalService.openConfirmation$({
                buttonCancelLabel: 'Rester sur ' + Etude.enablableModules.NOTESTIM.label,
                buttonConfirmationLabel: 'Voir le rapport',
                comments: 'Le rapport est arrivé sur ' + AppNoteoIconTextNotyComponent.label + '.',
                question: 'Souhaitez-vous voir le rapport généré par ' + Etude.enablableModules.NOTESTIM.label + '?',
                title: 'Mise à jour du rapport',
                status: ModalService.status.INFO,
            })),
            switchMap(goToRapport => {
                if (goToRapport) {
                    return from(this._router.navigateByUrl(`/app/estimations/${this._estimation.id}/rapport`));
                }

                return this._estimationNotestimService.getOne$(this._estimation)
                    .pipe(tap(updatedEstimationNotestim => this._estimationNotestim.linkRapportMedia = updatedEstimationNotestim.linkRapportMedia));
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(() => waitingUser = false);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
