import {getMockDictionaryItem, getMockDictionaryItemApi} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import BienCommodite from '@models/bien/commodites/commodite/bien-commodite.model';
import {IBienCommoditeApi} from '@models/bien/commodites/commodite/bien-commodite.interfaces';

export const getMockBienCommodite = (): BienCommodite => {
    const suffix = 'bien-commodite';
    const bienCommodite = new BienCommodite('uuid-' + suffix, 9);

    bienCommodite.comments = 'comments-' + suffix;
    bienCommodite.commodite = getMockDictionaryItem();
    bienCommodite.distance = 7;
    bienCommodite.otherLabel = 'otherLabel-' + suffix;

    return bienCommodite;
};

export const getMockBienCommoditeApi = (): IBienCommoditeApi => {
    const suffix = 'bien-commodite-api';

    return {
        _embedded: {commodite: getMockDictionaryItemApi()},
        comments: 'comments-' + suffix,
        commoditeId: 1,
        distance: 7,
        id: 9,
        otherLabel: 'otherLabel-' + suffix,
        uuid: 'uuid-' + suffix,
    };
};

export class MockBienCommoditeFactory {
    create(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienCommoditesApiService {
    delete$(): void {
    }

    get$(): void {
    }

    save$(): void {
    }
}

export class MockBienCommoditesService {
    save$(): void {
    }
}
