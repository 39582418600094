import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IBienSurfaceApi} from '@models/bien/surfaces/surface/bien-surface.interfaces';
import BienSurface from '@models/bien/surfaces/surface/bien-surface.model';

@Injectable({providedIn: 'root'})
export class BienSurfacesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(dossierBienId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/biens/${dossierBienId}/pieces/${id}`);
    }

    get$(dossierBienId: string, id: string): Observable<IBienSurfaceApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/pieces/${id}`);
    }

    save$(dossierBienId: string, id: string, bienSurfaceApi: IBienSurfaceApi): Observable<IBienSurfaceApi> {
        if (id === BienSurface.STATUT_NEW) {
            return this._modelApiService.post$(`/biens/${dossierBienId}/pieces`, bienSurfaceApi);
        }

        return this._modelApiService.put$(`/biens/${dossierBienId}/pieces/${id}`, bienSurfaceApi)
            .pipe(switchMap(_ => this.get$(dossierBienId, id)));
    }
}
