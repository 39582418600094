import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICEmailDossiersApi} from '@models/emails/email/dossiers/collection/email-dossiers.collection.interfaces';

@Injectable({providedIn: 'root'})
export class EmailDossiersApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(emailId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/emails/${emailId}/dossiers/${id}`);
    }

    getCollection$(emailId: string): Observable<ICEmailDossiersApi> {
        return this.getCollectionByLink$(`/emails/${emailId}/dossiers`);
    }

    getCollectionByLink$(link: string): Observable<ICEmailDossiersApi> {
        return this._modelApiService.getCollection$(link);
    }
}
