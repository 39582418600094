import BienLogement from '@models/bien/logements/logement/bien-logement.model';
import {
    getMockDictionaryItem, getMockDictionaryItemApi
} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import {IBienLogementApi} from '@models/bien/logements/logement/bien-logement.interfaces';

export const getMockBienLogement = (): BienLogement => {
    const suffix = 'bien-logement';
    const bienLogement = new BienLogement('uuid-' + suffix, 7);

    bienLogement.comments = 'comments-' + suffix;
    bienLogement.etage = getMockDictionaryItem();
    bienLogement.logement = getMockDictionaryItem();
    bienLogement.otherLabel = 'otherLabel-' + suffix;
    bienLogement.surface = 7;

    return bienLogement;
};

export const getMockBienLogementApi = (): IBienLogementApi => {
    const suffix = 'bien-logement-api';

    return {
        _embedded: {etage: getMockDictionaryItemApi(), logement: getMockDictionaryItemApi()},
        comments: 'comments-' + suffix,
        etageId: 3,
        id: 7,
        logementId: 4,
        otherLabel: 'otherLabel-' + suffix,
        surface: 7,
        uuid: 'uuid-' + suffix,
    };
};

export class MockBienLogementFactory {
    create(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienLogementsApiService {
    delete$(): void {
    }

    get$(): void {
    }

    save$(): void {
    }
}

export class MockBienLogementsService {
    save$(): void {
    }
}
