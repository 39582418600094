import {NgModule} from '@angular/core';
import {AppImagePreviewComponent} from '@shared/image/preview/image-preview.component';
import {AppImageCropComponent} from '@shared/image/crop/image-crop.component';
import {AppImagePickerComponent} from '@shared/image/picker/image-picker.component';

@NgModule({
    exports: [AppImageCropComponent, AppImagePickerComponent, AppImagePreviewComponent],
    imports: [AppImageCropComponent, AppImagePickerComponent, AppImagePreviewComponent],
})
export class ImageModule {
}
