import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgBienCadastreManager, NgDictionariesManager, NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgBien, NgBienCadastre, NgDoubleMandat} from '@legacy/app/managers/ressources';
import {NgClientService} from '@legacy/app/client/client';
import {map} from 'rxjs/operators';
import {from, Observable, of} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getManagersBienCadastre(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienCadastreManager', manager);

        /**
         * Manager cadastre
         *
         * @param UtilsManager
         * @param BaseManager
         * @param ClientService
         * @param $q
         * @param DictionariesManager
         * @param Ng2EtudeService
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['UtilsManager', 'BaseManager', 'ClientService', '$q', 'DictionariesManager', 'Ng2EtudeService', 'Ng2DictionaryItemService'];
        function manager(utilsManager: NgUtilsManager,
                         baseManager: NgManager,
                         clientService: NgClientService,
                         $q: IQService,
                         dictionariesManager: NgDictionariesManager,
                         ng2EtudeService: EtudeService,
                         ng2DictionaryItemService: DictionaryItemService) {
            let classResource = baseManager.getClass<NgBienCadastre>();

            /**
             * Routing
             */
            baseManager.addRoute('cadastres.cget', {path: '/biens/{bienId}/cadastres', method: 'GET'});
            baseManager.addRoute('cadastres.check-double-mandat', {
                path: '/toolbox/biens/cadastres/check',
                method: 'GET'
            });
            baseManager.addRoute('cadastres.insert', {path: '/biens/{bienId}/cadastres', method: 'POST'});
            baseManager.addRoute('cadastres.edit', {path: '/biens/{bienId}/cadastres/{id}', method: 'PUT'});
            baseManager.addRoute('cadastres.update-rank', {
                path: '/biens/{bienId}/cadastres/{id}/rank',
                method: 'PATCH'
            });

            /**
             * BienCadastreManager object
             *
             * @constructor
             */
            angular.extend(BienCadastreManager.prototype, baseManager.__proto__);
            BienCadastreManager.prototype.eskManager = {prefixRoute: 'cadastres', collectionName: 'items'};
            function BienCadastreManager() {
            }

            /**
             * Returns the promise to fetch collection of filtered ventes by cadastre
             *
             * @param cadastres
             * @param excludeBienId
             * @returns {Promise}
             */
            BienCadastreManager.prototype.checkDoubleMandat = function (cadastres: NgBienCadastre[], excludeBienId: number): IPromise<NgDoubleMandat> {
                const self = this;
                const promises: unknown[] = [];
                let doubleMandat: NgDoubleMandat;

                angular.forEach(cadastres, function (cadastre) {
                    const params = {};

                    if (!cadastre.isValid()) {
                        return;
                    }

                    if (angular.isObject(cadastre.commune) && cadastre.commune.id !== null) {
                        // @ts-ignore
                        params.commune = cadastre.commune.id;
                    }

                    // @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/461 : A modifier
                    /*if (angular.isDefined(cadastre.prefixe) && cadastre.prefixe !== null) {
                     params.prefixe = cadastre.prefixe;
                     }*/

                    if (angular.isDefined(cadastre.section) && cadastre.section !== null) {
                        // @ts-ignore
                        params.section = cadastre.section;
                    }

                    if (angular.isDefined(cadastre.parcelle) && cadastre.parcelle !== null) {
                        // @ts-ignore
                        params.parcelle = cadastre.parcelle;
                    }

                    if (angular.isDefined(cadastre.lot) && cadastre.lot !== null) {
                        // @ts-ignore
                        params.lot = cadastre.lot;
                    }

                    if (angular.isDefined(excludeBienId) && excludeBienId !== null) {
                        // @ts-ignore
                        params.excludeBien = excludeBienId;
                    }

                    promises.push(clientService.execRoute(self.getPrefixRoute() + '.check-double-mandat', params).then(
                        doubleMandatApi => doubleMandatApi as { biens: { bienReference: string; etudeRaisonSociale: string; responsableDossierNom: string; responsableDossierPrenom: string; responsableDossierTitre: string; }[], type: string }
                    ).then(doubleMandatApi => {
                        if (!angular.isObject(cadastre._esk)) {
                            cadastre._esk = {};
                        }

                        if (doubleMandatApi) {
                            let abreviation = '';

                            if (!doubleMandatApi.biens[0].responsableDossierTitre) {
                                abreviation = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.TITRES, doubleMandatApi.biens[0].responsableDossierTitre, true)).abreviation + " ";
                            }

                            cadastre._esk.doubleMandat = {
                                bienReference: doubleMandatApi.biens[0].bienReference,
                                etudeRaisonSociale: doubleMandatApi.biens[0].etudeRaisonSociale,
                                responsable: abreviation + doubleMandatApi.biens[0].responsableDossierPrenom + " " + doubleMandatApi.biens[0].responsableDossierNom,
                                title: doubleMandatApi.type === 'doubleMandat' ? 'Double mandat' : 'Doublon',
                                type: doubleMandatApi.type,
                            };
                        } else {
                            cadastre._esk.doubleMandat = undefined!;
                        }

                        if (angular.isObject(cadastre._esk.doubleMandat) && (!angular.isObject(doubleMandat) || doubleMandat.type !== 'doubleMandat')) {
                            doubleMandat = cadastre._esk.doubleMandat;
                        }
                    }));
                });

                return $q.all(promises).then(() => doubleMandat);
            };

            /**
             * Create a BienCadastre object
             *
             * @param data
             * @param bienId
             * @returns manager.BienCadastre object || Array of manager.BienCadastre objects
             */
            BienCadastreManager.prototype.create = function (data: unknown, bienId: number) {
                if (angular.isArray(data)) {
                    const cadastres = [];

                    for (let i = 0; i < data.length; i++) {
                        // @ts-ignore
                        cadastres.push(new BienCadastre(bienId, data[i]));
                    }

                    return cadastres;
                }

                // @ts-ignore
                return new BienCadastre(bienId, data);
            };

            /**
             * Cadastre object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(BienCadastre.prototype, classResource.prototype);
            BienCadastre.prototype.eskManager = {prefixRoute: 'cadastres'};
            function BienCadastre(this: NgBienCadastre, bienId: number, data: unknown) {
                this.extend(data);
                this.uuid = this.id?.toString();
                this._esk.defaultRouteParams = {id: this.id, bienId: bienId};
            }

            BienCadastre.prototype.extend = function (data: unknown) {
                const self = this;

                utilsManager.extendDataToResource(self, data);
                self.surface = self.surface?.toString().replace('.', ',');

                return self;
            };

            /**
             * Sanitize object BienCadastre before send to API
             *
             * @returns {Object}
             */
            BienCadastre.prototype.sanitize = function () {
                if (!/^\d{3}$/.test(this.prefixe)) {
                    this.prefixe = '000';
                }

                this.section = this.section.toUpperCase();

                const sanitizeObject = classResource.prototype.sanitize.call(this);

                sanitizeObject.lot = !sanitizeObject.lot ? undefined! : sanitizeObject.lot;

                // Transformation des propriétés de l'objet BienCadastre pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'commune');
                if (!!this.surface) {
                    sanitizeObject.surface = +(this.surface.toString().replace(',', '.'));
                } else {
                    sanitizeObject.surface = null!;
                }

                return sanitizeObject;
            };

            /**
             * Sanitize object BienCadastre before send to API
             *
             * @returns {Object}
             */
            BienCadastre.prototype.saveFromBien = function (bien: NgBien, ignoreOtherItems = false): Observable<NgBienCadastre> {
                const self = this;

                if (!self.isValid()) {
                    return of(undefined as unknown as NgBienCadastre);
                }

                self._esk.alreadyExists = false;
                bien.cadastres
                    .filter(bienCadastre => bienCadastre._esk.uuid !== self._esk.uuid)
                    .filter(bienCadastre => bienCadastre!.commune?.id?.toString() === self!.commune?.id?.toString())
                    .filter(bienCadastre => bienCadastre.prefixe === self.prefixe)
                    .filter(bienCadastre => bienCadastre.section === self.section)
                    .filter(bienCadastre => bienCadastre.parcelle?.toString() === self.parcelle?.toString())
                    .filter(bienCadastre => bienCadastre.lot?.toString() === self.lot?.toString() || (!bienCadastre.lot && !self.lot))
                    .forEach(_ => self._esk.alreadyExists = true);
                if (self._esk.alreadyExists) {
                    return of(undefined as unknown as NgBienCadastre);
                }

                bien.operateSurfaceCadastraleFromRefs();

                return from(bien.saveItem('cadastres', self, ignoreOtherItems).finally(() => {
                    if (bien._esk.type === DossierBienTypesConst.VENTE) {
                        ng2EtudeService.last$.subscribe(currentEtude => {
                            if (!currentEtude.hasNetwork()) {
                                return;
                            }

                            bienCadastreManager.checkDoubleMandat([self], bien.id);
                        });
                    }
                })).pipe(map(_ => self));
            };

            /**
             * Test validity and fill _esk.errors
             *
             * @returns {boolean}
             */
            BienCadastre.prototype.isValid = function () {
                const sanitizeObject = this.sanitize();

                this._esk.errors = {
                    lot: !!this.lot && (isNaN(sanitizeObject.lot) || !/^[1-9]\d*$/.test(sanitizeObject.lot)),
                    parcelle: isNaN(sanitizeObject.parcelle) || !/^[1-9]\d*$/.test(sanitizeObject.parcelle),
                    prefixe: !!this.prefixe && (isNaN(sanitizeObject.prefixe) || !/^\d{3}$/.test(sanitizeObject.prefixe)),
                    section: !/^[A-Z0-9]{1,2}$/.test(sanitizeObject.section),
                    surface: !!this.surface && (isNaN(sanitizeObject.surface) || !/^(?:\d*\.)?\d+$/.test(sanitizeObject.surface)),
                };

                return !this._esk.errors.commune && !this._esk.errors.lot && !this._esk.errors.parcelle && !this._esk.errors.prefixe && !this._esk.errors.section && !this._esk.errors.surface;
            };

            /**
             * Test if BienCadastre is empty
             *
             * @returns {boolean}
             */
            BienCadastre.prototype.isEmpty = function () {
                return (!angular.isString(this.section) || this.section === '')
                    && (!angular.isNumber(this.parcelle) || this.parcelle <= 0)
                    && (!angular.isNumber(this.lot) || this.lot <= 0)
                    && (!angular.isNumber(this.surface) || this.surface <= 0);
            };

            // @ts-ignore
            const bienCadastreManager = new BienCadastreManager() as NgBienCadastreManager;

            return bienCadastreManager;
        }
    })(angularJS);
}
