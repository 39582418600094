import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IBienDiagnosticApi} from '@models/bien/diagnostics/diagnostic/bien-diagnostic.interfaces';
import BienDiagnostic from '@models/bien/diagnostics/diagnostic/bien-diagnostic.model';

@Injectable({providedIn: 'root'})
export class BienDiagnosticsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(dossierBienId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/biens/${dossierBienId}/diagnostics/${id}`);
    }

    get$(dossierBienId: string, id: string): Observable<IBienDiagnosticApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/diagnostics/${id}`);
    }

    save$(dossierBienId: string, id: string, bienDiagnosticApi: IBienDiagnosticApi): Observable<IBienDiagnosticApi> {
        if (id === BienDiagnostic.STATUT_NEW) {
            return this._modelApiService.post$(`/biens/${dossierBienId}/diagnostics`, bienDiagnosticApi);
        }

        return this._modelApiService.put$(`/biens/${dossierBienId}/diagnostics/${id}`, bienDiagnosticApi)
            .pipe(switchMap(_ => this.get$(dossierBienId, id)));
    }
}
