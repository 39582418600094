import {Component, inject, OnInit} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {map} from 'rxjs/operators';
import {INavigationLateralMenu, INavigationLateralSection} from '@shared/navigation/navigation.interfaces';
import {AppNavigationLateralComponent} from '@shared/navigation/lateral/navigation-lateral.component';

@Component({selector: 'layout-configuration',templateUrl: 'layout-configuration.component.html'})
export class AppLayoutConfigurationComponent implements OnInit {
    // @todo Utilité du typage ?
    static readonly menus: Record<string, INavigationLateralMenu> = {
        DOCUMENTS: {
            code: AppNavigationLateralComponent.menuCodes.DOCUMENTS,
            label: 'Documents',
            route: '/app/configuration/documents',
            subMenus: [
                {label: 'En-tête', route: '/app/configuration/documents/header'},
                {label: 'Pied de page', route: '/app/configuration/documents/footer'},
            ],
        },
        DOSSIERS: {
            code: AppNavigationLateralComponent.menuCodes.DOSSIERS,
            label: 'Dossiers',
            route: '/app/configuration/dossiers',
        },
        ETUDE: {
            code: AppNavigationLateralComponent.menuCodes.ETUDE,
            label: 'Étude',
            route: '/app/configuration/etude',
        },
        NETWORK: {
            code: AppNavigationLateralComponent.menuCodes.NETWORK,
            label: 'Réseau',
            route: '/app/configuration/network',
        },
        NOTESTIM: {
            code: AppNavigationLateralComponent.menuCodes.NOTESTIM,
            label: 'Notestim',
            route: '/app/configuration/notestim',
        },
        PASSERELLES: {
            code: AppNavigationLateralComponent.menuCodes.PASSERELLES,
            label: 'Passerelles',
            route: '/app/configuration/passerelles',
        },
    };
    readonly ACTION_MAIN = CallToActionService.MAIN;
    private _etudeService = inject(EtudeService);
    private _userService = inject(UserService);
    private _sections$!: Observable<INavigationLateralSection[]>;

    get sections$(): Observable<INavigationLateralSection[]> {
        return this._sections$;
    }

    ngOnInit(): void {
        const etudeSection$ = combineLatest([
            of(AppLayoutConfigurationComponent.menus.ETUDE),
            of(AppLayoutConfigurationComponent.menus.DOCUMENTS),
            combineLatest([
                this._etudeService.last$.pipe(map(currentEtude => currentEtude.hasNetwork())),
                this._userService.currentHasRole$(User.roles.GRP_VENTE)],
            ).pipe(
                map(([hasNetwork, hasRole]: [boolean, boolean]) => hasNetwork && hasRole),
                map(hasMenu => hasMenu ? AppLayoutConfigurationComponent.menus.NETWORK : undefined!),
            ),
            combineLatest([
                this._userService.currentHasRole$(User.roles.GRP_LOCATION),
                this._userService.currentHasRole$(User.roles.GRP_VENTE),
            ]).pipe(
                map(([hasRoleLocation, hasRoleVente]) => hasRoleLocation || hasRoleVente),
                switchMap(hasRoles => this._etudeService.last$.pipe(
                    map(currentEtude => hasRoles && currentEtude.configurablesPasserelles.length > 0),
                )),
                map(hasPasserelles => hasPasserelles ? AppLayoutConfigurationComponent.menus.PASSERELLES : undefined!),
            ),
            this._userService.last$.pipe(map(user => user.hasRoleNotestim() ? AppLayoutConfigurationComponent.menus.NOTESTIM : undefined!)),
        ]).pipe(
            map(menus => menus.filter(menu => !!menu)),
            switchMap(menus => this._etudeService.last$.pipe(
                map(currentEtude => ({menus, title: currentEtude.raisonSociale} as INavigationLateralSection)),
            )),
        );
        const utilisateurSection$ = of({
            menus: [AppLayoutConfigurationComponent.menus.DOSSIERS],
            title: 'Mon profil',
        } as unknown as INavigationLateralSection);

        this._sections$ = combineLatest([utilisateurSection$, etudeSection$]);
    }
}
