import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgBienCommodite} from '@legacy/app/managers/ressources';

export default function getManagersBienCommodite(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienCommoditeManager', manager);

        /**
         * Manager commodite
         *
         * @param BaseManager
         * @param UtilsManager
         * @returns {BienCommoditeManager}
         */
        manager.$inject = ['BaseManager', 'UtilsManager'];
        function manager(baseManager: NgManager, utilsManager: NgUtilsManager) {
            let classResource = baseManager.getClass<NgBienCommodite>();

            /**
             * Routing
             */
            baseManager.addRoute('commodites.cget', {path: '/biens/{bienId}/commodites', method: 'GET'});
            baseManager.addRoute('commodites.insert', {path: '/biens/{bienId}/commodites', method: 'POST'});
            baseManager.addRoute('commodites.edit', {path: '/biens/{bienId}/commodites/{id}', method: 'PUT'});
            baseManager.addRoute('commodites.update-rank', {
                path: '/biens/{bienId}/commodites/{id}/rank',
                method: 'PATCH'
            });

            /**
             * BienCommoditeManager object
             *
             * @constructor
             */
            angular.extend(BienCommoditeManager.prototype, baseManager.__proto__);
            BienCommoditeManager.prototype.eskManager = {prefixRoute: 'commodites', collectionName: 'items'};
            function BienCommoditeManager() {
            }

            /**
             * Create a BienCommodite object
             *
             * @param data
             * @param bienId
             * @returns manager.BienCommodite object || Array of manager.BienCommodite objects
             */
            BienCommoditeManager.prototype.create = function (data: unknown, bienId: number) {
                if (angular.isArray(data)) {
                    var commodites = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        commodites.push(new BienCommodite(bienId, data[i]));
                    }

                    return commodites;
                }

                // @ts-ignore
                return new BienCommodite(bienId, data);
            };

            /**
             * BienCommodite object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(BienCommodite.prototype, classResource.prototype);
            BienCommodite.prototype.eskManager = {prefixRoute: 'commodites'};
            function BienCommodite(this: NgBienCommodite, bienId: number, data: unknown) {
                this.extend(data);
                this.uuid = this.id?.toString();
                this._esk.defaultRouteParams = {id: this.id, bienId: bienId};
            }

            /**
             * Extend the existing BienLogement with new data
             *
             * @param data
             * @returns {BienLogement}
             */
            BienCommodite.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.distance = this.distance?.toString().replace('.', ',');

                return this;
            };

            /**
             * Sanitize object BienCommodite before send to API
             *
             * @returns {Object}
             */
            BienCommodite.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet BienCommodite pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'commodite');
                if (!!this.distance) {
                    sanitizeObject.distance = +(this.distance.toString().replace(',', '.'));
                } else {
                    sanitizeObject.distance = null!;
                }

                return sanitizeObject;
            };

            /**
             * Test validity and fill _esk.errors
             *
             * @returns {boolean}
             */
            BienCommodite.prototype.isValid = function () {
                const sanitizeObject = this.sanitize();

                this._esk.errors = {
                    commodite: !sanitizeObject.commoditeId,
                    distance: !!this.distance && (isNaN(sanitizeObject.distance) || !/^(?:\d*\.)?\d+$/.test(sanitizeObject.distance)),
                };

                return !this._esk.errors.commodite && !this._esk.errors.distance;
            };

            /**
             * Test if BienCommodite is empty
             *
             * @returns {boolean}
             */
            BienCommodite.prototype.isEmpty = function () {
                return !angular.isObject(this.commodite)
                    && (!angular.isNumber(this.distance) || this.distance <= 0)
                    && (!angular.isString(this.comments) || this.comments === '');
            };

            // @ts-ignore
            return new BienCommoditeManager();
        }
    })(angularJS);
}
