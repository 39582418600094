export default `
<div class="container-fluid container-fullw tw-bg-white">
  <div class="row">
    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.duplicate()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'copy', action_icon_transform: 'shrink-36 up-26 right-29', main_icon_name: 'chart-line'}">
              </app-fa-shortcut>

              <h4>Dupliquer</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2"
         title="{{$ctrl.hasRoleVente ? '' : 'Vous n\\'avez pas le module Vente.'}}">
      <div class="shortcut disabled:tw-disabled" ng-disabled="!$ctrl.hasRoleVente">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createVente()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'arrow-right-arrow-left', main_icon_name: 'house'}">
              </app-fa-shortcut>

              <h4>Transformer en vente</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createEmail()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'envelope', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Envoyer un Email</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ng-if="$ctrl.estimation.statut === $ctrl.STATUTS.BROUILLON" class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.remettreRapport()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'circle-check', main_icon_name: 'pen-to-square'}">
              </app-fa-shortcut>

              <h4>Passer en "Remise au client"</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row lifetime">
    <!-- Archivage -->
    <div ng-if="$ctrl.estimation.statut !== $ctrl.STATUTS.ARCHIVE" class="col-xs-12 col-sm-6">
      <div class="panel panel-white no-radius">
        <div class="panel-heading border-bottom">
          <h4 class="panel-title text-bold"><fa-icon [icon]="'archive'"></fa-icon> Archivage</h4>
        </div>

        <div class="panel-body text-info">
          <span>Ce bien en estimation est actif. Il n'est pas encore archivé.</span>

          <p class="text-muted">
            Vous pouvez procéder à son archivage en cliquant sur le lien ci-dessous.<br>
            Il sera retiré de votre portefeuille de biens en estimation actifs.
          </p>
        </div>

        <div class="panel-footer">
          <button type="button" class="text-bold text-danger" ng-click="$ctrl.archive()">
            Archiver le bien en estimation ...
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-6">
      <app-dossier-inot-exports-box [dossier]="$ctrl.ng2Estimation"></app-dossier-inot-exports-box>
    </div>
  </div>

  <div class="tw-text-right">
    Bien créé le <app-ng2-date [date]="$ctrl.estimation.bien.createdAt" format="shortDate"></app-ng2-date> |
    Dernière modification le <app-ng2-date [date]="$ctrl.estimation.bien.updatedAt" format="shortDate"></app-ng2-date>
  </div>
</div>
`;
