import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {NoteoSepteoConst} from '@shared/noteo/noteo.constants';

@Component({
    imports: [NgOptimizedImage],
    selector: 'app-noteo-logo-septeo',
    standalone: true,
    styleUrl: 'noteo-logo.septeo.component.scss',
    templateUrl: 'noteo-logo.septeo.component.html',
})
export class AppNoteoLogoSepteoComponent {
    private _label = NoteoSepteoConst.label;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get srcImage(): string {
        return NoteoSepteoConst.srcImage;
    }
}
