{
  "name": "noty",
  "private": true,
  "repository": {
    "type": "git",
    "url": "ssh://git@gitlab.soqrate.com:8822/soqrate-notaires/eskimmo-angular.git"
  },
  "description": "Client Noty Angular",
  "versionClient": "2025.01",
  "versionAPI": "2024.09",
  "browserslist": [
    "last 2 Chrome versions",
    "last 2 Firefox version",
    "last 2 Edge major versions",
    "last 2 Safari major versions",
    "last 2 iOS major versions",
    "Firefox ESR"
  ],
  "scripts": {
    "build": "ng build --output-hashing=all",
    "build:analysis": "yarn build --stats-json && webpack-bundle-analyzer ./dist/stats.json",
    "dependencies:check": "yarn install --check-files",
    "dependencies:install": "yarn --frozen-lockfile",
    "lint": "eslint src --color --max-warnings 0 --cache --cache-strategy content",
    "lint:fix": "yarn lint --fix",
    "lint-styles": "stylelint \"src/app/**/*.scss\"",
    "parse": "ngc && yarn lint-styles && yarn lint:fix && yarn lint",
    "project:check": "yarn dependencies:install && yarn dependencies:check && yarn lint && yarn test && yarn build -c prod && yarn serve",
    "project:init": "yarn dependencies:install && yarn serve",
    "serve": "node --max_old_space_size=8192 node_modules/@angular/cli/bin/ng serve",
    "test": "node --max_old_space_size=8192 node_modules/@angular/cli/bin/ng test",
    "test:coverage": "yarn test -c coverage",
    "update": "npx ng update @angular/cli @angular/core --force && yarn upgrade && yarn upgrade-interactive --latest"
  },
  "dependencies": {
    "@angular-slider/ngx-slider": "^18.0.0",
    "@angular/animations": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/forms": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@angular/upgrade": "^18.2.6",
    "@bluehalo/ngx-leaflet": "^18.0.2",
    "@bluehalo/ngx-leaflet-markercluster": "^18.0.1",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.3.0",
    "@fortawesome/free-brands-svg-icons": "^6.3.0",
    "@fortawesome/free-regular-svg-icons": "^6.3.0",
    "@fortawesome/free-solid-svg-icons": "^6.4.0",
    "@fortawesome/pro-duotone-svg-icons": "^6.4.0",
    "@fortawesome/pro-light-svg-icons": "^6.4.0",
    "@fortawesome/pro-regular-svg-icons": "^6.4.0",
    "@fortawesome/pro-solid-svg-icons": "^6.4.0",
    "@fortawesome/pro-thin-svg-icons": "^6.4.0",
    "@fortawesome/sharp-solid-svg-icons": "^6.4.0",
    "@ng-select/ng-select": "^13.4.1",
    "@sentry/angular": "^8.40.0",
    "@tinymce/tinymce-angular": "^7.0.0",
    "angular": "^1.8.1",
    "angular-cookies": "^1.8.1",
    "angular-drag-and-drop-lists": "^2.1.0",
    "angular-i18n": "^1.8.1",
    "angular-messages": "^1.8.1",
    "angular-sanitize": "^1.8.1",
    "angular-svg-pan-zoom": "^1.0.0",
    "angular-translate": "^2.18.3",
    "angular-ui-bootstrap": "~2.5.0",
    "angular-ui-sortable": "^0.19.0",
    "bootstrap-sass": "^3.4.1",
    "chart.js": "^4.2.0",
    "cropperjs": "^1.5.12",
    "jexl": "^2.3.0",
    "jquery": "^3.6.0",
    "jquery-ui-dist": "^1.12.1",
    "jquery-ui-touch-punch": "^0.2.3",
    "leaflet": "^1.9.4",
    "leaflet.markercluster": "^1.5.3",
    "libphonenumber-js": "^1.9.44",
    "lodash-es": "^4.17.21",
    "messageformat": "^2.3.0",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-device-detector": "^8.0.0",
    "ngx-toastr": "^19.0.0",
    "rxjs": "^7.5.6",
    "signature_pad": "^5.0.0",
    "simplify-js": "^1.2.3",
    "svg-pan-zoom": "^3.6.1",
    "sweetalert2": "^11.7.15",
    "tinymce": "^6.8.3",
    "ui-select": "0.x",
    "uuid": "^10.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular-eslint/builder": "^18.0.1",
    "@angular-eslint/schematics": "^18.0.1",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/language-service": "^18.2.6",
    "@tailwindcss/container-queries": "^0.1.1",
    "@tailwindcss/forms": "^0.5.0",
    "@types/angular": "^1.7.0",
    "@types/angular-mocks": "^1.7.0",
    "@types/angular-translate": "^2.19.0",
    "@types/angular-ui-bootstrap": "^1.0.0",
    "@types/angular-ui-sortable": "^0.13.35",
    "@types/chart.js": "^2.9.34",
    "@types/jasmine": "~5.1.1",
    "@types/jasminewd2": "~2.0.10",
    "@types/jexl": "^2.2.0",
    "@types/leaflet": "^1.9.3",
    "@types/leaflet.markercluster": "^1.5.1",
    "@types/lodash": "^4.14.177",
    "@types/node": "^22.0.2",
    "@types/ui-select": "^0.19.1",
    "angular-eslint": "^18.0.1",
    "angular-mocks": "^1.8.1",
    "autoprefixer": "^10.4.8",
    "eslint": "^9.6.0",
    "html-loader": "^5.0.0",
    "jasmine-core": "^5.0.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "^2.1.0",
    "karma-jasmine": "~5.1.0",
    "puppeteer": "^23.4.1",
    "stylelint": "^16.1.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "tailwindcss": "^3.0.15",
    "ts-node": "^10.9.1",
    "typescript": "5.5.4",
    "typescript-eslint": "^8.0.0",
    "webpack-bundle-analyzer": "^4.4.2"
  }
}
