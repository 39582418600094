import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgDictionariesManager, NgLocationManager} from '@legacy/app/managers/managers';
import {NgLocation} from '@legacy/app/managers/ressources';
import {delay, from, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {NgForm} from '@angular/forms';
import FormService from '@shared/form/form.service';
import {LocationFactory} from '@models/locations/location/location.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Location from '@models/locations/location/location.model';
import Bien from '@models/bien/bien.model';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Component({selector: 'layout-location-edit-general', templateUrl: 'layout-location-edit-general.component.html'})
export class AppLayoutLocationEditGeneralComponent implements OnDestroy, OnInit {
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _locationFactory = inject(LocationFactory);
    private _ngForm = inject(NgForm);
    private _ngInjectorService = inject(NgInjectorService);
    private _contactsGroupLinkSource = new ReplaySubject<string>(1);
    private _contactsGroupLink$ = this._contactsGroupLinkSource.asObservable();
    private _ngLocation!: NgLocation;
    private readonly _onDestroy$ = new Subject<void>();
    private _periodiciteCharges!: DictionaryItem;
    private _periodiciteLoyer!: DictionaryItem;
    private _showComplementLoyer = false;
    private _typeLoyer!: DictionaryItem;

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    get contactsGroupLink$(): Observable<string> {
        return this._contactsGroupLink$;
    }

    get DICTIONARY_ITEM_CODE_NATURE_LOCAUX_COMMERCIAUX(): string {
        return Bien.natures.LOCAUX_COMMERCIAUX;
    }

    get DICTIONARY_NAME_LOCATION_LOYER_TYPES(): string {
        return Dictionary.names.LOCATION_LOYER_TYPES;
    }

    get DICTIONARY_NAME_PERIODICITES(): string {
        return Dictionary.names.PERIODICITES;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienFieldsConst;
    }

    get LOCATION_BROUILLON(): string {
        return Location.statuts.BROUILLON;
    }

    get ngLocation(): NgLocation {
        return this._ngLocation;
    }

    get periodiciteCharges(): DictionaryItem {
        return this._periodiciteCharges;
    }

    get periodiciteLoyer(): DictionaryItem {
        return this._periodiciteLoyer;
    }

    get showComplementLoyer(): boolean {
        return this._showComplementLoyer;
    }

    set showComplementLoyer(value: boolean) {
        this._showComplementLoyer = value;
    }

    get typeLoyer(): DictionaryItem {
        return this._typeLoyer;
    }

    ngOnInit(): void {
        this.ngLocationManager.current$.pipe(
            tap(_ => this._ngLocation = undefined!),
            delay(1),
            tap(ngLocation => {
                const location = this._locationFactory.ngCreate(ngLocation);

                this._contactsGroupLinkSource.next(location.linkContactsGroup);
                this._periodiciteCharges = location.periodiciteCharges;
                this._periodiciteLoyer = location.periodiciteLoyer;
                this._typeLoyer = location.typeLoyer;
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(ngLocation => this._ngLocation = ngLocation);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    hasErrorOnConstraints(constraints: string[]): boolean {
        return FormService.hasErrorOnConstraints(this._ngForm, constraints);
    }

    onChangeContactsGroup(contactsGroup: ContactsGroup): void {
        let contactsGroupSaved$ = of(contactsGroup);

        if (!contactsGroup.id) {
            contactsGroupSaved$ = this._contactsGroupFactory.save$(contactsGroup).pipe(tap(contactsGroupSaved => {
                this._ngLocation._links.contactsGroup = {href: contactsGroupSaved.linkSelf};
                this._contactsGroupLinkSource.next(contactsGroupSaved.linkSelf);
            }));
        }

        contactsGroupSaved$.pipe(
            switchMap(contactsGroupSaved => from(this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))),
            take(1),
        ).subscribe(ngContactsGroup => this._ngLocation.bien.contactsGroup = ngContactsGroup);
    }

    onChangeLoyerCharges(): void {
        this.ngLocation.operateLoyer();
        this.ngLocation.depotGarantie = this.ngLocation._esk.loyerVierge!;
        this.ngLocation.operateMontantTotalDu();
    }

    onSelectPeriodiciteCharges(periodiciteCharges: DictionarySelected): void {
        this._periodiciteCharges = periodiciteCharges as DictionaryItem;
        if (this._periodiciteCharges && !Array.isArray(this._periodiciteCharges)) {
            this._ngLocation.periodiciteCharges = this.ngDictionariesManager.createFromNg2(this._periodiciteCharges);
        } else {
            this._ngLocation.periodiciteCharges = undefined!;
        }
    }

    onSelectPeriodiciteLoyer(periodiciteLoyer: DictionarySelected): void {
        this._periodiciteLoyer = periodiciteLoyer as DictionaryItem;
        if (this._periodiciteLoyer && !Array.isArray(this._periodiciteLoyer)) {
            this._ngLocation.periodiciteLoyer = this.ngDictionariesManager.createFromNg2(this._periodiciteLoyer);
        } else {
            this._ngLocation.periodiciteLoyer = undefined!;
        }
    }

    onSelectTypeLoyer(typeLoyer: DictionarySelected): void {
        this._typeLoyer = typeLoyer as DictionaryItem;
        if (this._typeLoyer && !Array.isArray(this._typeLoyer)) {
            this._ngLocation.typeLoyer = this.ngDictionariesManager.createFromNg2(this._typeLoyer);
        } else {
            this._ngLocation.typeLoyer = undefined!;
        }
    }
}
