import {inject, Injectable} from '@angular/core';
import {combineLatest, from, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {EstimationNotestimFactory} from '@models/estimations/estimation/notestim/estimation-notestim.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {ModalService} from '@shared/modal/modal.service';
import Etude from '@models/etudes/etude/etude.model';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class EstimationNotestimService {
    private _estimationNotestimFactory = inject(EstimationNotestimFactory);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _currentSource = new ReplaySubject<EstimationNotestim>(1);
    private _current$ = this._currentSource.asObservable();

    get current$(): Observable<EstimationNotestim> {
        return this._current$;
    }

    checkIfCurrentRapportChanged$(estimation: Estimation): Observable<boolean> {
        return combineLatest([
            this.current$.pipe(filter(estimationNotestim => !!estimationNotestim)),
            this.getOne$(estimation),
        ]).pipe(map(([currentEstimationNotestim, lastEstimationNotestim]) =>
            currentEstimationNotestim.linkRapportMedia !== lastEstimationNotestim.linkRapportMedia));
    }

    getOne$(estimation: Estimation): Observable<EstimationNotestim> {
        return this._estimationNotestimFactory.get$(estimation, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return of(this._estimationNotestimFactory.createVirgin());
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }

    initCurrent(estimation: Estimation): void {
        this._currentSource.next(null!);
        this.getOne$(estimation).pipe(take(1))
            .subscribe(estimationNotestim => this._currentSource.next(estimationNotestim));
    }

    saveCurrent$(estimation: Estimation): Observable<EstimationNotestim> {
        return this._estimationNotestimFactory.save$(estimation, {headers: {handledStatusErrors: [422]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 422) {
                    return this._modalService.openConfirmation$({
                        buttonConfirmationLabel: 'Saisir l\'adresse',
                        comments: 'Afin de bénéficier de la puissance de ' + Etude.enablableModules.NOTESTIM.label +
                            ', il est nécessaire de saisir l\'adresse complète de votre bien en estimation.',
                        question: 'Voulez-vous saisir l\'adresse complète de votre bien en estimation ?',
                        title: 'Localisation manquante',
                        status: ModalService.status.WARNING,
                    }).pipe(switchMap(isConfirmed => {
                        if (isConfirmed) {
                            return from(this._router.navigateByUrl('/app/estimations/' + estimation.id.toString() + '/edit/general')).pipe(map(() => undefined!));
                        }

                        return of(this._estimationNotestimFactory.createVirgin());
                    }));
                }

                return throwError(() => httpErrorResponse);
            }),
            tap(estimationNotestim => this._currentSource.next(estimationNotestim)),
        );
    }
}
