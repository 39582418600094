@if (pending$ | appLoadObs | async; as pending) {
  <div [ngClass]="pending.value ? 'tw-waiting' : undefined!" class="tw-flex tw-gap-4">
    @if (options.enabledActions?.save) {
      <button type="button" class="tw-btn-primary-info" (click)="save()" [disabled]="pending.value">Enregistrer</button>
    } @else if (options.enabledActions?.send) {
      <button type="button" class="tw-btn-primary-info" (click)="send()" [disabled]="pending.value">Envoyer</button>
    } @else if (options.enabledActions?.generate) {
      <button type="button" class="tw-btn-primary-info" (click)="generate()" [disabled]="pending.value">Générer</button>
    }

    @if (showDropdown) {
      <button type="button" class="tw-btn-secondary-info tw-w-10" #buttonElement (click)="clickDropdown(buttonElement)">
        <fa-icon [icon]="'ellipsis-v'"/>
      </button>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-8'" class="tw-block tw-w-40"/>
}
