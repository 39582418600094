import {Component, inject, OnInit} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {of} from 'rxjs';
import {EstimationRapportService} from '@features/estimations/estimation/rapport/estimation-rapport.service';
import {EstimationService} from '@models/estimations/estimation/estimation.service';

@Component({selector: 'layout-estimation-rapport', templateUrl: 'layout-estimation.rapport.component.html'})
export class AppLayoutEstimationRapportComponent implements OnInit {
    private _estimationRapportService = inject(EstimationRapportService);
    private _estimationService = inject(EstimationService);

    get ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    ngOnInit(): void {
        of(this._estimationService.getCurrentFromNg()).subscribe(estimation => this._estimationRapportService.init(estimation));
    }
}
