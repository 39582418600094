import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgEtude, NgSite} from '@legacy/app/managers/ressources';

export default function getManagersSite(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('SiteManager', manager);

        /**
         * Manager site
         *
         * @param UtilsManager
         * @param BaseManager
         * @returns {SiteManager}
         */
        manager.$inject = ['UtilsManager', 'BaseManager'];
        function manager(utilsManager: NgUtilsManager, baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('sites.cget', {path: '/etudes/{etudeId}/sites', method: 'GET'});
            baseManager.addRoute('sites.get', {path: '/etudes/{etudeId}/sites/{id}', method: 'GET'});
            baseManager.addRoute('sites.insert', {path: '/etudes/{etudeId}/sites', method: 'POST'});
            baseManager.addRoute('sites.edit', {path: '/etudes/{etudeId}/sites/{id}', method: 'PUT'});
            baseManager.addRoute('sites.update-rank', {path: '/etudes/{etudeId}/sites/{id}/rank', method: 'PATCH'});

            /**
             * SiteManager object
             *
             * @constructor
             */
            angular.extend(SiteManager.prototype, baseManager.__proto__);
            SiteManager.prototype.eskManager = {prefixRoute: 'sites', collectionName: 'items'};

            function SiteManager() {
            }

            /**
             * Get sites for etude
             *
             * @param etude
             * @returns Promise
             */
            SiteManager.prototype.getAllForEtude = function (etude: NgEtude) {
                return this.getAll({etudeId: etude.id});
            };

            /**
             * Create a Site object
             *
             * @param data
             * @param etudeId
             * @returns Site object
             */
            SiteManager.prototype.create = function (data: unknown, etudeId: number) {
                if (angular.isArray(data)) {
                    var sites = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        sites.push(new Site(etudeId, data[i]));
                    }

                    return sites;
                }

                // @ts-ignore
                return new Site(etudeId, data);
            };

            /**
             * Site object
             *
             * @param etudeId
             * @param data
             * @constructor
             */
            angular.extend(Site.prototype, classResource.prototype);
            Site.prototype.eskManager = {prefixRoute: 'sites'};

            function Site(this: NgSite, etudeId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, etudeId};
            }

            /**
             * Sanitize object Site before send to API
             *
             * @returns {Object}
             */
            Site.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet Site pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'commune');

                return sanitizeObject;
            };

            // @ts-ignore
            return new SiteManager();
        }
    })(angularJS);
}
