import {inject, Injectable} from '@angular/core';
import {IRefreshTokenResponse} from '@core/auth/core/auth.interfaces';
import {from, Observable, of} from 'rxjs';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgAuthenticationService} from '@legacy/app/auth/auth';
import {catchError, map} from 'rxjs/operators';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
    static readonly NO_REFRESH_TOKEN = 'NO_REFRESH_TOKEN';
    private _errorConfigurationService = inject(ErrorConfigurationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _excludedUrls: string[] = [];

    constructor() {
        this._errorConfigurationService.addIgnoredError(AuthService.NO_REFRESH_TOKEN);
    }

    // Supprimer les injections des anciens manager
    private get ngAuthenticationService(): NgAuthenticationService {
        return this._ngInjectorService.getService('AuthenticationService');
    }

    get excludedUrls(): string[] {
        return this._excludedUrls;
    }

    addExcludedUrl(url: string): void {
        this._excludedUrls.push(url);
    }

    getAccessToken(): string {
        return this.ngAuthenticationService.getAccessToken();
    }

    isAccessTokenValid(): boolean {
        return this.ngAuthenticationService.isAuthenticated();
    }

    isAuthenticated$(): Observable<boolean> {
        if (this.isAccessTokenValid()) {
            return of(true);
        }

        return this.refreshAccessToken$().pipe(map(_ => this.isAccessTokenValid()));
    }

    logout(): void {
        this.ngAuthenticationService.logout();
    }

    refreshAccessToken$(): Observable<IRefreshTokenResponse> {
        return from(this.ngAuthenticationService.authenticateWithRefreshToken()).pipe(
            map(_ => ({token: this.getAccessToken()} as IRefreshTokenResponse)),
            catchError(() => {
                // @todo Conserver la route sur laquelle on était pour y retourner une fois la connexion actualisée
                this._router.navigateByUrl('/auth/login');

                return of({} as IRefreshTokenResponse);
            }),
        );
    }
}
