import angularJS from '@shared/angularJS/global.ng';
import {cloneDeep, filter as lFilter, remove} from "lodash";
import {IModule, IPromise, IQService} from 'angular';
import {
    NgBienCadastreManager, NgBienChargeManager, NgBienCommoditeManager, NgBienDiagnosticManager, NgBienLogementManager,
    NgBienPhotoManager, NgBienPieceManager, NgCommuneManager, NgContactsGroupManager, NgDictionariesManager,
    NgPhotoManager, NgUserManager, NgUtilsManager
} from '@legacy/app/managers/managers';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {
    NgBien, NgBienCadastre, NgBienCharge, NgBienCollectionItem, NgBienCommodite, NgBienDiagnostic, NgBienLogement,
    NgBienPiece, NgMedia
} from '@legacy/app/managers/ressources';
import {firstValueFrom} from 'rxjs';
import Ng2Bien from '@models/bien/bien.model';
import {UserService} from '@models/users/user/user.service';
import {
    IBienPerformanceEnergetiqueApi
} from '@models/bien/performance-energetique/bien-performance-energetique.interfaces';
import User from '@models/users/user/user.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';
import {CBienPhotosFactory} from '@models/bien/photos/collection/bien-photos.collection.factory';
import {filter, map, take} from 'rxjs/operators';
import {DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';
import {BienCadastreFactory} from '@core/models/bien/cadastres/cadastre/bien-cadastre.factory';
import {BienChargeFactory} from '@models/bien/charges/charge/bien-charge.factory';
import {BienCommoditeFactory} from '@models/bien/commodites/commodite/bien-commodite.factory';
import {BienDiagnosticFactory} from '@models/bien/diagnostics/diagnostic/bien-diagnostic.factory';
import {BienLogementFactory} from '@models/bien/logements/logement/bien-logement.factory';
import {BienSurfaceFactory} from '@models/bien/surfaces/surface/bien-surface.factory';
import {getMockADossierBien} from '@models/dossiers/biens/dossier-biens.fixtures';

export default function getManagersBien(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienManager', manager);

        /**
         * Manager bien
         *
         * @param $q
         * @param BienPhotoManager
         * @param BienCadastreManager
         * @param BienChargeManager
         * @param BienCommoditeManager
         * @param CommuneManager
         * @param ContactsGroupManager
         * @param BienDiagnosticManager
         * @param DictionariesManager
         * @param BienLogementManager
         * @param PhotoManager
         * @param BienPieceManager
         * @param UserManager
         * @param UtilsManager
         * @param $translate
         * @param SoqSweetAlert
         * @param Ng2UserService
         * @param Ng2DictionaryItemService
         * @param Ng2CBienPhotosFactory
         * @param Ng2BienCadastreFactory
         * @param Ng2BienChargeFactory
         * @param Ng2BienCommoditeFactory
         * @param Ng2BienDiagnosticFactory
         * @param Ng2BienLogementFactory
         * @param Ng2BienSurfaceFactory
         */
        manager.$inject = ['$q', 'BienPhotoManager', 'BienCadastreManager', 'BienChargeManager', 'BienCommoditeManager', 'CommuneManager', 'ContactsGroupManager', 'BienDiagnosticManager', 'DictionariesManager', 'BienLogementManager', 'PhotoManager', 'BienPieceManager', 'UserManager', 'UtilsManager', '$translate', 'SoqSweetAlert', 'Ng2UserService', 'Ng2DictionaryItemService', 'Ng2CBienPhotosFactory', 'Ng2BienCadastreFactory', 'Ng2BienChargeFactory', 'Ng2BienCommoditeFactory', 'Ng2BienDiagnosticFactory', 'Ng2BienLogementFactory', 'Ng2BienSurfaceFactory'];
        function manager($q: IQService,
                         bienPhotoManager: NgBienPhotoManager,
                         bienCadastreManager: NgBienCadastreManager,
                         bienChargeManager: NgBienChargeManager,
                         bienCommoditeManager: NgBienCommoditeManager,
                         communeManager: NgCommuneManager,
                         contactsGroupManager: NgContactsGroupManager,
                         bienDiagnosticManager: NgBienDiagnosticManager,
                         dictionariesManager: NgDictionariesManager,
                         bienLogementManager: NgBienLogementManager,
                         photoManager: NgPhotoManager,
                         bienPieceManager: NgBienPieceManager,
                         userManager: NgUserManager,
                         utilsManager: NgUtilsManager,
                         $translate: angular.translate.ITranslateService,
                         soqSweetAlert: NgSoqSweetAlert,
                         ng2UserService: UserService,
                         ng2DictionaryItemService: DictionaryItemService,
                         ng2CBienPhotosFactory: CBienPhotosFactory,
                         ng2BienCadastreFactory: BienCadastreFactory,
                         ng2BienChargeFactory: BienChargeFactory,
                         ng2BienCommoditeFactory: BienCommoditeFactory,
                         ng2BienDiagnosticFactory: BienDiagnosticFactory,
                         ng2BienLogementFactory: BienLogementFactory,
                         ng2BienSurfaceFactory: BienSurfaceFactory) {
            let currentUser: User;

            ng2UserService.current$.pipe(filter(user => !!user)).subscribe(user => currentUser = user);

            /**
             * Bien object
             *
             * @constructor
             */
            BienManager.prototype.eskManager = {name: 'BienManager'};
            function BienManager() {
            }

            /**
             * Init current
             *
             * @param bien
             */
            BienManager.prototype.initCurrent = function (bien: NgBien) {
                this.current = bien;
            };

            /**
             * Create a new Bien instance
             *
             * @param type
             * @param data
             * @returns {manager.Bien}
             */
            BienManager.prototype.create = function (type: string, data: unknown) {
                // @ts-ignore
                return new Bien(type, data);
            };

            /**
             * Create a Bien object
             *
             * @param ng2Bien
             * @param reference
             * @returns manager.Bien
             */
            BienManager.prototype.createFromNg2 = function (type: string, ng2Bien: Ng2Bien, reference: string) {
                if (!ng2Bien) {
                    return $q.resolve(undefined!);
                }

                return contactsGroupManager.createFromNg2(ng2Bien.contactsGroup).then(contactsGroup =>
                    this.create(type, {contactsGroup, reference, titre: ng2Bien.titre})
                );
            };

            /**
             * Bien object
             *
             * @param data
             * @constructor
             */
            Bien.prototype.eskManager = {name: 'Bien'};
            function Bien(this: NgBien, type: string, data: unknown) {
                this.nature = dictionariesManager.createFromNg2(currentUser?.settings[type + 's']?.defaultNature
                    || ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, Ng2Bien.natures.MAISON));
                this.extend(type, data);
            }

            /**
             * Extend the existing Bien with new data
             *
             * @param type
             * @param data
             * @returns {Bien}
             */
            Bien.prototype.extend = function (type: string, data: unknown) {
                const self = this;

                utilsManager.extendDataToResource(self, data);
                self._esk.interne = !!(data as NgBien).interne;
                self._esk.type = type;

                if (!angular.isObject(self.option)) {
                    self.option = {};
                }

                if (!angular.isObject(self.performanceEnergetique)) {
                    self.performanceEnergetique = {};
                }

                if (angular.isArray(self.cadastres)) {
                    self.cadastres = bienCadastreManager.create(self.cadastres, self.id);
                } else {
                    self.cadastres = [];
                }

                if (angular.isArray(self.charges)) {
                    self.charges = bienChargeManager.create(self.charges, self.id);
                } else {
                    self.charges = [];
                }

                if (angular.isArray(self.commodites)) {
                    self.commodites = bienCommoditeManager.create(self.commodites, self.id);
                } else {
                    self.commodites = [];
                }

                if (angular.isArray(self.diagnostics)) {
                    self.diagnostics = bienDiagnosticManager.create(self.diagnostics, self.id);
                } else {
                    self.diagnostics = [];
                }

                if (angular.isArray(self.logements)) {
                    self.logements = bienLogementManager.create(self.logements, self.id);
                } else {
                    self.logements = [];
                }

                if (angular.isArray(self.pieces)) {
                    self.pieces = bienPieceManager.create(self.pieces, self.id);
                } else {
                    self.pieces = [];
                }

                if (angular.isObject(self.modifier)) {
                    self.modifier = userManager.create(self.modifier);
                }

                if (angular.isObject(self.responsableDossier)) {
                    self.responsableDossier = userManager.create(self.responsableDossier);
                }

                self.updatedAt = DateFormat.toDate(self.updatedAt);

                if (angular.isObject(self.contactsGroup)) {
                    self.contactsGroup = contactsGroupManager.create(self.contactsGroup);
                } else {
                    self.contactsGroup = contactsGroupManager.create();
                }

                if (angular.isObject(self.archiver)) {
                    self.archiver = userManager.create(self.archiver);
                }

                if (angular.isObject(self.option.commune)) {
                    self.option.commune = communeManager.create(self.option.commune);
                }

                if (angular.isObject(self.option.voie)) {
                    self.option.nomVoie = self.option.voie.nom;
                }

                if (!self.metadata) {
                    self.metadata = {};
                }

                if (!self.metadata.confidentielles) {
                    self.metadata.confidentielles = {};
                }

                if (!self.metadata.confreres) {
                    self.metadata.confreres = {};
                }

                if (!self.metadata.couts) {
                    self.metadata.couts = {};
                }

                if (!self.metadata.loiAlur) {
                    self.metadata.loiAlur = {};
                }

                if (!self.metadata.occupation) {
                    self.metadata.occupation = {};
                }

                if (angular.isString(self.metadata.occupation.dateDisponibilite)) {
                    self.metadata.occupation.dateDisponibilite = DateFormat.toDate(self.metadata.occupation.dateDisponibilite);
                }

                self._links = angular.isObject(self._links) ? self._links : {};
                if (angular.isArray(self.photos)) {
                    self.photos = bienPhotoManager.create(self.photos, self.id);
                } else {
                    self.photos = [];
                    if (self._links.privatePhotos || self._links.photos) {
                        ng2CBienPhotosFactory.getByLink$(self._links.privatePhotos?.href || self._links.photos.href).pipe(
                            map(cBienPhotos => cBienPhotos.results.map(bienPhoto => bienPhotoManager.createFromNg2(self, bienPhoto))),
                            take(1),
                        ).subscribe(bienPhotos => self.photos = bienPhotos);
                    }
                }

                self.metadata.loiAlur.montantQuotePart = self.metadata.loiAlur.montantQuotePart?.toString().replace('.', ',');
                self.metadata.loiAlur.montantTravaux = self.metadata.loiAlur.montantTravaux?.toString().replace('.', ',');
                self.metadata.loiAlur.nombreCoproprietaires = self.metadata.loiAlur.nombreCoproprietaires?.toString().replace('.', ',');
                self.metadata.loiAlur.nombreLots = self.metadata.loiAlur.nombreLots?.toString().replace('.', ',');
                self.metadata.occupation.chargesActuelles = self.metadata.occupation.chargesActuelles?.toString().replace('.', ',');
                self.metadata.occupation.loyerActuel = self.metadata.occupation.loyerActuel?.toString().replace('.', ',');
                self.option.anneeConstruction = self.option.anneeConstruction?.toString();
                self.option.degreDeclivite = self.option.degreDeclivite?.toString();
                self.option.hauteurSousPlafond = self.option.hauteurSousPlafond?.toString().replace('.', ',');
                self.option.longueurFacade = self.option.longueurFacade?.toString().replace('.', ',');
                self.option.nombreBains = self.option.nombreBains?.toString();
                self.option.nombreChambres = self.option.nombreChambres?.toString();
                self.option.nombreCheminees = self.option.nombreCheminees?.toString();
                self.option.nombreDouches = self.option.nombreDouches?.toString();
                self.option.nombreEtages = self.option.nombreEtages?.toString();
                self.option.nombreEtagesBatiment = self.option.nombreEtagesBatiment?.toString();
                self.option.nombreLogements = self.option.nombreLogements?.toString();
                self.option.nombrePieces = self.option.nombrePieces?.toString();
                self.option.nombrePlacesParking = self.option.nombrePlacesParking?.toString();
                self.option.nombreSallesBains = self.option.nombreSallesBains?.toString();
                self.option.nombreSallesEau = self.option.nombreSallesEau?.toString();
                self.option.nombreWc = self.option.nombreWc?.toString();
                self.option.numeroEtage = self.option.numeroEtage?.toString();
                self.option.surfaceAnnexe = self.option.surfaceAnnexe?.toString().replace('.', ',');
                self.option.surfaceBalcon = self.option.surfaceBalcon?.toString().replace('.', ',');
                self.option.surfaceBatie = self.option.surfaceBatie?.toString().replace('.', ',');
                self.option.surfaceCadastrale = self.option.surfaceCadastrale?.toString().replace('.', ',');
                self.option.surfaceCarrez = self.option.surfaceCarrez?.toString().replace('.', ',');
                self.option.surfaceCave = self.option.surfaceCave?.toString().replace('.', ',');
                self.option.surfaceCour = self.option.surfaceCour?.toString().replace('.', ',');
                self.option.surfaceGarage = self.option.surfaceGarage?.toString().replace('.', ',');
                self.option.surfaceNonBatie = self.option.surfaceNonBatie?.toString().replace('.', ',');
                self.option.surfacePlancher = self.option.surfacePlancher?.toString().replace('.', ',');
                self.option.surfaceSejour = self.option.surfaceSejour?.toString().replace('.', ',');
                self.option.surfaceTerrasse = self.option.surfaceTerrasse?.toString().replace('.', ',');
                self.performanceEnergetique.consommation = self.performanceEnergetique.consommation?.toString().replace('.', ',');
                self.performanceEnergetique.emissions = self.performanceEnergetique.emissions?.toString().replace('.', ',');
                self.superficie = self.superficie?.toString().replace('.', ',');
                self._esk.surfaceCadastraleInit = getSurfaceFromCollection(self.cadastres);
                self.sumLogementsSurface();
                self.sumPiecesSurface();

                return self;
            };

            /**
             * Sanitize object Bien before send to API
             *
             * @returns sanitizeObject
             */
            Bien.prototype.sanitize = function () {
                let sanitizeObject: NgBien & { option: { voieId: number } };

                // Récupération seulement des données de la ressource
                sanitizeObject = cloneDeep(this);

                // Attributs inutiles pour l'API
                sanitizeObject._esk = undefined!;
                sanitizeObject.modifier = undefined!;
                sanitizeObject.updatedAt = undefined!;

                // Objets simples => id
                utilsManager.flattenIdForAttribute(sanitizeObject, 'nature');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'responsableDossier');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'contactsGroup');

                // Tableaux d'objets => Tableaux d'ids
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'assainissements');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'charpentes');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'chauffages');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'clotures');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'connexionsInternet');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'huisseries');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'isolations');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'materiauxConstruction');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'materiauxCouverture');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'volets');
                utilsManager.flattenIdsForAttribute(sanitizeObject, 'vues');

                // Objets possédant leurs propres routes
                sanitizeObject.cadastres = undefined!;
                sanitizeObject.charges = undefined!;
                sanitizeObject.commodites = undefined!;
                sanitizeObject.diagnostics = undefined!;
                sanitizeObject.logements = undefined!;
                sanitizeObject.pieces = undefined!;

                // Options objet => objetId
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'epoqueConstruction');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'etat');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'exposition');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'commune');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'mitoyennete');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'occupation');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'plainPied');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'quartier');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'residence');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'usage');
                utilsManager.flattenIdForAttribute(sanitizeObject.option, 'voie');

                // Nettoyage des données en fonction de la saisie
                if (sanitizeObject.option.aucunAssainissement === true) {
                    sanitizeObject.assainissements = undefined!;
                    sanitizeObject.option.assainissementNonConforme = undefined!;
                    sanitizeObject.option.viabilisationAssainissement = undefined!;
                    sanitizeObject.option.viabilisationAssainissementCollectifRaccorde = undefined!;
                }
                if (sanitizeObject.option.aucunChauffage) sanitizeObject.chauffages = undefined!;
                if (this.option.commune && !this.option.commune.hasVoies) {
                    sanitizeObject.option.voieId = undefined!
                }
                if (this.performanceEnergetique.statutDpe !== DiagnosticStatutsConst.DISPONIBLE) {
                    if (!sanitizeObject.performanceEnergetique) {
                        sanitizeObject.performanceEnergetique = {} as IBienPerformanceEnergetiqueApi;
                    }

                    sanitizeObject.performanceEnergetique.consommation = null!;
                    sanitizeObject.performanceEnergetique.consommationClasse = null!;
                    sanitizeObject.performanceEnergetique.emissions = null!;
                    sanitizeObject.performanceEnergetique.emissionsClasse = null!;
                    sanitizeObject.performanceEnergetique.coutMin = null!;
                    sanitizeObject.performanceEnergetique.coutMax = null!;
                    sanitizeObject.performanceEnergetique.dateReference = null!;
                }

                // Suppression des données obsolètes pour l'API
                if (!!this.metadata.loiAlur.montantQuotePart) {
                    sanitizeObject.metadata.loiAlur.montantQuotePart = +(this.metadata.loiAlur.montantQuotePart.toString().replace(',', '.'));
                } else {
                    sanitizeObject.metadata.loiAlur.montantQuotePart = null!;
                }

                if (!!this.metadata.loiAlur.montantTravaux) {
                    sanitizeObject.metadata.loiAlur.montantTravaux = +(this.metadata.loiAlur.montantTravaux.toString().replace(',', '.'));
                } else {
                    sanitizeObject.metadata.loiAlur.montantTravaux = null!;
                }

                if (!!this.metadata.loiAlur.nombreCoproprietaires) {
                    sanitizeObject.metadata.loiAlur.nombreCoproprietaires = +(this.metadata.loiAlur.nombreCoproprietaires.toString().replace(',', '.'));
                } else {
                    sanitizeObject.metadata.loiAlur.nombreCoproprietaires = null!;
                }

                if (!!this.metadata.loiAlur.nombreLots) {
                    sanitizeObject.metadata.loiAlur.nombreLots = +(this.metadata.loiAlur.nombreLots.toString().replace(',', '.'));
                } else {
                    sanitizeObject.metadata.loiAlur.nombreLots = null!;
                }

                if (!!this.metadata.occupation.chargesActuelles) {
                    sanitizeObject.metadata.occupation.chargesActuelles = +(this.metadata.occupation.chargesActuelles.toString().replace(',', '.'));
                } else {
                    sanitizeObject.metadata.occupation.chargesActuelles = null!;
                }

                if (!!this.metadata.occupation.loyerActuel) {
                    sanitizeObject.metadata.occupation.loyerActuel = +(this.metadata.occupation.loyerActuel.toString().replace(',', '.'));
                } else {
                    sanitizeObject.metadata.occupation.loyerActuel = null!;
                }

                if (!!this.option.anneeConstruction) {
                    sanitizeObject.option.anneeConstruction = +(this.option.anneeConstruction.toString());
                } else {
                    sanitizeObject.option.anneeConstruction = null!;
                }

                if (!!this.option.degreDeclivite) {
                    sanitizeObject.option.degreDeclivite = +(this.option.degreDeclivite.toString());
                } else {
                    sanitizeObject.option.degreDeclivite = null!;
                }

                if (!!this.option.hauteurSousPlafond) {
                    sanitizeObject.option.hauteurSousPlafond = +(this.option.hauteurSousPlafond.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.hauteurSousPlafond = null!;
                }

                if (!!this.option.longueurFacade) {
                    sanitizeObject.option.longueurFacade = +(this.option.longueurFacade.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.longueurFacade = null!;
                }

                if (!!this.option.nombreBains) {
                    sanitizeObject.option.nombreBains = +(this.option.nombreBains.toString());
                } else {
                    sanitizeObject.option.nombreBains = null!;
                }

                if (!!this.option.nombreChambres) {
                    sanitizeObject.option.nombreChambres = +(this.option.nombreChambres.toString());
                } else {
                    sanitizeObject.option.nombreChambres = null!;
                }

                if (!!this.option.nombreCheminees) {
                    sanitizeObject.option.nombreCheminees = +(this.option.nombreCheminees.toString());
                } else {
                    sanitizeObject.option.nombreCheminees = null!;
                }

                if (!!this.option.nombreDouches) {
                    sanitizeObject.option.nombreDouches = +(this.option.nombreDouches.toString());
                } else {
                    sanitizeObject.option.nombreDouches = null!;
                }

                if (!!this.option.nombreEtages) {
                    sanitizeObject.option.nombreEtages = +(this.option.nombreEtages.toString());
                } else {
                    sanitizeObject.option.nombreEtages = null!;
                }

                if (!!this.option.nombreEtagesBatiment) {
                    sanitizeObject.option.nombreEtagesBatiment = +(this.option.nombreEtagesBatiment.toString());
                } else {
                    sanitizeObject.option.nombreEtagesBatiment = null!;
                }

                if (!!this.option.nombreLogements) {
                    sanitizeObject.option.nombreLogements = +(this.option.nombreLogements.toString());
                } else {
                    sanitizeObject.option.nombreLogements = null!;
                }

                if (!!this.option.nombrePieces) {
                    sanitizeObject.option.nombrePieces = +(this.option.nombrePieces.toString());
                } else {
                    sanitizeObject.option.nombrePieces = null!;
                }

                if (!!this.option.nombrePlacesParking) {
                    sanitizeObject.option.nombrePlacesParking = +(this.option.nombrePlacesParking.toString());
                } else {
                    sanitizeObject.option.nombrePlacesParking = null!;
                }

                if (!!this.option.nombreSallesBains) {
                    sanitizeObject.option.nombreSallesBains = +(this.option.nombreSallesBains.toString());
                } else {
                    sanitizeObject.option.nombreSallesBains = null!;
                }

                if (!!this.option.nombreSallesEau) {
                    sanitizeObject.option.nombreSallesEau = +(this.option.nombreSallesEau.toString());
                } else {
                    sanitizeObject.option.nombreSallesEau = null!;
                }

                if (!!this.option.nombreWc) {
                    sanitizeObject.option.nombreWc = +(this.option.nombreWc.toString());
                } else {
                    sanitizeObject.option.nombreWc = null!;
                }

                if (!!this.option.numeroEtage) {
                    sanitizeObject.option.numeroEtage = +(this.option.numeroEtage.toString());
                } else {
                    sanitizeObject.option.numeroEtage = null!;
                }

                if (!!this.option.surfaceAnnexe) {
                    sanitizeObject.option.surfaceAnnexe = +(this.option.surfaceAnnexe.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceAnnexe = null!;
                }

                if (!!this.option.surfaceBalcon) {
                    sanitizeObject.option.surfaceBalcon = +(this.option.surfaceBalcon.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceBalcon = null!;
                }

                if (!!this.option.surfaceBatie) {
                    sanitizeObject.option.surfaceBatie = +(this.option.surfaceBatie.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceBatie = null!;
                }

                if (!!this.option.surfaceCadastrale) {
                    sanitizeObject.option.surfaceCadastrale = +(this.option.surfaceCadastrale.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceCadastrale = null!;
                }

                if (!!this.option.surfaceCarrez) {
                    sanitizeObject.option.surfaceCarrez = +(this.option.surfaceCarrez.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceCarrez = null!;
                }

                if (!!this.option.surfaceCave) {
                    sanitizeObject.option.surfaceCave = +(this.option.surfaceCave.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceCave = null!;
                }

                if (!!this.option.surfaceCour) {
                    sanitizeObject.option.surfaceCour = +(this.option.surfaceCour.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceCour = null!;
                }

                if (!!this.option.surfaceGarage) {
                    sanitizeObject.option.surfaceGarage = +(this.option.surfaceGarage.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceGarage = null!;
                }

                if (!!this.option.surfaceNonBatie) {
                    sanitizeObject.option.surfaceNonBatie = +(this.option.surfaceNonBatie.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceNonBatie = null!;
                }

                if (!!this.option.surfacePlancher) {
                    sanitizeObject.option.surfacePlancher = +(this.option.surfacePlancher.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfacePlancher = null!;
                }

                if (!!this.option.surfaceSejour) {
                    sanitizeObject.option.surfaceSejour = +(this.option.surfaceSejour.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceSejour = null!;
                }

                if (!!this.option.surfaceTerrasse) {
                    sanitizeObject.option.surfaceTerrasse = +(this.option.surfaceTerrasse.toString().replace(',', '.'));
                } else {
                    sanitizeObject.option.surfaceTerrasse = null!;
                }

                if (this.performanceEnergetique.statutDpe === DiagnosticStatutsConst.DISPONIBLE) {
                    if (!!this.performanceEnergetique.consommation) {
                        sanitizeObject.performanceEnergetique.consommation = +(this.performanceEnergetique.consommation.toString().replace(',', '.'));
                    } else {
                        sanitizeObject.performanceEnergetique.consommation = null!;
                    }

                    if (!!this.performanceEnergetique.emissions) {
                        sanitizeObject.performanceEnergetique.emissions = +(this.performanceEnergetique.emissions.toString().replace(',', '.'));
                    } else {
                        sanitizeObject.performanceEnergetique.emissions = null!;
                    }
                }

                if (!!this.superficie) {
                    sanitizeObject.superficie = +(this.superficie.toString().replace(',', '.'));
                } else {
                    sanitizeObject.superficie = null!;
                }

                // @ts-ignore
                if (!sanitizeObject.option.numeroVoie) {
                    // @ts-ignore
                    sanitizeObject.option.numeroVoie = null!;
                }

                return sanitizeObject;
            };

            /**
             * Add Photo to array 'photos' from a media
             *
             * @param media
             * @returns {Promise}
             */
            Bien.prototype.addPhotoFromMedia = function (media: NgMedia) {
                const self = this;

                if (!angular.isNumber(media.id)) {
                    throw new Error("'id' du média non spécifié");
                }

                return photoManager.create({media: {id: media.id}}).save().then(function () {
                    var bienPhoto = bienPhotoManager.create({photo: {id: media.id}}, self.id);

                    return bienPhoto.save().then(function () {
                        if (!angular.isArray(self.photos)) {
                            self.photos = [];
                        }
                        self.photos.push(bienPhoto);

                        return bienPhoto;
                    });
                });
            };

            /**
             * Save collection
             *
             * @param bien
             * @returns {Promise}
             */
            Bien.prototype.save = function (bien: NgBien & Record<string, unknown>) {
                const collectionPromises = [] as IPromise<unknown>[];
                const self = this;

                if (!angular.isNumber(bien.id)) {
                    collectionPromises.push(saveElementCollection('cadastres'));
                    collectionPromises.push(saveElementCollection('charges'));
                    collectionPromises.push(saveElementCollection('commodites'));
                    collectionPromises.push(saveElementCollection('photos'));
                    if (![Ng2Bien.natures.TERRAIN_A_BATIR, Ng2Bien.natures.AUTRE_TERRAIN].includes(self.nature.code)) {
                        collectionPromises.push(saveElementCollection('diagnostics'));
                    }
                    if (self.nature.code === Ng2Bien.natures.IMMEUBLE) {
                        collectionPromises.push(saveElementCollection('logements'));
                    }
                    if (![Ng2Bien.natures.IMMEUBLE, Ng2Bien.natures.TERRAIN_A_BATIR, Ng2Bien.natures.AUTRE_TERRAIN].includes(self.nature.code)) {
                        collectionPromises.push(saveElementCollection('pieces'));
                    }
                }

                return $q.all(collectionPromises).then(() => firstValueFrom(ng2UserService.last$)).then(currentUser => {
                    self.updatedAt = DateFormat.toDate();
                    self.modifier = userManager.createFromNg2(currentUser);
                    self.sumLogementsSurface();
                    self.sumPiecesSurface();
                });

                /**
                 * Execute save() on each item of collection
                 *
                 * @param collectionName
                 * @returns {Promise}
                 */
                function saveElementCollection(collectionName: string) {
                    const elementCollectionPromises = [] as IPromise<unknown>[];

                    self[collectionName] = bien[collectionName];
                    for (var i = 0; i < self[collectionName].length; i++) {
                        if (self[collectionName][i]._esk.flush !== true) {
                            self[collectionName][i]._esk.defaultRouteParams.bienId = self.id;
                            if (self[collectionName][i].isValid()) {
                                elementCollectionPromises.push(self[collectionName][i].save());
                            }
                        }
                    }

                    return $q.all(elementCollectionPromises).then(function () {
                        bien[collectionName] = self[collectionName];
                    });
                }
            };

            /**
             * Has saved cadastres or not
             *
             * @returns {boolean}
             */
            Bien.prototype.hasSavedCadastres = function () {
                for (var i = 0; i < this.cadastres.length; i++) {
                    if (this.cadastres[i]._esk.flush === true) {
                        return true;
                    }
                }

                return false;
            };

            /**
             * Add virgin item to collection
             *
             * @param collectionName
             * @returns {Object}
             */
            Bien.prototype.addVirginToCollection = function (collectionName: string) {
                var manager;
                var item = null;
                var defaultData = {};

                // Vérification qu'une ligne vierge existe déjà
                angular.forEach(this[collectionName], function (itemCollection) {
                    if (itemCollection.isEmpty()) item = itemCollection;
                });
                if (angular.isObject(item)) return item;

                // Initialisation des valeurs
                switch (collectionName) {
                    case 'cadastres':
                        manager = bienCadastreManager;
                        defaultData = {prefixe: '000', commune: this.option.commune};
                        break;
                    case 'charges':
                        manager = bienChargeManager;
                        break;
                    case 'commodites':
                        manager = bienCommoditeManager;
                        break;
                    case 'diagnostics':
                        manager = bienDiagnosticManager;
                        break;
                    case 'logements':
                        manager = bienLogementManager;
                        break;
                    case 'pieces':
                        manager = bienPieceManager;
                        break;
                    default:
                        throw new Error("La collection '" + collectionName + "' n'est pas définie.");
                }

                // Création d'un item supplémentaire à la collection
                item = manager.create(defaultData, this.id);
                this[collectionName].push(item);

                return item;
            };

            /**
             * Save item from collectionName
             *
             * @param collectionName
             * @param item
             * @param ignoreOtherItems
             * @returns {Promise}
             */
            Bien.prototype.saveItem = function (collectionName: string, item: NgBienCollectionItem, ignoreOtherItems = false): IPromise<void> {
                const self = this;

                if (!item.isValid()) {
                    return $q.reject();
                }

                if (!angular.isNumber(self.id) || self.id <= 0) {
                    return $q.resolve();
                }

                if (item._esk.loading) {
                    item._esk.toSave = true;

                    return $q.reject();
                }

                item._esk.toSave = false;
                item._esk.loading = true;

                return item.save().then(() => {
                    if (collectionName === 'logements') {
                        self.sumLogementsSurface();
                    }

                    if (collectionName === 'pieces') {
                        self.sumPiecesSurface();
                    }
                }).finally(function () {
                    if (!ignoreOtherItems) {
                        angular.forEach(lFilter(self[collectionName], {_esk: {toSave: true}}), collectionNameItem => self.saveItem(collectionName, collectionNameItem));
                    }

                    item._esk.loading = false;
                });
            };

            /**
             * Remove item from collectionName
             *
             * @param collectionName
             * @param item
             * @returns {Promise}
             */
            Bien.prototype.removeItem = function (collectionName: string, item: NgBienCollectionItem) {
                var promise;
                const self = this;

                if (item.id) {
                    let ceData: string;

                    if (collectionName === 'cadastres') {
                        ceData = "ce cadastre";
                    } else if (collectionName === 'charges') {
                        ceData = "cette charge";
                    } else if (collectionName === 'commodites') {
                        ceData = "cette commodité";
                    } else if (collectionName === 'diagnostics') {
                        ceData = "ce diagnostic";
                    } else if (collectionName === 'logements') {
                        ceData = "ce logement";
                    } else if (collectionName === 'pieces') {
                        ceData = "cette pièce";
                    } else {
                        throw new Error("La collection '" + collectionName + "' n'est pas définie.");
                    }

                    promise = soqSweetAlert.dangerConfirmation($translate.instant('remove.confirm.TITLE'), $translate.instant('remove.confirm.MESSAGE', {ceData: ceData}), {
                        confirmButtonText: $translate.instant('remove.confirm.CONFIRMATION')
                    });
                } else {
                    promise = $q.resolve();
                }

                return promise.then(function () {
                    item._esk.loading = true;

                    if (item.id) {
                        // Création d'un ADossierBien un peu particulier pour la suppression car il n'y a besoin que des id
                        const ng2DossierBien = getMockADossierBien({bien: self, id: self.id});

                        if (collectionName === 'cadastres') {
                            return firstValueFrom(ng2BienCadastreFactory.delete$(ng2DossierBien, ng2BienCadastreFactory.ngCreate(item as NgBienCadastre)));
                        } else if (collectionName === 'charges') {
                            return firstValueFrom(ng2BienChargeFactory.delete$(ng2DossierBien, ng2BienChargeFactory.ngCreate(item as NgBienCharge)));
                        } else if (collectionName === 'commodites') {
                            return firstValueFrom(ng2BienCommoditeFactory.delete$(ng2DossierBien, ng2BienCommoditeFactory.ngCreate(item as NgBienCommodite)));
                        } else if (collectionName === 'diagnostics') {
                            return firstValueFrom(ng2BienDiagnosticFactory.delete$(ng2DossierBien, ng2BienDiagnosticFactory.ngCreate(item as NgBienDiagnostic)));
                        } else if (collectionName === 'logements') {
                            return firstValueFrom(ng2BienLogementFactory.delete$(ng2DossierBien, ng2BienLogementFactory.ngCreate(item as NgBienLogement)));
                        } else if (collectionName === 'pieces') {
                            return firstValueFrom(ng2BienSurfaceFactory.delete$(ng2DossierBien, ng2BienSurfaceFactory.ngCreate(item as NgBienPiece)));
                        }
                    }

                    return $q.resolve();
                }).then(() => remove(self[collectionName], item), () => {
                }).finally(() => item._esk.loading = false);
            };

            /**
             * Operate surface cadastrale from references cadastrales
             *
             * @param init
             */
            Bien.prototype.operateSurfaceCadastraleFromRefs = function (init = false) {
                let surfaceCadastrale = getSurfaceFromCollection(this.cadastres);

                if (init || !angular.isNumber(this.option.surfaceCadastrale) || this.option.surfaceCadastrale === this._esk.surfaceCadastraleInit) {
                    this.option.surfaceCadastrale = surfaceCadastrale;
                }

                this._esk.surfaceCadastraleInit = surfaceCadastrale;
            };

            /**
             * Init descriptif
             *
             * @param typeDescriptif
             */
            Bien.prototype.initDescriptif = function (typeDescriptif: string) {
                switch (typeDescriptif) {
                    case 'MANDAT' :
                        this.descriptifMandat = this.descriptif;
                        break;

                    case 'AFFICHETTE' :
                        this.descriptifAffichette = this.descriptif;
                        break;

                    case 'SITE_INTERNET' :
                        this.descriptifSiteInternet = this.descriptif;
                        break;

                    case 'JOURNAUX_PAPIER' :
                        this.descriptifJournalPapier = this.descriptif;
                        break;
                }
            };

            /**
             * Update network adresse
             */
            Bien.prototype.updateNetworkAddress = function () {
                this.option.adresseReseau = [this.option.numeroVoie, this.option.nomVoie].join(' ');
            };

            /**
             * Sum surface logements
             */
            Bien.prototype.sumLogementsSurface = function () {
                this._esk.surfaceLogements = getSurfaceFromCollection(this.logements);
            };

            /**
             * Sum surface pieces
             */
            Bien.prototype.sumPiecesSurface = function () {
                this._esk.surfacePieces = getSurfaceFromCollection(this.pieces);
                this._esk.surfacePiecesHabitables = this.pieces?.filter?.(item => !!item && !!item.habitable && !!item.surface)
                    .map(item => item.surface)
                    .map(surface => +(surface.toString().replace(',', '.')))
                    .reduce((sum, surface) => sum + surface, 0);
                this._esk.surfacePiecesNonHabitables = this.pieces?.filter?.(item => !!item && item.habitable === false && !!item.surface)
                    .map(item => item.surface)
                    .map(surface => +(surface.toString().replace(',', '.')))
                    .reduce((sum, surface) => sum + surface, 0);
            };

            /**
             * Get complete URL
             */
            Bien.prototype.getSanitizeUrlVisiteVirtuelle = function () {
                if (!this.option.urlVisiteVirtuelle) {
                    return;
                }

                if (this.option.urlVisiteVirtuelle.startsWith('http://') || this.option.urlVisiteVirtuelle.startsWith('https://')) {
                    return this.option.urlVisiteVirtuelle;
                }

                return 'http://' + this.option.urlVisiteVirtuelle;
            };

            /**
             * Get surface cadastrale from references cadastrales
             *
             * @param collection
             * @return number
             */
            function getSurfaceFromCollection(collection: { surface: number }[]): number {
                return collection?.filter?.(item => !!item && !!item.surface)
                    .map(item => item.surface)
                    .map(surface => +(surface.toString().replace(',', '.')))
                    .reduce((sum, surface) => sum + surface, 0);
            }

            // @ts-ignore
            return new BienManager();
        }
    })(angularJS);
}
