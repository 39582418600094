import {inject, Injectable} from '@angular/core';
import {INotestimBienApi} from '@models/notestim/biens/bien/notestim-bien.interfaces';
import NotestimBien from '@models/notestim/biens/bien/notestim-bien.model';
import {Observable} from 'rxjs';
import {NotestimBiensApiService} from '@models/notestim/biens/notestim-biens.api.service';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {map} from 'rxjs/operators';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class NotestimBienFactory {
    private _notestimBiensApiService = inject(NotestimBiensApiService);
    private _venteFactory = inject(VenteFactory);

    create(notestimBienApi: INotestimBienApi): NotestimBien {
        const notestimBien = this.createVirgin();

        notestimBien.libelle = notestimBienApi.libelle;
        notestimBien.notestimId = notestimBienApi.notestimId;

        return notestimBien;
    }

    createVirgin(): NotestimBien {
        return new NotestimBien();
    }

    import$(notestimBien: NotestimBien): Observable<Vente> {
        return this._notestimBiensApiService.import$(notestimBien.notestimId).pipe(
            map(venteApi => this._venteFactory.create(venteApi))
        );
    }
}
