import {inject, Injectable} from "@angular/core";
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NotestimApiService} from '@models/notestim/notestim.api.service';

@Injectable({providedIn: 'root'})
export class NotestimFactory {
    private _notestimsApiService = inject(NotestimApiService);

    getUrlSettings$(): Observable<string> {
        return this._notestimsApiService.getSettings$().pipe(map(notestimApi => notestimApi.iframeUrl));
    }
}
