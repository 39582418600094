<div class="tw-h-full tw-overflow-auto">
  <div class="views noty-intro">
    <div class="mask rgba-noty-strong justify-content-center align-items-center">
      <div class="noty-overlay-effect">
        <div class="container">
          <div class="container-login">
            <div class="tw-text-center">
              <h1 class="display-4 ">Bienvenue sur</h1>

              <div class="tw-relative tw-max-w-[400px] tw-w-full tw-aspect-video tw-mx-auto">
                <img ngSrc="assets/images/noty/logo.svg" fill>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-offset-3 col-sm-6">
                <div class="main-login"><div class="box-login"><router-outlet/></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <esk-footer/>
</div>
