import {inject, Injectable} from '@angular/core';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {Observable, throwError} from 'rxjs';
import {
    IEstimationReferenceApi
} from '@models/estimations/estimation/references/reference/estimation-reference.interfaces';
import {map} from 'rxjs/operators';
import {
    EstimationReferencesApiService
} from '@models/estimations/estimation/references/estimation-references.api.service';
import Vente from '@models/ventes/vente/vente.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import {VALIDATION_ERRORS_TYPE} from '@models/model.constants';
import Reference from '@models/references/reference/reference.model';
import {
    EstimationReferenceSourceConst, EstimationReferenceSourceType
} from '@models/estimations/estimation/references/estimation-references.constants';

@Injectable({providedIn: 'root'})
export class EstimationReferenceFactory {
    private _estimationReferencesApiService = inject(EstimationReferencesApiService);

    create(estimationReferenceApi: IEstimationReferenceApi): EstimationReference {
        const estimationReference = this.createVirgin(estimationReferenceApi.uuid);

        estimationReference.adresse = estimationReferenceApi.adresse;
        estimationReference.dateVente = estimationReferenceApi.dateVente;
        estimationReference.epoqueId = estimationReferenceApi.epoqueId;
        estimationReference.loyer = estimationReferenceApi.loyer;
        estimationReference.natureId = estimationReferenceApi.natureId;
        estimationReference.nombreChambres = estimationReferenceApi.nombreChambres;
        estimationReference.nombreEtages = estimationReferenceApi.nombreEtages;
        estimationReference.nombrePieces = estimationReferenceApi.nombrePieces;
        estimationReference.prixVente = estimationReferenceApi.prixVente;
        estimationReference.sourceType = estimationReferenceApi.sourceType;
        estimationReference.sourceUuid = estimationReferenceApi.sourceUuid;
        estimationReference.superficie = estimationReferenceApi.superficie;
        estimationReference.surfaceCadastrale = estimationReferenceApi.surfaceCadastrale;
        if (estimationReferenceApi.communeId) {
            estimationReference.communeId = estimationReferenceApi.communeId;
            estimationReference.linkCommune = '/communes/' + estimationReference.communeId.toString();
        }

        return estimationReference;
    }

    createFromReference(reference: Reference, source: EstimationReferenceSourceType): EstimationReference {
        const estimationReference = this.createVirgin();

        estimationReference.adresse = reference.adresse;
        estimationReference.communeId = reference.communeId;
        estimationReference.dateVente = reference.dateVente;
        estimationReference.epoqueId = reference.epoqueId;
        estimationReference.linkCommune = reference.linkCommune;
        estimationReference.loyer = reference.loyer;
        estimationReference.natureId = reference.natureId;
        estimationReference.nombreChambres = reference.nombreChambres;
        estimationReference.nombreEtages = reference.nombreEtages;
        estimationReference.nombrePieces = reference.nombrePieces;
        estimationReference.prixVente = reference.prixVente;
        estimationReference.sourceType = source;
        estimationReference.sourceUuid = reference.uuid;
        estimationReference.superficie = reference.superficie;
        estimationReference.surfaceCadastrale = reference.surfaceCadastrale;

        return estimationReference;
    }

    createFromVente(vente: Vente): EstimationReference {
        const estimationReference = this.createVirgin();

        estimationReference.adresse = vente.bien.inlineAdresse;
        estimationReference.communeId = vente.bien.commune?.id;
        estimationReference.dateVente = vente.archiveDateVente;
        estimationReference.epoqueId = vente.bien.epoqueConstruction?.id;
        estimationReference.linkCommune = vente.bien.commune?.linkSelf;
        estimationReference.loyer = vente.bien.loyerActuel;
        estimationReference.natureId = vente.bien.nature.id;
        estimationReference.nombreChambres = vente.bien.nombreChambres;
        estimationReference.nombreEtages = vente.bien.nombreEtages;
        estimationReference.nombrePieces = vente.bien.nombrePieces;
        estimationReference.prixVente = vente.archivePrixCession;
        estimationReference.sourceType = EstimationReferenceSourceConst.VENTE;
        estimationReference.sourceUuid = vente.uuid;
        estimationReference.superficie = vente.bien.superficie;
        estimationReference.surfaceCadastrale = vente.bien.surfaceCadastrale;

        return estimationReference;
    }

    createVirgin(uuid?: string): EstimationReference {
        return new EstimationReference(uuid ?? EstimationReference.statuts.NEW);
    }

    delete$(estimation: Estimation, estimationReference: EstimationReference): Observable<void> {
        return this._estimationReferencesApiService.delete$(estimation.id.toString(), estimationReference.uuid);
    }

    forApi(estimationReference: EstimationReference): IEstimationReferenceApi {
        const estimationReferenceApi = {} as IEstimationReferenceApi;

        estimationReferenceApi.adresse = estimationReference.adresse;
        estimationReferenceApi.dateVente = estimationReference.dateVente;
        estimationReferenceApi.communeId = estimationReference.communeId;
        estimationReferenceApi.epoqueId = estimationReference.epoqueId;
        estimationReferenceApi.loyer = estimationReference.loyer;
        estimationReferenceApi.natureId = estimationReference.natureId;
        estimationReferenceApi.nombreChambres = estimationReference.nombreChambres;
        estimationReferenceApi.nombreEtages = estimationReference.nombreEtages;
        estimationReferenceApi.nombrePieces = estimationReference.nombrePieces;
        estimationReferenceApi.prixVente = estimationReference.prixVente;
        estimationReferenceApi.sourceType = estimationReference.sourceType;
        estimationReferenceApi.sourceUuid = estimationReference.sourceUuid;
        estimationReferenceApi.superficie = estimationReference.superficie;
        estimationReferenceApi.surfaceCadastrale = estimationReference.surfaceCadastrale;

        return estimationReferenceApi;
    }

    save$(estimation: Estimation, estimationReference: EstimationReference): Observable<EstimationReference> {
        return this._estimationReferencesApiService.save$(estimation.id.toString(), estimationReference.uuid, this.forApi(estimationReference))
            .pipe(map(estimationReferenceApi => this.create(estimationReferenceApi)));
    }

    updateRank$(estimation: Estimation, estimationReference: EstimationReference, rank: number): Observable<void> {
        return this._estimationReferencesApiService.updateRank$(estimation.id.toString(), estimationReference.uuid, rank);
    }

    // @todo Mettre cette méthode dans EstimationReferenceService ?
    validAndSave$(estimation: Estimation, estimationReference: EstimationReference): Observable<EstimationReference> {
        const validationErrors = estimationReference.getValidationErrors();

        if (validationErrors.length > 0) {
            return throwError(() => ({type: VALIDATION_ERRORS_TYPE, validationErrors}));
        }

        return this.save$(estimation, estimationReference);
    }
}
