import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgDemandeurManager, NgDemandeurRechercheManager} from '@legacy/app/managers/managers';
import {NgDemandeur, NgDemandeurRecherche} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {Router} from '@angular/router';
import {delay, firstValueFrom, from, Observable, of, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {IPromise} from 'angular';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';

@Component({selector: 'layout-demandeur-edit', templateUrl: 'layout-demandeur-edit.component.html'})
export class AppLayoutDemandeurEditComponent implements OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    private _apiImpersonationService = inject(ApiImpersonationService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _demandeur!: NgDemandeur;
    private readonly _onDestroy$ = new Subject<void>();
    private _redirect = true;
    private _saveIsDone = false;
    private _saving = false;

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDemandeurManager(): NgDemandeurManager {
        return this._ngInjectorService.getService('DemandeurManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDemandeurRechercheManager(): NgDemandeurRechercheManager {
        return this._ngInjectorService.getService('DemandeurRechercheManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    get demandeur(): NgDemandeur {
        return this._demandeur;
    }

    get redirect(): boolean {
        return this._redirect;
    }

    set redirect(value: boolean) {
        this._redirect = value;
    }

    get saving(): boolean {
        return this._saving;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        this._redirect = false;
        if (this.demandeur?.statut !== Demandeur.statuts.BROUILLON) {
            this.editForm.onSubmit(undefined!);
            if (!this.editForm.valid) {
                this._formDemandeurNotValid();

                return of(false);
            }

            return from(this.savePromise().then(_ => true, _ => false));
        }

        return of(true);
    }

    ngOnInit(): void {
        this.ngDemandeurManager.current$.pipe(takeUntil(this._onDestroy$)).subscribe(ngDemandeur => this._demandeur = ngDemandeur);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.demandeur?.id) {
            this.save();
        }
    }

    isSearchValid(currentSearch: NgDemandeurRecherche, defaultReturn = true): IPromise<boolean> {
        if (!currentSearch) {
            return Promise.resolve(defaultReturn);
        }

        return (currentSearch.updateSearchLocalisations ? currentSearch.updateSearchLocalisations(currentSearch) : Promise.resolve()).then(() => {
            let criteria: string = undefined!;

            if (!currentSearch.natures || currentSearch.natures.length <= 0) {
                criteria = 'une nature';
            } else if ((!currentSearch.areas || currentSearch.areas.length <= 0)
                && (!currentSearch.communes || currentSearch.communes.length <= 0)
                && (!currentSearch.quartiers || currentSearch.quartiers.length <= 0)) {
                criteria = 'une commune ou une région ou un département ou un quartier';
            }

            if (criteria) {
                return this.ngSoqSweetAlert.warningMessage('Modification de la recherche',
                    'Vous devez saisir au moins ' + criteria + '.<br><br>Modifiez avant d\'enregistrer.').then(() => Promise.resolve(false));
            }

            return Promise.resolve(true);
        });
    }

    save(redirect = false): void {
        const isDemandeurNew = !this.demandeur.id;

        if (this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isDemandeurNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if (this.demandeur.statut !== Demandeur.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
            this._formDemandeurNotValid();
            return;
        }

        /*
                If (this.demandeur.statut === Demandeur.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formDemandeurNotValid();
                }
        */

        // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
        this.savePromise().then(isDemandeurRechercheSaved => {
            if (isDemandeurNew) {
                return this._router.navigateByUrl('/app/demandeurs/' + this.demandeur.id.toString(), {replaceUrl: true});
            } else {
                if (!redirect || !isDemandeurRechercheSaved) {
                    return;
                }

                return this._router.navigateByUrl('/app/demandeurs/' + this.demandeur.id.toString() + '/resultats');
            }
        }).finally(() => this._saving = false);
    }

    savePromise(): IPromise<boolean> {
        const isDemandeurNew = !this.demandeur.id;

        this._saveIsDone = true;
        this._saving = true;

        // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
        return firstValueFrom(of(undefined).pipe(
            delay(600),
            switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.demandeur.contactsGroup))),
        )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
            .then(contactsGroup => this.demandeur.contactsGroup = contactsGroup)
            .then(_ => this.demandeur.save())
            .then(ngDemandeur => {
                ngDemandeur.recherches = this.demandeur.recherches;
                this.ngDemandeurManager.current = ngDemandeur;

                return this.demandeur.recherches[0];
            })
            .then(currentSearch => {
                if (isDemandeurNew) {
                    return Promise.resolve(undefined!);
                }

                return this.isSearchValid(currentSearch, false).then(isSearchValid => {
                    if (!isSearchValid) {
                        return Promise.resolve(false);
                    }

                    return currentSearch.save()
                        .then(() => currentSearch.launch())
                        .then(() => this.ngDemandeurRechercheManager.getOneById<NgDemandeurRecherche>(this.ngDemandeurManager.current.id, this.demandeur.recherches[0].id)
                            .then(updatedSearch => this.demandeur.recherches[0].updateStatus(updatedSearch))
                        )
                        .then(() => this.ngDemandeurManager.emitCurrent(this.demandeur))
                        .then(() => true);
                });
            }).finally(() => this._saving = false);
    }

    private _formDemandeurNotValid(): void {
        this.ngSoqSweetAlert.warningMessage('Saisie des champs', 'Certains champs n\'ont pas de valeurs correctes.');

        return;
    }
}
