import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDictionariesManager, NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgLocationMandatsCollection} from '@legacy/app/managers/collections';
import {NgLocationMandat} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';
import Location from '@models/locations/location/location.model';

export default function getManagersLocationMandat(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('LocationMandatManager', manager);

        /**
         * Manager locationMandat
         *
         * @param BaseManager
         * @param UtilsManager
         * @param DictionariesManager
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'DictionariesManager', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         dictionariesManager: NgDictionariesManager,
                         ng2DictionaryItemService: DictionaryItemService) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('location-mandats.cget', {path: '/locations/{locationId}/mandats', method: 'GET'});
            baseManager.addRoute('location-mandats.get', {
                path: '/locations/{locationId}/mandats/{id}.{_format}',
                method: 'GET'
            });
            baseManager.addRoute('location-mandats.insert', {path: '/locations/{locationId}/mandats', method: 'POST'});
            baseManager.addRoute('location-mandats.edit', {
                path: '/locations/{locationId}/mandats/{id}',
                method: 'PUT'
            });

            /**
             * LocationMandatManager object
             *
             * @constructor
             */
            angular.extend(LocationMandatManager.prototype, baseManager.__proto__);
            LocationMandatManager.prototype.eskManager = {prefixRoute: 'location-mandats', collectionName: 'items'};
            function LocationMandatManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @returns {Promise}
             */
            LocationMandatManager.prototype.getAll = function (locationId: number) {
                return baseManager.getAll.call(this, {locationId: locationId}).then(function (locationMandats) {
                    const locationMandatsCollection: NgLocationMandatsCollection = locationMandats as NgLocationMandatsCollection;

                    angular.forEach(locationMandatsCollection.collection, function (locationMandat) {
                        locationMandat._esk.defaultRouteParams!.locationId = locationId;
                    });

                    return locationMandatsCollection;
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @param resourceId
             * @returns {Promise}
             */
            LocationMandatManager.prototype.getOneById = function (locationId: number, resourceId: number) {
                return this.get('get', {
                    locationId: locationId,
                    id: resourceId,
                    _format: 'json'
                }).then(function (locationMandat: NgLocationMandat) {
                    locationMandat._esk.defaultRouteParams!.locationId = locationId;

                    return locationMandat;
                });
            };

            /**
             * Create a LocationMandat object
             *
             * @param data
             * @param locationId
             * @returns manager.LocationMandat object || Array of manager.LocationMandat objects
             */
            LocationMandatManager.prototype.create = function (data: unknown, locationId: number) {
                if (angular.isArray(data)) {
                    var mandats = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        mandats.push(new LocationMandat(locationId, data[i]));
                    }

                    return mandats;
                }

                // @ts-ignore
                return new LocationMandat(locationId, data);
            };

            /**
             * LocationMandat object
             *
             * @param locationId
             * @param data
             * @constructor
             */
            angular.extend(LocationMandat.prototype, classResource.prototype);
            LocationMandat.prototype.eskManager = {prefixRoute: 'location-mandats'};
            function LocationMandat(this: NgLocationMandat, locationId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, locationId: locationId};
            }

            /**
             * Extend the existing LocationMandat with new data
             *
             * @param data
             * @returns {LocationMandat}
             */
            LocationMandat.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isString(this.type)) {
                    this.type = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_MANDAT_TYPES, this.type));
                } else {
                    this.type = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_MANDAT_TYPES, Location.mandatTypes.GESTION));
                }

                if (angular.isString(this.date)) {
                    this.date = DateFormat.toDate(this.date);
                }

                if (angular.isString(this.dateFin)) {
                    this.dateFin = DateFormat.toDate(this.dateFin);
                }

                return this;
            };

            /**
             * Sanitize object LocationMandat before send to API
             *
             * @returns {Object}
             */
            LocationMandat.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet LocationMandat pour l'envoi à l'API
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'type');
                sanitizeObject.date = DateFormat.toAPI(this.date);
                sanitizeObject.dateFin = DateFormat.toAPI(this.dateFin);

                return sanitizeObject;
            };

            // @ts-ignore
            return new LocationMandatManager();
        }
    })(angularJS);
}
