import Bien from '@models/bien/bien.model';
import {getMockContactsGroup, getMockContactsGroupApi} from '@models/contacts-group/contacts-group.fixtures';
import {getMockQuartier, getMockQuartierApi} from '@models/quartiers/quartiers.fixtures';
import {getMockCommune, getMockCommuneApi} from '@models/communes/communes.fixtures';
import {
    getMockDictionaryItem, getMockDictionaryItemApi
} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import {getMockBienDiagnostic, getMockBienDiagnosticApi} from '@models/bien/diagnostics/bien-diagnostics.fixtures';
import {getMockBienSurface, getMockBienSurfaceApi} from '@models/bien/surfaces/bien-surfaces.fixtures';
import {getMockBienLogement, getMockBienLogementApi} from '@models/bien/logements/bien-logements.fixtures';
import {getMockBienCommodite, getMockBienCommoditeApi} from '@models/bien/commodites/bien-commodites.fixtures';
import {
    IBienApi, IBienMetadataApi, IBienOptionApi, IBienTitreDescriptifQueryParameters
} from '@models/bien/bien.interfaces';
import {getMockBienCharge, getMockBienChargeApi} from '@models/bien/charges/bien-charges.fixtures';
import {
    getMockBienPerformanceEnergetique, getMockBienPerformanceEnergetiqueApi
} from '@models/bien/performance-energetique/bien-performance-energetique.fixtures';
import {getMockDateString} from '@shared/date/date.format.fixtures';
import {getMockLinksApi} from '@models/links/links.fixtures';
import {getMockCommuneVoie, getMockCommuneVoieApi} from '@models/communes/commune/voies/commune-voies.fixtures';
import {getMockBienCadastre, getMockBienCadastreApi} from '@models/bien/cadastres/bien-cadastres.fixtures';
import {getMockQueryParameters} from '@shared/texts/texts.fixtures';

export const getMockBien = (): Bien => {
    const suffix = 'bien';
    const bien = new Bien();

    bien.accesHandicape = true;
    bien.administrationSyndicLoiAlur = true;
    bien.adresseReseau = 'adresseReseau-' + suffix;
    bien.alarmeLocation = true;
    bien.alarmePleinePropriete = true;
    bien.ancienneReference = 'ancienneReference-' + suffix;
    bien.anneeConstruction = 4;
    bien.annexe = true;
    bien.ascenseur = true;
    bien.assainissementNonConforme = true;
    bien.assainissements = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.aucunAssainissement = true;
    bien.aucunChauffage = true;
    bien.balcon = true;
    bien.cadastres = [getMockBienCadastre(), getMockBienCadastre()];
    bien.causeVente = 'causeVente-' + suffix;
    bien.cave = true;
    bien.chambreRdc = true;
    bien.charges = [getMockBienCharge(), getMockBienCharge()];
    bien.chargesLocatives = 8;
    bien.charpentes = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.chauffageCollectif = true;
    bien.chauffages = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.cheminee = true;
    bien.chemineeFonctionnelle = true;
    bien.chemineeOrnement = true;
    bien.citerneGazLocation = true;
    bien.citerneGazPleinePropriete = true;
    bien.clesVisite = 'clesVisite-' + suffix;
    bien.clotures = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.codePostal = 'codePostal-' + suffix;
    bien.combles = true;
    bien.commentsAccessibiliteEtEnvironnement = 'commentsAccessibiliteEtEnvironnement-' + suffix;
    bien.commentsAgencementExterieur = 'commentsAgencementExterieur-' + suffix;
    bien.commentsAgencementInterieur = 'commentsAgencementInterieur-' + suffix;
    bien.commentsBatiment = 'commentsBatiment-' + suffix;
    bien.commentsCharges = 'commentsCharges-' + suffix;
    bien.commentsConfidentiel = 'commentsConfidentiel-' + suffix;
    bien.commentsConstruction = 'commentsConstruction-' + suffix;
    bien.commentsCouts = 'commentsCouts-' + suffix;
    bien.commentsLoiAlur = 'commentsLoiAlur-' + suffix;
    bien.commentsTerrain = 'commentsTerrain-' + suffix;
    bien.commentsUrbanisme = 'commentsUrbanisme-' + suffix;
    bien.commentsVue = 'commentsVue-' + suffix;
    bien.commodites = [getMockBienCommodite(), getMockBienCommodite()];
    bien.commune = getMockCommune();
    bien.complementAdresse1 = 'complementAdresse1-' + suffix;
    bien.connexionsInternet = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.contactsGroup = getMockContactsGroup();
    bien.coordonneesSyndicLoiAlur = 'coordonneesSyndicLoiAlur-' + suffix;
    bien.cour = true;
    bien.cuisineEquipee = true;
    bien.cuveFuelAerienne = true;
    bien.cuveFuelEnterree = true;
    bien.cuveInactiveDegazee = true;
    bien.cuveInactiveNeutralizee = true;
    bien.dateDisponibilite = getMockDateString();
    bien.declivite = true;
    bien.degreDeclivite = 7;
    bien.dernierEtage = true;
    bien.designation = 'designation-' + suffix;
    bien.descriptif = 'descriptif-' + suffix;
    bien.descriptifAffichette = 'descriptifAffichette-' + suffix;
    bien.descriptifJournalPapier = 'descriptifJournalPapier-' + suffix;
    bien.descriptifMandat = 'descriptifMandat-' + suffix;
    bien.descriptifSiteInternet = 'descriptifSiteInternet-' + suffix;
    bien.detecteurFumee = true;
    bien.diagnosticsRealises = [getMockBienDiagnostic(), getMockBienDiagnostic()];
    bien.epoqueConstruction = getMockDictionaryItem();
    bien.etat = getMockDictionaryItem();
    bien.exceptionnel = true;
    bien.exposition = getMockDictionaryItem();
    bien.garage = true;
    bien.grenier = true;
    bien.hasLoiAlur = true;
    bien.hauteurSousPlafond = 3;
    bien.huisseries = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.isolations = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.linkNotaire = 'linkNotaire-' + suffix;
    bien.linkPhotos = 'linkPhotos-' + suffix;
    bien.linkPremierePhoto = 'linkPremierePhoto-' + suffix;
    bien.linkPrivatePhotos = 'linkPrivatePhotos-' + suffix;
    bien.linkPrivatePremierePhoto = 'linkPrivatePremierePhoto-' + suffix;
    bien.linkProtectedPhotos = 'linkProtectedPhotos-' + suffix;
    bien.linkProtectedPremierePhoto = 'linkProtectedPremierePhoto-' + suffix;
    bien.logements = [getMockBienLogement(), getMockBienLogement()];
    bien.longueurFacade = 9;
    bien.loyerActuel = 4;
    bien.materiauxConstruction = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.materiauxCouverture = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.mitoyennete = getMockDictionaryItem();
    bien.montantQuotePartLoiAlur = 9;
    bien.montantTravauxLoiAlur = 4;
    bien.nature = getMockDictionaryItem();
    bien.nombreBains = 6;
    bien.nombreChambres = 4;
    bien.nombreCheminees = 1;
    bien.nombreCoproprietairesLoiAlur = 2;
    bien.nombreDouches = 9;
    bien.nombreEtages = 7;
    bien.nombreEtagesBatiment = 2;
    bien.nombreLogements = 8;
    bien.nombreLotsLoiAlur = 5;
    bien.nombrePieces = 3;
    bien.nombrePlacesParking = 6;
    bien.nombreSallesBains = 3;
    bien.nombreSallesEau = 4;
    bien.nombreWc = 2;
    bien.nomVoie = 'nomVoie-' + suffix;
    bien.notaireId = 9;
    bien.numeroCle = 'numeroCle-' + suffix;
    bien.numeroEtage = 5;
    bien.numeroVoie = 6;
    bien.occupation = getMockDictionaryItem();
    bien.panneaux = 'panneaux-' + suffix;
    bien.panneauxPhotovoltaiques = true;
    bien.performanceEnergetique = getMockBienPerformanceEnergetique();
    bien.pieceEauRdc = true;
    bien.piscine = true;
    bien.plainPied = getMockDictionaryItem();
    bien.planSauvegardeLoiAlur = true;
    bien.pointEauCampagne = true;
    bien.pointEauVille = true;
    bien.quartier = getMockQuartier();
    bien.referenceInterne = 'referenceInterne-' + suffix;
    bien.residence = getMockDictionaryItem();
    bien.residenceService = true;
    bien.responsableDossierId = 6;
    bien.servitude = 'servitude-' + suffix;
    bien.sousSol = true;
    bien.superficie = 5;
    bien.surfaceAnnexe = 8;
    bien.surfaceBalcon = 7;
    bien.surfaceBatie = 6;
    bien.surfaceCadastrale = 2;
    bien.surfaceCarrez = 1;
    bien.surfaceCave = 4;
    bien.surfaceCour = 3;
    bien.surfaceGarage = 2;
    bien.surfaceNonBatie = 9;
    bien.surfacePlancher = 5;
    bien.surfaces = [getMockBienSurface(), getMockBienSurface()];
    bien.surfaceSejour = 1;
    bien.surfaceTerrasse = 4;
    bien.terrasse = true;
    bien.titre = 'titre-' + suffix;
    bien.urlVisiteVirtuelle = 'urlVisiteVirtuelle-' + suffix;
    bien.usage = getMockDictionaryItem();
    bien.veranda = true;
    bien.viabilisable = true;
    bien.viabilisationAssainissement = 'viabilisationAssainissement-' + suffix;
    bien.viabilisationAssainissementCollectifRaccorde = true;
    bien.viabilisationComments = 'viabilisationComments-' + suffix;
    bien.viabilisationEau = true;
    bien.viabilisationElectricite = true;
    bien.viabilisationFibre = true;
    bien.viabilisationGaz = true;
    bien.viabilisationTelecom = true;
    bien.viabilisationVoirie = true;
    bien.voie = getMockCommuneVoie();
    bien.volets = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.vues = [getMockDictionaryItem(), getMockDictionaryItem()];
    bien.wcBroyeur = true;
    bien.zoneInondable = true;
    bien.zonePLU = 'zonePLU-' + suffix;
    bien.zoneRisques = true;

    return bien;
};

export const getMockBienApi = (objectFirstLevel = true): IBienApi => {
    const suffix = 'bien-api';
    const mockPerformanceEnergetique = getMockBienPerformanceEnergetiqueApi();
    const bienApi: IBienApi = {
        _embedded: {},
        _links: {},
        ancienneReference: 'ancienneReference-' + suffix,
        dateArchive: 'dateArchive-' + suffix,
        descriptif: 'descriptif-' + suffix,
        descriptifAffichette: 'descriptifAffichette-' + suffix,
        descriptifJournalPapier: 'descriptifJournalPapier-' + suffix,
        descriptifMandat: 'descriptifMandat-' + suffix,
        descriptifSiteInternet: 'descriptifSiteInternet-' + suffix,
        notaireId: 9,
        reference: 'reference-' + suffix,
        referenceInterne: 'referenceInterne-' + suffix,
        superficie: 5,
        titre: 'titre-' + suffix,
        uuid: 'uuid-' + suffix,

        // Voir l'interface pour la raison de la dissociation de ces attributs
        assainissements: [1, 2],
        charpentes: [1, 2],
        chauffages: [1, 2],
        clotures: [1, 2],
        connexionsInternet: [1, 2],
        contactsGroupId: 1,
        huisseries: [1, 2],
        isolations: [1, 2],
        materiauxConstruction: [1, 2],
        materiauxCouverture: [1, 2],
        metadata: getMockBienMetadataApi(),
        natureId: 4,
        option: getMockBienOptionApi(),
        performanceEnergetique: mockPerformanceEnergetique,
        volets: [1, 2],
        vues: [1, 2],
    } as IBienApi;

    if (objectFirstLevel) {
        bienApi._links = getMockLinksApi([
            'notaire',
            'photos',
            'premierePhoto',
            'privatePhotos',
            'privatePremierePhoto',
            'protectedPhotos',
            'protectedPremierePhoto',
            'responsableDossier',
        ]);
        bienApi._embedded.assainissements = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.cadastres = [getMockBienCadastreApi(), getMockBienCadastreApi()];
        bienApi._embedded.charges = [getMockBienChargeApi(), getMockBienChargeApi()];
        bienApi._embedded.charpentes = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.chauffages = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.clotures = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.commodites = [getMockBienCommoditeApi(), getMockBienCommoditeApi()];
        bienApi._embedded.connexionsInternet = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.contactsGroup = getMockContactsGroupApi();
        bienApi._embedded.diagnostics = [getMockBienDiagnosticApi(), getMockBienDiagnosticApi()];
        bienApi._embedded.huisseries = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.isolations = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.logements = [getMockBienLogementApi(), getMockBienLogementApi()];
        bienApi._embedded.materiauxConstruction = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.materiauxCouverture = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.metadata = getMockBienMetadataApi();
        bienApi._embedded.nature = getMockDictionaryItemApi();
        bienApi._embedded.option = getMockBienOptionApi();
        bienApi._embedded.performanceEnergetique = mockPerformanceEnergetique;
        bienApi._embedded.pieces = [getMockBienSurfaceApi(), getMockBienSurfaceApi()];
        bienApi._embedded.volets = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
        bienApi._embedded.vues = [getMockDictionaryItemApi(), getMockDictionaryItemApi()];
    }

    return bienApi;
};

export const getMockBienMetadataApi = (): IBienMetadataApi => {
    const suffix = 'bien-metadata-api';

    return {
        confidentielles: {
            comments: 'comments-' + suffix,
            numeroCle: 'numeroCle-' + suffix,
            panneaux: 'panneaux-' + suffix,
        },
        confreres: {clesVisite: 'clesVisite-' + suffix, comments: 'comments-' + suffix},
        couts: {comments: 'commentsCouts-' + suffix},
        loiAlur: {
            administrationSyndic: true,
            coordonneesSyndic: 'coordonneesSyndic-' + suffix,
            comments: 'commentsLoiAlur-' + suffix,
            loiAlur: true,
            montantQuotePart: 9,
            montantTravaux: 4,
            nombreCoproprietaires: 2,
            nombreLots: 5,
            planSauvegarde: true,
        },
        occupation: {chargesActuelles: 8, dateDisponibilite: getMockDateString(), loyerActuel: 2},
    };
};

export const getMockBienOptionApi = (): IBienOptionApi => {
    const suffix = 'bien-option-api';

    return {
        _embedded: {
            commune: getMockCommuneApi(),
            epoqueConstruction: getMockDictionaryItemApi(),
            etat: getMockDictionaryItemApi(),
            exposition: getMockDictionaryItemApi(),
            mitoyennete: getMockDictionaryItemApi(),
            occupation: getMockDictionaryItemApi(),
            plainPied: getMockDictionaryItemApi(),
            quartier: getMockQuartierApi(),
            residence: getMockDictionaryItemApi(),
            usage: getMockDictionaryItemApi(),
            voie: getMockCommuneVoieApi(),
        },
        _links: getMockLinksApi('premierePhoto'),
        accesHandicape: true,
        adresseReseau: 'adresseReseau-' + suffix,
        alarmeLocation: true,
        alarmePleinePropriete: true,
        anneeConstruction: 8,
        annexe: true,
        ascenseur: true,
        assainissementNonConforme: true,
        aucunAssainissement: true,
        aucunChauffage: true,
        balcon: true,
        causeVente: 'causeVente-' + suffix,
        cave: true,
        chambreRdc: true,
        chauffageCollectif: true,
        cheminee: true,
        chemineeFonctionnelle: true,
        chemineeOrnement: true,
        citerneGazLocation: true,
        citerneGazPleinePropriete: true,
        codePostal: 'codePostal-' + suffix,
        comblesAmenageables: true,
        commentsAccessibiliteEtEnvironnement: 'commentsAccessibiliteEtEnvironnement-' + suffix,
        commentsAgencementExterieur: 'commentsAgencementExterieur-' + suffix,
        commentsAgencementInterieur: 'commentsAgencementInterieur-' + suffix,
        commentsBatiment: 'commentsBatiment-' + suffix,
        commentsConstruction: 'commentsConstruction-' + suffix,
        commentsCouts: 'commentsCouts-' + suffix,
        commentsTerrain: 'commentsTerrain-' + suffix,
        commentsVue: 'commentsVue-' + suffix,
        complementAdresse1: 'complementAdresse1-' + suffix,
        cour: true,
        cuisineEquipee: true,
        cuveFuelAerienne: true,
        cuveFuelEnterree: true,
        cuveInactiveDegazee: true,
        cuveInactiveNeutralizee: true,
        declivite: true,
        degreDeclivite: 3,
        dernierEtage: true,
        designation: 'designation-' + suffix,
        detecteurFumee: true,
        diagDate: 'diagDate-' + suffix,
        exceptionnel: true,
        garage: true,
        grenier: true,
        hauteurSousPlafond: 3,
        longueurFacade: 8,
        nombreBains: 1,
        nombreChambres: 7,
        nombreCheminees: 4,
        nombreDouches: 6,
        nombreEtages: 5,
        nombreEtagesBatiment: 7,
        nombreLogements: 9,
        nombrePieces: 3,
        nombrePlacesParking: 1,
        nombreSallesBains: 6,
        nombreSallesEau: 7,
        nombreWc: 4,
        nomVoie: 'nomVoie-' + suffix,
        numeroEtage: 3,
        numeroVoie: 6,
        panneauxPhotovoltaiques: true,
        pieceEauRdc: true,
        piscine: true,
        pointEauCampagne: true,
        pointEauVille: true,
        residenceService: true,
        risquesComments: 'risquesComments-' + suffix,
        risquesZone: true,
        servitude: 'servitude-' + suffix,
        sousSol: true,
        surfaceAnnexe: 1,
        surfaceBalcon: 3,
        surfaceBatie: 6,
        surfaceCadastrale: 5,
        surfaceCarrez: 1,
        surfaceCave: 4,
        surfaceCour: 2,
        surfaceGarage: 3,
        surfaceNonBatie: 8,
        surfacePlancher: 9,
        surfaceSejour: 4,
        surfaceTerrasse: 7,
        terrasse: true,
        urbanismeComments: 'urbanismeComments-' + suffix,
        urbanismeZonePLU: 'urbanismeZonePLU-' + suffix,
        urlVisiteVirtuelle: 'urlVisiteVirtuelle-' + suffix,
        veranda: true,
        viabilisable: true,
        viabilisationAssainissement: 'viabilisationAssainissement-' + suffix,
        viabilisationAssainissementCollectifRaccorde: true,
        viabilisationComments: 'viabilisationComments-' + suffix,
        viabilisationEau: true,
        viabilisationElectricite: true,
        viabilisationFibre: true,
        viabilisationGaz: true,
        viabilisationTelecom: true,
        viabilisationVoirie: true,
        wcBroyeur: true,
        zoneInondable: true,

        // Voir l'interface pour la raison de la dissociation de ces attributs
        communeId: 1,
        epoqueConstructionId: 2,
        etatId: 3,
        expositionId: 4,
        mitoyenneteId: 5,
        occupationId: 6,
        plainPiedId: 7,
        quartierId: 8,
        residenceId: 9,
        usageId: 1,
        voieId: 2,
    };
};

export const getMockBienTitreDescriptifQueryParameters = (suffix = 'bien-titre-descriptif-query-parameters'): IBienTitreDescriptifQueryParameters =>
    ({...getMockQueryParameters(), bienId: 'bienId-' + suffix});

export class MockBienApiService {
    getDescriptif$(): void {
    }

    getTitre$(): void {
    }
}

export class MockBienFactory {
    create(): void {
    }

    createFromDossierBienDiffusionEtat(): void {
    }

    createVirgin(): void {
    }

    forApi(): void {
    }

    forApiMetadata(): void {
    }

    forApiOption(): void {
    }

    getDescriptif$(): void {
    }

    getTitre$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienService {
    createVirgin$(): void {
    }

    saveCollection$(): void {
    }

    saveCollectionForNew$(): void {
    }

    updateTitreDescriptif$(): void {
    }
}
