import {IDossierBienDiffusionEtatApi} from '@models/dossiers/biens/bien/dossier-bien.interfaces';
import CADossierBiens from '@models/dossiers/biens/collection/dossier-biens.collection.model.abstract';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {getMockLinks} from '@models/links/links.fixtures';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {getMockBien} from '@models/bien/bien.fixtures';
import {ICADossierBiensQueryParameters} from '@models/dossiers/biens/collection/dossier-biens.interfaces';
import {getMockQueryParameterConditionString, getMockQueryParameters} from '@shared/texts/texts.fixtures';

class MyADossierBien extends ADossierBien {
    readonly dossierType: DossierBienType;

    constructor(uuid: string, id: number, reference: string, dossierBienType: DossierBienType) {
        super(uuid, id, reference);
        this.dossierType = dossierBienType;
    }
}

class MyCADossierBiens extends CADossierBiens {
    dossierType: DossierBienType;

    constructor(dossierBienTYpe: DossierBienType) {
        super();
        this.dossierType = dossierBienTYpe;
    }
}

export const getMockADossierBien = (data = {}): ADossierBien => {
    const aDossierBienData = data as ADossierBien;
    const suffix = 'dossier-bien';
    const aDossierBien = new MyADossierBien(aDossierBienData.uuid ?? 'uuid-' + suffix, aDossierBienData.id ?? 5, 'reference-' + suffix, aDossierBienData.dossierType ?? DossierBienTypesConst.VENTE);

    aDossierBien.archiveComments = 'archiveComments-' + suffix;
    aDossierBien.bien = aDossierBienData.bien ?? getMockBien();
    aDossierBien.statut = 'statut-' + suffix;
    aDossierBien.setInterne(true);

    return aDossierBien;
};

export const getMockCADossierBiens = (): CADossierBiens => {
    const cADossierBiens = new MyCADossierBiens(DossierBienTypesConst.VENTE);

    cADossierBiens.links = getMockLinks();
    cADossierBiens.page = 4;
    cADossierBiens.pages = 8;
    cADossierBiens.perPage = 2;
    cADossierBiens.results = [getMockADossierBien(), getMockADossierBien()];
    cADossierBiens.total = 5;

    return cADossierBiens;
};

export const getMockCADossierBiensQueryParameters = (suffix = 'ca-dossier-biens-query-parameters'): ICADossierBiensQueryParameters => ({
    ...getMockQueryParameters(suffix),
    ...{statut: getMockQueryParameterConditionString(suffix)},
});

export const getMockDossierBienDiffusionEtatApi = (suffix = 'dossier-bien-diffusion-etat-api'): IDossierBienDiffusionEtatApi => ({
    codeNature: 'codeNature-' + suffix,
    id: 9,
    nomProprietairesGroup: 'nomProprietairesGroup-' + suffix,
    premierePhoto: 'premierePhoto-' + suffix,
    reference: 'reference-' + suffix,
});

export class MockCDossierBiensService {
    getFiltered$(): void {
    }

    getFromDemandeurRechercheResultats$(): void {
    }
}

export class MockDossierBienFactory {
    createFromDiffusionEtat(): void {
    }

    getByLink$(): void {
    }

    openVirtualVisit$(): void {
    }

    ngCreate(): void {
    }
}

export class MockDossierBienFieldsService {
    isEditionVisible(): void {
    }

    isResumeVisible(): void {
    }
}

export class MockDossierBienService {
    askToBeSaved(): void {
    }

    createFromDiffusionEtat(): void {
    }

    getFromDemandeurRechercheResultat$(): void {
    }

    getNgCurrent$(): void {
    }

    openVirtualVisit$(): void {
    }

    updateTitreDescriptif$(): void {
    }
}

export class MockDossierBiensApiService {
    deleteVirtualVisit$(): void {
    }
}

export class MockDossierBiensService {
    getByLinks$(): void {
    }
}
