import {inject, Injectable} from '@angular/core';
import {NgBienDossier} from '@legacy/app/managers/ressources';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable, of, switchMap} from 'rxjs';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {NgEstimationManager, NgLocationManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import DemandeurRechercheResultat from '@core/models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {LocationService} from '@models/locations/location/location.service';
import {VenteService} from '@models/ventes/vente/vente.service';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {BienService} from '@models/bien/bien.service';

@Injectable({providedIn: 'root'})
export class DossierBienService {
    private _bienService = inject(BienService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _estimationFactory = inject(EstimationFactory);
    private _estimationService = inject(EstimationService);
    private _etudeService = inject(EtudeService);
    private _locationFactory = inject(LocationFactory);
    private _locationService = inject(LocationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _venteFactory = inject(VenteFactory);
    private _venteService = inject(VenteService);

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService('EstimationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    askToBeSaved(dossierBien: ADossierBien): void {
        if (dossierBien.isEstimation()) {
            this._estimationService.askToBeSaved();
        } else if (dossierBien.isLocation()) {
            this._locationService.askToBeSaved();
        } else if (dossierBien.isVente()) {
            this._venteService.askToBeSaved();
        }
    }

    getFromDemandeurRechercheResultat$(demandeurRechercheResultat: DemandeurRechercheResultat): Observable<ADossierBien> {
        if (demandeurRechercheResultat.hasLocation()) {
            return this._locationFactory.getByLink$(demandeurRechercheResultat.linkLocation);
        } else if (demandeurRechercheResultat.hasVente()) {
            return this._venteFactory.getByLink$(demandeurRechercheResultat.linkVente);
        }

        return of(undefined as unknown as ADossierBien);
    }

    getNgCurrent$(dossierBienType: DossierBienType): Observable<NgBienDossier> {
        if (dossierBienType === DossierBienTypesConst.ESTIMATION) {
            return this.ngEstimationManager.current$;
        } else if (dossierBienType === DossierBienTypesConst.LOCATION) {
            return this.ngLocationManager.current$;
        } else if (dossierBienType === DossierBienTypesConst.VENTE) {
            return this.ngVenteManager.current$;
        }

        return of(undefined as unknown as NgBienDossier);
    }

    openVirtualVisit$(dossierBien: ADossierBien): Observable<Window> {
        return this._etudeService.last$.pipe(switchMap(etude => this._dossierBienFactory.openVirtualVisit$(etude, dossierBien)));
    };

    updateTitreDescriptif$(dossierBien: ADossierBien): Observable<ADossierBien> {
        return this._bienService.updateTitreDescriptif$(dossierBien).pipe(switchMap(() => {
            if (dossierBien.isEstimation()) {
                return this._estimationFactory.save$(dossierBien as Estimation);
            } else if (dossierBien.isLocation()) {
                return this._locationFactory.save$(dossierBien as Location);
            } else if (dossierBien.isVente()) {
                return this._venteFactory.save$(dossierBien as Vente);
            }

            return of(dossierBien);
        }));
    }
}
