import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IBienChargeApi} from '@models/bien/charges/charge/bien-charge.interfaces';
import BienCharge from '@models/bien/charges/charge/bien-charge.model';

@Injectable({providedIn: 'root'})
export class BienChargesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(dossierBienId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/biens/${dossierBienId}/charges/${id}`);
    }

    get$(dossierBienId: string, id: string): Observable<IBienChargeApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/charges/${id}`);
    }

    save$(dossierBienId: string, id: string, bienChargeApi: IBienChargeApi): Observable<IBienChargeApi> {
        if (id === BienCharge.STATUT_NEW) {
            return this._modelApiService.post$(`/biens/${dossierBienId}/charges`, bienChargeApi);
        }

        return this._modelApiService.put$(`/biens/${dossierBienId}/charges/${id}`, bienChargeApi)
            .pipe(switchMap(_ => this.get$(dossierBienId, id)));
    }
}
