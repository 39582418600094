import BienDiagnostic from '@models/bien/diagnostics/diagnostic/bien-diagnostic.model';
import {
    getMockDictionaryItem, getMockDictionaryItemApi
} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import {IBienDiagnosticApi} from '@models/bien/diagnostics/diagnostic/bien-diagnostic.interfaces';
import {getMockDateString} from '@shared/date/date.format.fixtures';

export const getMockBienDiagnostic = (): BienDiagnostic => {
    const suffix = 'bien-diagnostic';
    const bienDiagnostic = new BienDiagnostic('uuid-' + suffix, 2);

    bienDiagnostic.date = getMockDateString();
    bienDiagnostic.comments = 'comments-' + suffix;
    bienDiagnostic.type = getMockDictionaryItem();

    return bienDiagnostic;
};

export const getMockBienDiagnosticApi = (): IBienDiagnosticApi => {
    const suffix = 'bien-diagnostic-api';

    return {
        _embedded: {typeDiagnostic: getMockDictionaryItemApi()},
        comments: 'comments-' + suffix,
        date: getMockDateString(),
        id: 2,
        typeDiagnosticId: 6,
        uuid: 'uuid-' + suffix,
    };
};

export class MockBienDiagnosticFactory {
    create(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienDiagnosticsApiService {
    delete$(): void {
    }

    get$(): void {
    }

    save$(): void {
    }
}

export class MockBienDiagnosticsService {
    save$(): void {
    }
}
