import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgSoqValidation} from '@legacy/app/soqrate/soqrate';
import {NgContact, NgEmailRecipient} from '@legacy/app/managers/ressources';
import {EmailRecipientFactory} from '@models/emails/email/recipients/recipient/email-recipient.factory';
import {EmailFactory} from '@models/emails/email/email.factory';

export default function getEskSelectRecipient(module: IModule): void {
    (angular => {
        'use strict';

        /**
         * @example <esk-select-recipient></esk-select-recipient>
         */
        module.component('eskSelectRecipient', {
            bindings: {
                email: '=ngModel',
                recipientType: '@',
                placeholder: '@'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/recipient.html'
        });

        /**
         * Controller to select recipient
         *
         * @param $translate
         * @param SoqValidation
         * @param Ng2ToasterService
         * @param Ng2EmailFactory
         * @param Ng2EmailRecipientFactory
         */
        Controller.$inject = ['$translate', 'SoqValidation', 'Ng2ToasterService', 'Ng2EmailFactory', 'Ng2EmailRecipientFactory'];
        function Controller(this: any,
                            $translate: angular.translate.ITranslateService,
                            soqValidation: NgSoqValidation,
                            ng2ToasterService: ToasterService,
                            ng2EmailFactory: EmailFactory,
                            ng2EmailRecipientFactory: EmailRecipientFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.addRecipient = addRecipient;
            $ctrl.filterParticipants = filterParticipants;
            $ctrl.isDisabled = isDisabled;
            $ctrl.removeRecipient = removeRecipient;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.newRecipient = null;
                $ctrl.options = {placeholder: $ctrl.placeholder, otherwise: true};
            }

            /**
             * Add recipient
             *
             * @param recipient
             */
            function addRecipient(recipient: NgEmailRecipient) {
                if (angular.isObject(recipient) && soqValidation.emailAddress(recipient.emailAddress)) {
                    $ctrl.email.addRecipient(recipient.emailAddress, $ctrl.recipientType, recipient.convivialName);
                } else {
                    if (angular.isDefined(recipient)) {
                        ng2ToasterService.warning($translate.instant('mail.TITLE'), $translate.instant('mail.recipient_incorrect.MESSAGE', {mailAddress: recipient.emailAddress}));
                    }
                }

                $ctrl.newRecipient = null;
            }

            /**
             * Filter participants
             *
             * @param participants
             * @returns {Array}
             */
            function filterParticipants(participants: NgContact[]) {
                const filteredParticipants: NgContact[] = [];

                participants.forEach(participant => {
                    if (participant.person!.emailAddress) {
                        filteredParticipants.push(participant);
                    }
                });

                return filteredParticipants;
            }

            /**
             * Disabled recipient already select
             *
             * @param participant
             * @returns {boolean}
             */
            function isDisabled(participant: NgContact) {
                return angular.isDefined(find($ctrl.email.recipients, {'emailAddress': participant.person!.emailAddress}));
            }

            function removeRecipient(ngEmailRecipient: NgEmailRecipient) {
                const index = ($ctrl.email.recipients as NgEmailRecipient[]).findIndex(p => p._esk.uuid === ngEmailRecipient._esk.uuid);

                if ($ctrl.email.recipients[index].id) {
                    ng2EmailRecipientFactory.delete$(ng2EmailFactory.ngCreate($ctrl.email), ng2EmailRecipientFactory.ngCreate(ngEmailRecipient))
                        .subscribe(() => $ctrl.email.recipients.splice(index, 1));
                } else {
                    $ctrl.email.recipients.splice(index, 1);
                }
            }
        }
    })(angularJS);
}
