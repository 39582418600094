import BienCharge from '@models/bien/charges/charge/bien-charge.model';
import {IBienChargeApi} from '@models/bien/charges/charge/bien-charge.interfaces';
import {
    getMockDictionaryItem, getMockDictionaryItemApi
} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';

export const getMockBienCharge = (): BienCharge => {
    const suffix = 'bien-charge';
    const bienCharge = new BienCharge('uuid-' + suffix, 6);

    bienCharge.charge = getMockDictionaryItem();
    bienCharge.comments = 'comments-' + suffix;
    bienCharge.cout = 7;
    bienCharge.periodicite = getMockDictionaryItem();

    return bienCharge;
};

export const getMockBienChargeApi = (): IBienChargeApi => {
    const suffix = 'bien-charge-api';

    return {
        _embedded: {charge: getMockDictionaryItemApi(), periodicite: getMockDictionaryItemApi()},
        chargeId: 3,
        comments: 'comments-' + suffix,
        cout: 7,
        id: 6,
        periodiciteId: 5,
        uuid: 'uuid-' + suffix,
    };
};

export class MockBienChargeFactory {
    create(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienChargesApiService {
    delete$(): void {
    }

    get$(): void {
    }

    save$(): void {
    }
}

export class MockBienChargesService {
    save$(): void {
    }
}
