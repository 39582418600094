import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgBienCharge} from '@legacy/app/managers/ressources';

export default function getManagersBienCharge(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienChargeManager', manager);

        /**
         * Manager charge
         *
         * @param UtilsManager
         * @param BaseManager
         * @param $q
         * @returns {BienChargeManager}
         */
        manager.$inject = ['UtilsManager', 'BaseManager', '$q'];
        function manager(utilsManager: NgUtilsManager, baseManager: NgManager, $q: IQService) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('charges.cget', {path: '/biens/{bienId}/charges', method: 'GET'});
            baseManager.addRoute('charges.insert', {path: '/biens/{bienId}/charges', method: 'POST'});
            baseManager.addRoute('charges.edit', {path: '/biens/{bienId}/charges/{id}', method: 'PUT'});
            baseManager.addRoute('charges.update-rank', {path: '/biens/{bienId}/charges/{id}/rank', method: 'PATCH'});

            /**
             * BienChargeManager object
             *
             * @constructor
             */
            angular.extend(BienChargeManager.prototype, baseManager.__proto__);
            BienChargeManager.prototype.eskManager = {prefixRoute: 'charges', collectionName: 'items'};
            function BienChargeManager() {
            }

            /**
             * Create a Charge object
             *
             * @param data
             * @param bienId
             * @returns manager.BienCharge object || Array of manager.BienCharge objects
             */
            BienChargeManager.prototype.create = function (data: unknown, bienId: number) {
                if (angular.isArray(data)) {
                    var charges = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        charges.push(new BienCharge(bienId, data[i]));
                    }

                    return charges;
                }

                // @ts-ignore
                return new BienCharge(bienId, data);
            };

            /**
             * BienCharge object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(BienCharge.prototype, classResource.prototype);
            BienCharge.prototype.eskManager = {prefixRoute: 'charges'};
            function BienCharge(this: NgBienCharge, bienId: number, data: unknown) {
                this.extend(data);
                this.uuid = this.id?.toString();
                this._esk.defaultRouteParams = {id: this.id, bienId: bienId};
            }

            /**
             * Extend the existing BienCharge with new data
             *
             * @param data
             * @returns {BienCharge}
             */
            BienCharge.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.cout = this.cout?.toString().replace('.', ',');

                return this;
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            BienCharge.prototype.save = function () {
                const self = this;

                self._esk.savedAskSource.next();
                if (!self.isValid()) {
                    return $q.reject('NOT_VALID');
                }

                return classResource.prototype.save.call(self);
            };

            /**
             * Sanitize object BienCharge before send to API
             *
             * @returns {Object}
             */
            BienCharge.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet BienCharge pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'charge');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'periodicite');
                if (!!this.cout) {
                    sanitizeObject.cout = +(this.cout.toString().replace(',', '.'));
                } else {
                    sanitizeObject.cout = null!;
                }

                return sanitizeObject;
            };

            /**
             * Test validity and fill _esk.errors
             *
             * @returns {boolean}
             */
            BienCharge.prototype.isValid = function () {
                const sanitizeObject = this.sanitize();

                this._esk.errors = {
                    charge: !sanitizeObject.chargeId,
                    periodicite: !sanitizeObject.periodiciteId,
                    cout: !!this.cout && (isNaN(sanitizeObject.cout) || !/^(?=.*[1-9])\d*(?:.\d*)?\s*$/.test(sanitizeObject.cout)),
                };

                return !this._esk.errors.charge && !this._esk.errors.periodicite && !this._esk.errors.cout;
            };

            /**
             * Test if BienCharge is empty
             *
             * @returns {boolean}
             */
            BienCharge.prototype.isEmpty = function () {
                return !angular.isObject(this.charge)
                    && !angular.isObject(this.periodicite)
                    && (!angular.isNumber(this.cout) || this.cout <= 0)
                    && (!angular.isString(this.comments) || this.comments === '');
            };

            // @ts-ignore
            return new BienChargeManager();
        }
    })(angularJS);
}
