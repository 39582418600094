<div class="tw-mb-5"><h3><fa-icon [icon]="'edit'"/> {{ngBienCharge.uuid ? 'Édition' : 'Ajout'}} d'une charge</h3></div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
      class="tw-w-[80vw] sm:tw-w-[30rem] md:tw-w-[40rem] lg:tw-w-[50rem]">
  <div class="tw-py-6">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienChargeType.formInput">
          <label>Type de charge *</label>

          <app-dictionary-select-items #bienChargeType="dictionarySelect" [ngItem]="ngBienCharge.charge"
                                       [name]="DICTIONARY_NAME_CHARGES" (selected)="onSelectCharge($event)"
                                       [options]="{name: 'charge' + ngBienCharge.uuid, required: true}"/>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienChargePeriodicite.formInput">
          <label>Périodicité *</label>

          <app-dictionary-select-items #bienChargePeriodicite="dictionarySelect" [ngItem]="ngBienCharge.periodicite"
                                       [name]="DICTIONARY_NAME_PERIODICITES" (selected)="onSelectPeriodicite($event)"
                                       [options]="{name: 'periodicite' + ngBienCharge.uuid, required: true}"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="cout">
          <label for="cout">Coût</label>

          <div class="input-group input-group-number input-group-currency">
            <input type="text" id="cout" name="cout" #cout="ngModel" [(ngModel)]="ngBienCharge.cout"
                   class="form-control" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$">

            <span class="input-group-addon">€</span>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <label for="charge-comments">Commentaires</label>

          <textarea id="charge-comments" class="form-control" name="comments" [minHeight]="100"
                    [(ngModel)]="ngBienCharge.comments"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-float-right">
    <button type="submit" class="tw-btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
