import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import BienLogement from '@models/bien/logements/logement/bien-logement.model';
import {NgBienLogement} from '@legacy/app/managers/ressources';
import {IBienLogementApi} from '@models/bien/logements/logement/bien-logement.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BienLogementsApiService} from '@models/bien/logements/bien-logements.api.service';

@Injectable({providedIn: 'root'})
export class BienLogementFactory {
    private _bienLogementsApiService = inject(BienLogementsApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienLogementApi: IBienLogementApi): BienLogement {
        const bienLogement: BienLogement = new BienLogement(bienLogementApi.uuid, bienLogementApi.id);

        bienLogement.comments = bienLogementApi.comments;
        bienLogement.otherLabel = bienLogementApi.otherLabel;
        bienLogement.surface = bienLogementApi.surface;
        if (bienLogementApi._embedded) {
            if (bienLogementApi._embedded.etage) {
                bienLogement.etage = this._dictionaryItemFactory.create(bienLogementApi._embedded.etage);
            }

            if (bienLogementApi._embedded.logement) {
                bienLogement.logement = this._dictionaryItemFactory.create(bienLogementApi._embedded.logement);
            }
        }

        return bienLogement;
    }

    delete$(dossierBien: ADossierBien, bienLogement: BienLogement): Observable<void> {
        return this._bienLogementsApiService.delete$(dossierBien.id.toString(), bienLogement.id.toString());
    }

    forApi(bienLogement: BienLogement): IBienLogementApi {
        const bienLogementApi = {
            comments: bienLogement.comments,
            otherLabel: bienLogement.otherLabel,
            surface: bienLogement.surface,
        } as IBienLogementApi;

        if (bienLogement.etage) {
            bienLogementApi.etageId = bienLogement.etage.id;
        }

        if (bienLogement.logement) {
            bienLogementApi.logementId = bienLogement.logement.id;
        }

        return bienLogementApi;
    }

    save$(dossierBien: ADossierBien, bienLogement: BienLogement): Observable<BienLogement> {
        return this._bienLogementsApiService.save$(dossierBien.id.toString(), bienLogement.id?.toString() ?? BienLogement.STATUT_NEW, this.forApi(bienLogement))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }

    ngCreate(ngBienLogement: NgBienLogement): BienLogement {
        const bienLogement: BienLogement = new BienLogement(ngBienLogement.uuid, ngBienLogement.id);

        bienLogement.comments = ngBienLogement.comments;
        bienLogement.surface = ngBienLogement.surface;
        if (ngBienLogement.etage) {
            bienLogement.etage = this._dictionaryItemFactory.ngCreate(ngBienLogement.etage);
        }

        if (ngBienLogement.logement) {
            if (ngBienLogement.logement.id) {
                bienLogement.logement = this._dictionaryItemFactory.ngCreate(ngBienLogement.logement);
            } else {
                bienLogement.otherLabel = ngBienLogement.logement.label;
            }
        }

        return bienLogement;
    }
}
