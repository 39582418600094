<div class="tw-mb-5">
  <h3><fa-icon [icon]="'edit'"/> {{ngBienLogement.uuid ? 'Édition' : 'Ajout'}} d'un logement</h3>
</div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
      class="tw-w-[80vw] sm:tw-w-[30rem] md:tw-w-[40rem] lg:tw-w-[50rem]">
  <div class="tw-py-6">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienLogementTypeLogement.formInput">
          <label>Type de logement *</label>

          <app-dictionary-select-items #bienLogementTypeLogement="dictionarySelect" [ngItem]="ngBienLogement.logement"
                                       [name]="DICTIONARY_NAME_LOGEMENTS" (selected)="onSelectLogement($event)"
                                       [options]="{acceptNoResult: true, name: 'logement' + ngBienLogement.uuid, required: true}"/>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienLogementEtage.formInput">
          <label>Étage</label>

          <app-dictionary-select-items #bienLogementEtage="dictionarySelect" [ngItem]="ngBienLogement.etage"
                                       [options]="{name: 'etage' + ngBienLogement.uuid}" (selected)="onSelectEtage($event)"
                                       [name]="DICTIONARY_NAME_ETAGES"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="surface">
          <label for="surface">Surface</label>

          <div class="input-group input-group-number">
            <input type="text" id="surface" name="surface" #surface="ngModel" [(ngModel)]="ngBienLogement.surface"
                   class="form-control" pattern="^(?:\d*.)?\d+$">

            <span class="input-group-addon">m<sup>2</sup></span>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <label for="logement-comments">Commentaires</label>

          <textarea id="logement-comments" class="form-control" name="comments" [minHeight]="100"
                    [(ngModel)]="ngBienLogement.comments"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-float-right">
    <button type="submit" class="tw-btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
