import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager} from '@legacy/app/managers/managers';
import {NgEmailDossiersCollection} from '@legacy/app/managers/collections';
import {NgEmailDossier} from '@legacy/app/managers/ressources';

export default function getManagersEmailDossier(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EmailDossierManager', manager);

        /**
         * Manager email dossier
         *
         * @param BaseManager
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('email-dossiers.cget', {path: '/emails/{emailId}/dossiers', method: 'GET'});
            baseManager.addRoute('email-dossiers.insert', {path: '/emails/{emailId}/dossiers', method: 'POST'});

            /**
             * EmailDossierManager object
             *
             * @constructor
             */
            angular.extend(EmailDossierManager.prototype, baseManager.__proto__);
            EmailDossierManager.prototype.eskManager = {prefixRoute: 'email-dossiers', collectionName: 'items'};

            function EmailDossierManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param emailId
             * @returns {Promise}
             */
            EmailDossierManager.prototype.getAll = function (emailId: number) {
                return baseManager.getAll.call(this, {emailId: emailId}).then(emailDossiers => {
                    const emailDossiersCollection: NgEmailDossiersCollection = emailDossiers as NgEmailDossiersCollection;

                    angular.forEach(emailDossiersCollection.collection, dossier => dossier._esk.defaultRouteParams!.emailId = emailId);

                    return emailDossiersCollection;
                });
            };

            /**
             * Create a EmailDossier object
             *
             * @param data
             * @param emailId
             * @returns manager.EmailDossier object
             */
            EmailDossierManager.prototype.create = function (data: unknown, emailId: number) {
                if (angular.isArray(data)) {
                    var dossiers = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        dossiers.push(new EmailDossier(emailId, data[i]));
                    }

                    return dossiers;
                }

                // @ts-ignore
                return new EmailDossier(emailId, data);
            };

            /**
             * EmailDossier object
             *
             * @param emailId
             * @param data
             * @constructor
             */
            angular.extend(EmailDossier.prototype, classResource.prototype);
            EmailDossier.prototype.eskManager = {prefixRoute: 'email-dossiers'};
            function EmailDossier(this: NgEmailDossier, emailId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, emailId: emailId};
            }

            /**
             * Sanitize object EmailDossier before send to API
             *
             * @returns sanitizeObject
             */
            EmailDossier.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.dossier;

                return sanitizeObject;
            };

            // @ts-ignore
            return new EmailDossierManager();
        }
    })(angularJS);
}
