import {AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgLocationManager} from '@legacy/app/managers/managers';
import {NgLocation} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {delay, firstValueFrom, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {LocationService} from '@models/locations/location/location.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Location from '@models/locations/location/location.model';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import {AppBienEditDescriptionComponent} from '@features/bien-edit/description/bien.edit-description.component';

@Component({selector: 'layout-location-edit', templateUrl: 'layout-location-edit.component.html'})
export class AppLayoutLocationEditComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    private _apiImpersonationService = inject(ApiImpersonationService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _dossierBienService = inject(DossierBienService);
    private _locationService = inject(LocationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _toasterService = inject(ToasterService);
    private _router = inject(Router);
    private _initLocation!: NgLocation;
    private _location!: NgLocation;
    private _hasConstraintOnPhotos = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _saveIsDone = false;
    private _saving = false;
    private _showSaveButton = true;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    get location(): NgLocation {
        return this._location;
    }

    get hasConstraintOnPhotos(): boolean {
        return this._hasConstraintOnPhotos;
    }

    get saving(): boolean {
        return this._saving;
    }

    get showSaveButton(): boolean {
        return this._showSaveButton;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        if (this.location?.statut !== Location.statuts.BROUILLON && !this.editForm.valid) {
            this.editForm.onSubmit(undefined!);
            this._formLocationNotValid();

            return of(false);
        }

        return of(true);
    }

    ngOnInit(): void {
        this.ngLocationManager.current$.pipe(
            tap(ngLocation => this._initLocation = {...ngLocation}),
            tap(_ => this._hasConstraintOnPhotos = this.ngBienDossierCurrent.hasConstraints('photos')),
            takeUntil(this._onDestroy$),
        ).subscribe(ngLocation => this._location = ngLocation);
        this._router.events.pipe(
            map(event => event as RouterEvent),
            filter(event => event instanceof NavigationStart),
            map((event: NavigationStart) => !event.url.endsWith('/edit/photos') && !event.url.endsWith('/edit/virtual_visit')),
            takeUntil(this._onDestroy$),
        ).subscribe(showSaveButton => this._showSaveButton = showSaveButton);
    }

    ngAfterViewInit(): void {
        this._locationService.needToBeSaved$.pipe(
            filter(_ => !!this.location),
            takeUntil(this._onDestroy$),
        ).subscribe(reason => this.save(true, reason));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.location?.id) {
            this.save();
        }
    }

    save(ignoreSubmitted = false, reason?: string): void {
        const isLocationNew = !this.location.id;

        if (this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isLocationNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if (this.location.statut !== Location.statuts.BROUILLON && (this.editForm.submitted || ignoreSubmitted) && !this.editForm.valid) {
            this._formLocationNotValid();
            return;
        }

        /*
                If (this.location.statut === Location.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formLocationNotValid();
                }
        */

        this._saveIsDone = true;
        this._saving = true;
        // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
        firstValueFrom(of(undefined).pipe(
            delay(600),
            switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.location.bien.contactsGroup))),
        )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
            .then(contactsGroup => this.location.bien.contactsGroup = contactsGroup)
            .then(_ => firstValueFrom(this._locationService.ngSave$(this.location).pipe(
                switchMap(location => {
                    if (reason === AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF) {
                        return this._dossierBienService.updateTitreDescriptif$(location);
                    }

                    return of(location);
                }),
            )))
            .then(location => this.ngLocationManager.getOneById(location.id))
            .then(ngLocation => {
                this.ngLocationManager.emitCurrent(ngLocation);
                if (isLocationNew) {
                    return this._router.navigateByUrl('/app/locations/' + this.location.id.toString(), {replaceUrl: true}) as unknown;
                } else {
                    return this.ngLocationManager.updateIfModified(this._initLocation, this.location).then(() => this._initLocation = this.location);
                }
            }, _ => this._toasterService.error('Oups !', 'Une erreur est survenue lors de la mise à jour de votre bien en location.'))
            .finally(() => this._saving = false);
    }

    private _formLocationNotValid(): void {
        this.ngSoqSweetAlert.warningMessage('Saisie des champs', 'Certains champs n\'ont pas de valeurs correctes.');

        return;
    }
}
