import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgDictionariesManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgVente} from '@legacy/app/managers/ressources';
import {delay, from, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import VenteCreditRentier from '@models/ventes/vente/credit-rentier/vente-credit-rentier.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Vente from '@models/ventes/vente/vente.model';

@Component({selector: 'layout-vente-edit-general', templateUrl: 'layout-vente-edit-general.component.html'})
export class AppLayoutVenteEditGeneralComponent implements OnDestroy, OnInit {
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _venteFactory = inject(VenteFactory);
    private _contactsGroupLinkSource = new ReplaySubject<string>(1);
    private _contactsGroupLink$ = this._contactsGroupLinkSource.asObservable();
    private _ngVente!: NgVente;
    private readonly _onDestroy$ = new Subject<void>();
    private _typeCharges!: DictionaryItem;

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    get contactsGroupLink$(): Observable<string> {
        return this._contactsGroupLink$;
    }

    get DICTIONARY_CODE_HONO_NEGO_INCLUS(): string {
        return Vente.chargesType.HONO_NEGO_INCLUS;
    }

    get DICTIONARY_CODE_NET_VENDEUR(): string {
        return Vente.chargesType.NET_VENDEUR;
    }

    get DICTIONARY_NAME_PERIODICITES(): string {
        return Dictionary.names.PERIODICITES;
    }

    get DICTIONARY_NAME_SEXES(): string {
        return Dictionary.names.SEXES;
    }

    get ngVente(): NgVente {
        return this._ngVente;
    }

    get typeCharges(): DictionaryItem {
        return this._typeCharges;
    }

    get VENTE_BROUILLON(): string {
        return Vente.statuts.BROUILLON;
    }

    get VENTE_INTERACTIVE_ENCHERES_SYSTEMES(): string {
        return Dictionary.names.VENTE_INTERACTIVE_ENCHERES_SYSTEMES;
    }

    get VENTE_PRIX_CHARGES_TYPES(): string {
        return Dictionary.names.VENTE_PRIX_CHARGES_TYPES;
    }

    get VENTE_TYPE_INTERACTIVE(): string {
        return Vente.types.INTERACTIVE;
    }

    get VENTE_TYPE_SIMPLE(): string {
        return Vente.types.SIMPLE;
    }

    get VENTE_TYPE_VIAGER(): string {
        return Vente.types.VIAGER;
    }

    ngOnInit(): void {
        this.ngVenteManager.current$.pipe(
            tap(_ => this._ngVente = undefined!),
            delay(1),
            tap(ngVente => {
                const vente = this._venteFactory.ngCreate(ngVente);

                this._contactsGroupLinkSource.next(vente.linkContactsGroup);
                this._typeCharges = vente.typeCharges;
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(ngVente => this._ngVente = ngVente);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    hasViagerBouquet(): boolean {
        const sanitizedNgVente = this.ngVente.sanitize() as NgVente;

        return this.ngVente.typeCharges.code === Vente.chargesType.NET_VENDEUR ? !!sanitizedNgVente?.viagerBouquet : !!this.ngVente._esk.bouquetHNI;
    }

    hasViagerRente(): boolean {
        const sanitizedNgVente = this.ngVente.sanitize() as NgVente;

        return !!sanitizedNgVente?.viagerRente;
    }

    onChangeContactsGroup(contactsGroup: ContactsGroup): void {
        let contactsGroupSaved$ = of(contactsGroup);

        if (!contactsGroup.id) {
            contactsGroupSaved$ = this._contactsGroupFactory.save$(contactsGroup).pipe(tap(contactsGroupSaved => {
                this._ngVente._links.contactsGroup = {href: contactsGroupSaved.linkSelf};
                this._contactsGroupLinkSource.next(contactsGroupSaved.linkSelf);
            }));
        }

        contactsGroupSaved$.pipe(
            switchMap(contactsGroupSaved => from(this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))),
            take(1),
        ).subscribe(ngContactsGroup => this._ngVente.bien.contactsGroup = ngContactsGroup);
    }

    onChangeInteractiveDateFin(interactiveDateFin: string): void {
        if (interactiveDateFin) {
            // @todo Utilisation de DateFormat ?
            this._ngVente.interactiveDateFin = new Date(interactiveDateFin.slice(0, -1));
        } else {
            this._ngVente.interactiveDateFin = undefined!;
        }
    }

    onSelectInteractiveSystemeEncheres(interactiveSystemeEncheres: DictionarySelected): void {
        if (interactiveSystemeEncheres && !Array.isArray(interactiveSystemeEncheres)) {
            this._ngVente.interactiveSystemeEncheres = this.ngDictionariesManager.createFromNg2(interactiveSystemeEncheres);
        } else {
            this._ngVente.interactiveSystemeEncheres = undefined!;
        }
    }

    onSelectTypeCharges(typeCharges: DictionaryItem): void {
        if (typeCharges && !Array.isArray(typeCharges)) {
            this._typeCharges = typeCharges;
            this._ngVente.typeCharges = this.ngDictionariesManager.createFromNg2(this._typeCharges);
        } else {
            this._ngVente.typeCharges = undefined!;
            this._typeCharges = undefined!;
        }
        this._ngVente.operatePricing(true, true);
    }

    onSelectViagerPeriodiciteRente(viagerPeriodiciteRente: DictionarySelected): void {
        if (viagerPeriodiciteRente && !Array.isArray(viagerPeriodiciteRente)) {
            this._ngVente.viagerPeriodiciteRente = this.ngDictionariesManager.createFromNg2(viagerPeriodiciteRente);
        } else {
            this._ngVente.viagerPeriodiciteRente = undefined!;
        }
    }

    onSelectViagerTeteSexe(viagerTete: VenteCreditRentier, viagerTeteSexe: DictionarySelected): void {
        viagerTete.sexe = viagerTeteSexe as DictionaryItem;
    }
}
