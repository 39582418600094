import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {IDictionaryItemApi} from '@models/dictionaries/dictionary/items/item/dictionary-item.interfaces';

export const getMockDictionaryItem = (data = {}): DictionaryItem => {
    const dictionaryItemData = data as DictionaryItem;
    const suffix = 'dictionary-item';
    const dictionaryItem = new DictionaryItem('uuid-' + suffix, 2);

    dictionaryItem.abreviation = 'abreviation-' + suffix;
    dictionaryItem.anneeMax = 2;
    dictionaryItem.anneeMin = 9;
    dictionaryItem.code = dictionaryItemData.code ?? 'code-' + suffix;
    dictionaryItem.codePasserelle = 'codePasserelle-' + suffix;
    dictionaryItem.codes = ['code-1-' + suffix, 'code-2-' + suffix];
    dictionaryItem.defaultComments = 'defaultComments-' + suffix;
    dictionaryItem.deprecated = dictionaryItemData.deprecated ?? false;
    dictionaryItem.duree = 'duree-' + suffix;
    dictionaryItem.label = 'label-' + suffix;
    dictionaryItem.shortLabel = 'shortLabel-' + suffix;
    dictionaryItem.tags = ['tag-1-' + suffix, 'tag-2-' + suffix];

    return dictionaryItem;
};

export const getMockDictionaryItemApi = (): IDictionaryItemApi => {
    const suffix = 'dictionary-item-api';

    return {
        abreviation: 'abreviation-' + suffix,
        anneeMax: 2,
        anneeMin: 9,
        code: 'code-' + suffix,
        codePasserelle: 'codePasserelle-' + suffix,
        codes: ['code-1-' + suffix, 'code-2-' + suffix],
        defaultComments: 'defaultComments-' + suffix,
        deprecated: false,
        duree: 'duree-' + suffix,
        id: 2,
        label: 'label-' + suffix,
        shortLabel: 'shortLabel-' + suffix,
        tags: ['tag-1-' + suffix, 'tag-2-' + suffix],
        uuid: 'uuid-' + suffix,
    };
};

export class MockDictionaryItemFactory {
    create(): void {
    }

    createForOtherLabel(): void {
    }

    createVirgin(): void {
    }

    forApi(): void {
    }

    ngCreate(): void {
    }
}

export class MockDictionaryItemService {
    getByCode(): void {
    }

    getById(): void {
    }

    getEpoqueFromAnnee(): void {
    }

    getNatureNormalizer(): void {
    }

    getOneSuperficieLabel(): void {
    }

    getOneSuperficieLabelById(): void {
    }
}
