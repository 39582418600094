import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';

@Injectable({providedIn: 'root'})
export class EmailRecipientsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(emailId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/emails/${emailId}/recipients/${id}`);
    }
}
