import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgBienLogement} from '@legacy/app/managers/ressources';

export default function getManagersBienLogement(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienLogementManager', manager);

        /**
         * Manager logement
         *
         * @param UtilsManager
         * @param BaseManager
         * @param $q
         * @returns {BienLogementManager}
         */
        manager.$inject = ['UtilsManager', 'BaseManager', '$q'];
        function manager(utilsManager: NgUtilsManager, baseManager: NgManager, $q: IQService) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('logements.cget', {path: '/biens/{bienId}/logements', method: 'GET'});
            baseManager.addRoute('logements.insert', {path: '/biens/{bienId}/logements', method: 'POST'});
            baseManager.addRoute('logements.edit', {path: '/biens/{bienId}/logements/{id}', method: 'PUT'});
            baseManager.addRoute('logements.update-rank', {
                path: '/biens/{bienId}/logements/{id}/rank',
                method: 'PATCH'
            });

            /**
             * BienLogementManager object
             *
             * @constructor
             */
            angular.extend(BienLogementManager.prototype, baseManager.__proto__);
            BienLogementManager.prototype.eskManager = {prefixRoute: 'logements', collectionName: 'items'};
            function BienLogementManager() {
            }

            /**
             * Create a BienLogement object
             *
             * @param data
             * @param bienId
             * @returns BienLogement object || Array of BienLogement objects
             */
            BienLogementManager.prototype.create = function (data: unknown, bienId: number) {
                if (angular.isArray(data)) {
                    const logements = [];

                    for (let i = 0; i < data.length; i++) {
                        // @ts-ignore
                        logements.push(new BienLogement(bienId, data[i]));
                    }

                    return logements;
                }

                // @ts-ignore
                return new BienLogement(bienId, data);
            };

            /**
             * BienLogement object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(BienLogement.prototype, classResource.prototype);
            BienLogement.prototype.eskManager = {prefixRoute: 'logements'};
            function BienLogement(this: NgBienLogement, bienId: number, data: unknown) {
                this.extend(data);
                this.uuid = this.id?.toString();
                this._esk.defaultRouteParams = {id: this.id, bienId: bienId};
            }

            /**
             * Extend the existing BienLogement with new data
             *
             * @param data
             * @returns {BienLogement}
             */
            BienLogement.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if ((!angular.isObject(this.logement) || !angular.isNumber(this.logement.id)) && angular.isString(this.otherLabel)) {
                    this.setLogementWithLabel(this.otherLabel);
                    delete this.otherLabel;
                }

                this.surface = this.surface?.toString().replace('.', ',');

                return this;
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            BienLogement.prototype.save = function () {
                const self = this;

                self._esk.savedAskSource.next();
                if (!self.isValid()) {
                    return $q.reject('NOT_VALID');
                }

                return classResource.prototype.save.call(self);
            };

            /**
             * Sanitize object BienLogement before send to API
             *
             * @returns {Object}
             */
            BienLogement.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet BienLogement pour l'envoi à l'API
                if (angular.isObject(sanitizeObject.logement)) {
                    if (angular.isNumber(sanitizeObject.logement.id)) {
                        utilsManager.flattenIdForAttribute(sanitizeObject, 'logement');
                    } else if (angular.isString(sanitizeObject.logement.label)) {
                        sanitizeObject.otherLabel = sanitizeObject.logement.label;
                        delete sanitizeObject.logement;
                    }
                }

                utilsManager.flattenIdForAttribute(sanitizeObject, 'etage');
                if (!!this.surface) {
                    sanitizeObject.surface = +(this.surface.toString().replace(',', '.'));
                } else {
                    sanitizeObject.surface = null!;
                }

                return sanitizeObject;
            };

            /**
             * Set logement with label
             *
             * @param label
             */
            BienLogement.prototype.setLogementWithLabel = function (label: string) {
                this.logement = {label: label};
            };

            /**
             * Test validity and fill _esk.errors
             *
             * @returns {boolean}
             */
            BienLogement.prototype.isValid = function () {
                const sanitizeObject = this.sanitize();

                this._esk.errors = {
                    logement: !sanitizeObject.logementId && !sanitizeObject.otherLabel,
                    surface: !!this.surface && (isNaN(sanitizeObject.surface) || !/^(?:\d*\.)?\d+$/.test(sanitizeObject.surface)),
                };

                return !this._esk.errors.logement && !this._esk.errors.surface;
            };

            /**
             * Test if BienLogement is empty
             *
             * @returns {boolean}
             */
            BienLogement.prototype.isEmpty = function () {
                return !angular.isObject(this.logement)
                    && !angular.isObject(this.etage)
                    && (!angular.isNumber(this.surface) || this.surface <= 0)
                    && (!angular.isString(this.comments) || this.comments === '');
            };

            // @ts-ignore
            return new BienLogementManager();
        }
    })(angularJS);
}
