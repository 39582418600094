import {inject, Injectable} from '@angular/core';
import EmailAttachment from '@models/emails/email/attachments/attachment/email-attachment.model';
import {IEmailAttachmentApi} from '@models/emails/email/attachments/attachment/email-attachment.interfaces';
import {EmailAttachmentsApiService} from '@models/emails/email/attachments/email-attachments.api.service';
import {Observable} from 'rxjs';
import Email from '@models/emails/email/email.model';
import {NgEmailAttachment} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class EmailAttachmentFactory {
    private _emailAttachmentsApiService = inject(EmailAttachmentsApiService);

    create(emailAttachmentApi: IEmailAttachmentApi): EmailAttachment {
        return new EmailAttachment(emailAttachmentApi.uuid, emailAttachmentApi.id);
    }

    delete$(email: Email, emailAttachment: EmailAttachment): Observable<void> {
        return this._emailAttachmentsApiService.delete$(email.id.toString(), emailAttachment.id.toString());
    }

    ngCreate(ngEmailAttachment: NgEmailAttachment): EmailAttachment {
        return new EmailAttachment(ngEmailAttachment.uuid, ngEmailAttachment.id);
    }
}
