import {Component, Input, Pipe, PipeTransform} from '@angular/core';
import {
    IPhoneNumber, IQueryParameterCondition, IQueryParameterRange, IQueryParameters, IQueryParametersTri, ITextPhoneOptions
} from '@shared/texts/texts.interfaces';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ConditionConst, SortConst} from '@shared/constants';

// @todo Ajouter un suffix
export const getMockPhoneNumber = (): IPhoneNumber => ({
    country: 'FR',
    format: (format: string): string => format,
    formatNational: (): string => 'formatNational',
    isValid: (): boolean => true,
});

export const getMockQueryParameterRangeNumber = (): IQueryParameterRange => ({max: 7, min: 4});

export const getMockQueryParameterRangeString = (suffix = 'query-parameter-range-string'): IQueryParameterRange<string> => ({
    max: 'max-' + suffix,
    min: 'min-' + suffix,
});

export const getMockQueryParameterCondition = <T = string>(values = ['value-1-query-parameter-string', 'value-2-query-parameter-string'] as T[]): IQueryParameterCondition<T> => ({
    condition: ConditionConst.IN,
    values,
});

export const getMockQueryParameterConditionNumber = (): IQueryParameterCondition<number> =>
    getMockQueryParameterCondition([3, 4]);

export const getMockQueryParameterConditionString = (suffix = 'query-parameter-string'): IQueryParameterCondition =>
    getMockQueryParameterCondition(['value-1-' + suffix, 'value-2-' + suffix]);

export const getMockQueryParameters = (suffix = 'query-parameters'): IQueryParameters => ({
    keywords: 'keywords-' + suffix,
    limit: 20,
    tris: getMockQueryParametersTri(suffix),
});

export const getMockQueryParametersTri = (suffix = 'query-parameters-tri'): IQueryParametersTri => ({
    ['key1-' + suffix]: SortConst.ASCENDING,
    ['key2-' + suffix]: SortConst.DESCENDING,
});

// @todo Ajouter un suffix
export const getMockTextPhoneOptions = (): ITextPhoneOptions => {
    const suffix = 'text-phone-options';

    return {
        icon: 'icon-' + suffix as IconProp,
        iconAddedClass: 'iconAddedClass-' + suffix,
        label: 'label-' + suffix,
        linkAddedClass: 'linkAddedClass-' + suffix,
    };
};

export class MockPhoneNumberService {
    getE164(): void {
    }

    getRfc3966(): void {
    }

    getUsable(): void {
    }

    isValid(): void {
    }

    parse(): void {
    }
}

export class MockTextsService {
    uuid(): void {
    }
}

export class MockUrlService {
    addQueryparamToUrl(): void {
    }

    getHostname(): void {
    }

    getQueryparamValue(): void {
    }

    isExcluded(): void {
    }

    pathWithQueryParameters(): void {
    }

    stringifyInQueryparams(): void {
    }
}

@Pipe({name: 'textCurrency', standalone: true})
export class MockTextCurrencyPipe implements PipeTransform {
    transform(): void {
    }
}

@Pipe({name: 'textNl2br', standalone: true})
export class MockTextNl2brPipe implements PipeTransform {
    transform(): void {
    }
}

@Component({selector: 'app-text-phone', standalone: true, template: ''})
export class MockTextPhoneComponent {
    @Input() options!: ITextPhoneOptions;
    @Input() phone!: string;
}

@Component({selector: 'app-text-phones', standalone: true, template: ''})
export class MockTextPhonesComponent {
    @Input() phones!: string[];
}

@Pipe({name: 'textSize', standalone: true})
export class MockTextSizePipe implements PipeTransform {
    transform(): void {
    }
}
