import {IContactsGroupMemberApi} from '@models/contacts-group/members/member/contacts-group-member.interfaces';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {getMockContact, getMockContactApi} from '@models/contacts/contacts.fixtures';
import CContactsGroupMembers from '@models/contacts-group/members/collection/contacts-group-members.collection.model';


export const getMockCContactsGroupMembers = (): CContactsGroupMembers => {
    const cContactsGroupMembers = new CContactsGroupMembers();

    cContactsGroupMembers.page = 4;
    cContactsGroupMembers.pages = 8;
    cContactsGroupMembers.perPage = 2;
    cContactsGroupMembers.results = [getMockContactsGroupMember(), getMockContactsGroupMember()];
    cContactsGroupMembers.total = 5;

    return cContactsGroupMembers;
};


export const getMockContactsGroupMember = (): ContactsGroupMember => {
    const contactsGroupMember = new ContactsGroupMember();

    contactsGroupMember.contact = getMockContact();
    contactsGroupMember.sortableRank = 4;

    return contactsGroupMember;
};

export const getMockContactsGroupMemberApi = (): IContactsGroupMemberApi => ({
    _embedded: {contact: getMockContactApi()},
    contactId: 6,
    sortableRank: 4,
});

export class MockCContactsGroupMembersFactory {
    createFromMembers(): void {
    }

    createVirgin(): void {
    }
}

export class MockCContactsGroupMembersService{
    getFromContactsGroupLink$(): void {
    }
}


export class MockContactsGroupMemberFactory {
    create(): void {
    }

    createFromContact(): void {
    }

    createVirgin(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    save$(): void {
    }

    updateRank$(): void {
    }

    ngCreate(): void {
    }
}

export class MockContactsGroupMemberService {
    addFromContact$(): void {
    }

    delete$(): void {
    }

    deleteFromContact$(): void {
    }

    save$(): void {
    }
}

export class MockContactsGroupMembersService {
    create(): void {
    }

    createFromContact(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockContactsGroupMembersApiService {
    delete$(): void {
    }

    save$(): void {
    }

    updateRank$(): void {
    }
}
