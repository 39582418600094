import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {IContactsGroupApi} from '@models/contacts-group/contacts-group.interfaces';
import {
    getMockContactsGroupMember, getMockContactsGroupMemberApi
} from '@models/contacts-group/members/contacts-group-members.fixtures';
import {Observable, of} from 'rxjs';
import {NgContactsGroup} from '@legacy/app/managers/ressources';
import {getMockNgContactsGroup} from '@legacy/app/managers/managers.fixtures';
import {getMockLinksApi} from '@models/links/links.fixtures';
import {
    getMockDictionaryItem, getMockDictionaryItemApi
} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';

export const getMockContactsGroup = (data= {}): ContactsGroup => {
    const contactsGroupData = data as ContactsGroup;
    const suffix = 'contactsGroup';
    const contactsGroup = new ContactsGroup(contactsGroupData.uuid ?? 'uuid-' + suffix, contactsGroupData.id ?? 1);

    contactsGroup.formuleAdressePostale = 'formuleAdressePostale-' + suffix;
    contactsGroup.formuleIntroduction = 'formuleIntroduction-' + suffix;
    contactsGroup.formulePolitesse = 'formulePolitesse-' + suffix;
    contactsGroup.linkSelf = 'linkSelf-' + suffix;
    contactsGroup.nom = 'nom-' + suffix;
    contactsGroup.members = [getMockContactsGroupMember(), getMockContactsGroupMember()];
    contactsGroup.typeGroup = getMockDictionaryItem();

    return contactsGroup;
};

export const getMockContactsGroupApi = (): IContactsGroupApi => {
    const suffix = 'contacts-group-api';

    return {
        _embedded: {
            members: [getMockContactsGroupMemberApi(), getMockContactsGroupMemberApi()],
            typeGroup: getMockDictionaryItemApi(),
        },
        _links: getMockLinksApi('self'),
        formuleAdressePostale: 'formuleAdressePostale-' + suffix,
        formuleIntroduction: 'formuleIntroduction-' + suffix,
        formulePolitesse: 'formulePolitesse-' + suffix,
        id: 1,
        nom: 'nom-' + suffix,
        typeGroupId: 3,
        uuid: 'uuid-' + suffix,
    };
};

export class MockContactsGroupApiService {
    getByLink$(): void {
    }

    save$(): void {
    }
}

export class MockContactsGroupFactory {
    create(): void {
    }

    createFromContact(): void {
    }

    createFromDossierBienDiffusionEtat(): void {
    }

    createVirgin(): void {
    }

    forApi(): void {
    }

    getByLink$(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockContactsGroupService {
    get ngCreated$(): Observable<NgContactsGroup> {
        return of(getMockNgContactsGroup());
    }

    getOneByLink$(): void {
    }

    save$(): void {
    }

    ngOneCreated(): void {
    }
}
