import {IDepartementApi} from '@models/departements/departement/departement.interfaces';
import Departement from '@models/departements/departement/departement.model';

export const getMockDepartement = (): Departement => {
    const suffix = 'departement';
    const departement = new Departement('uuid-' + suffix, 6);

    departement.code = 'code-' + suffix;
    departement.fullname = 'fullname-' + suffix;

    return departement;
};

export const getMockDepartementApi = (): IDepartementApi => {
    const suffix = 'departement-api';

    return {code: 'code-' + suffix, fullname: 'fullname-' + suffix, id: 6, uuid: 'uuid-' + suffix};
};

export class MockDepartementFactory {
    create(): void {
    }

    ngCreate(): void {
    }
}
