import {Component, ElementRef, HostListener, Inject, inject, Input, OnInit, ViewChild} from '@angular/core';
import {INavigationLateralSection} from '@shared/navigation/navigation.interfaces';
import {NavigationService} from '@shared/navigation/navigation.service';
import {Observable} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {HTMLElementTagNameConst} from '@shared/constants';
import ScreenSizeService from '@shared/screen-size/screen-size.service';
import {AsyncPipe, NgClass} from '@angular/common';
import {ClickAwayModule} from '@shared/click-away/click-away.module';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {AppLoadObsPipe} from '@shared/load/obs/load-obs.pipe';
import {AppNoteoLogoSepteoComponent} from '@shared/noteo/logo/septeo/noteo-logo.septeo.component';

@Component({
    animations: [
        trigger('openClose', [
            state('true', style({transform: 'translateX(0)'})),
            state('false', style({transform: 'translateX(-100%)'})),
            transition('false <=> true', animate('500ms ease-in-out')),
        ]),
    ],
    imports: [
        AppLoadObsPipe,
        AppNoteoLogoSepteoComponent,
        AsyncPipe,
        ClickAwayModule,
        FaIconComponent,
        NgClass,
        RouterLink,
        RouterLinkActive,
    ],
    selector: 'app-navigation-lateral',
    standalone: true,
    templateUrl: 'navigation-lateral.component.html',
})
export class AppNavigationLateralComponent implements OnInit {
    @ViewChild('navigationLateral') navigationLateralElementRef!: ElementRef<HTMLDivElement>;
    static readonly menuCodes = {
        DOCUMENTS: 'DOCUMENTS',
        DOSSIERS: 'DOSSIERS',
        ETUDE: 'ETUDE',
        GED: 'GED',
        NETWORK: 'NETWORK',
        NOTESTIM: 'NOTESTIM',
        OVERVIEW: 'OVERVIEW',
        PASSERELLES: 'PASSERELLES',
    };
    private _navigationService = inject(NavigationService);
    private _screenSizeService = inject(ScreenSizeService);
    private _window: Window;
    private _sections!: INavigationLateralSection[];
    private _skipAnimations = true;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    get menuCodes(): Record<string, string> {
        return AppNavigationLateralComponent.menuCodes;
    }

    get mustBeOpen$(): Observable<boolean> {
        return this._navigationService.mustBeOpen$;
    }

    get sections(): INavigationLateralSection[] {
        return this._sections;
    }

    @Input()
    set sections(value: INavigationLateralSection[]) {
        this._sections = value;
    }

    get skipAnimations(): boolean {
        return this._skipAnimations;
    }

    ngOnInit(): void {
        this.setSkipAnimations();
    }

    clickMenu(event: Event): void {
        if (this.findAnchorParent(event.target as HTMLElement)) {
            this.close();
        }
    }

    close(): void {
        const cssStyleDeclaration = this._window.getComputedStyle(this.navigationLateralElementRef.nativeElement);

        if (cssStyleDeclaration.display === 'block') {
            this._navigationService.close();
        }
    }

    findAnchorParent(element: HTMLElement): HTMLAnchorElement {
        if (element.tagName === HTMLElementTagNameConst.ANCHOR) {
            return element as HTMLAnchorElement;
        } else if (this.navigationLateralElementRef.nativeElement.contains(element.parentElement)) {
            return this.findAnchorParent(element.parentElement!);
        }

        return undefined!;
    }

    @HostListener('window:resize')
    setSkipAnimations(): void {
        this._skipAnimations = this._screenSizeService.test(ScreenSizeService.widths.MD);
    }
}
