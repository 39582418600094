import {inject, Injectable} from '@angular/core';
import EmailDossier from '@models/emails/email/dossiers/dossier/email-dossier.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {LocationFactory} from '@models/locations/location/location.factory';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {IEmailDossierApi} from '@models/emails/email/dossiers/dossier/email-dossier.interfaces';
import {Observable} from 'rxjs';
import Email from '@models/emails/email/email.model';
import {EmailDossiersApiService} from '@models/emails/email/dossiers/email-dossiers.api.service';
import {NgEmailDossier} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class EmailDossierFactory {
    private _demandeurFactory = inject(DemandeurFactory);
    private _emailDossiersApiService = inject(EmailDossiersApiService);
    private _estimationFactory = inject(EstimationFactory);
    private _locationFactory = inject(LocationFactory);
    private _venteFactory = inject(VenteFactory);

    create(emailDossierApi: IEmailDossierApi): EmailDossier {
        const emailDossier = new EmailDossier(emailDossierApi.uuid, emailDossierApi.id);

        emailDossier.type = emailDossierApi.type;
        if (emailDossierApi._embedded) {
            if (emailDossierApi._embedded.demandeur) {
                emailDossier.demandeur = this._demandeurFactory.create(emailDossierApi._embedded.demandeur);
            }

            if (emailDossierApi._embedded.estimation) {
                emailDossier.estimation = this._estimationFactory.create(emailDossierApi._embedded.estimation);
            }

            if (emailDossierApi._embedded.location) {
                emailDossier.location = this._locationFactory.create(emailDossierApi._embedded.location);
            }

            if (emailDossierApi._embedded.vente) {
                emailDossier.vente = this._venteFactory.create(emailDossierApi._embedded.vente);
            }
        }

        return emailDossier;
    }

    delete$(email: Email, emailDossier: EmailDossier): Observable<void> {
        return this._emailDossiersApiService.delete$(email.id.toString(), emailDossier.id.toString());
    }

    ngCreate(ngEmailDossier: NgEmailDossier): EmailDossier {
        return new EmailDossier(ngEmailDossier.uuid, ngEmailDossier.id);
    }
}
