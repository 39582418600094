import {inject, Injectable} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgVenteManager} from '@legacy/app/managers/managers';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Vente from '@models/ventes/vente/vente.model';
import {Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {filter, map, startWith, take, tap} from 'rxjs/operators';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {BienService} from '@models/bien/bien.service';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCNotestimBiensSelectComponent
} from '@features/notestim/biens/select/dynamic-components/notestim-biens-select.component';
import {
    DCNotestimBienImportComponent
} from '@features/notestim/biens/bien/import/dynamic-components/notestim-bien-import.component';
import NotestimBien from '@models/notestim/biens/bien/notestim-bien.model';

@Injectable({providedIn: 'root'})
export class VenteService {
    private _bienService = inject(BienService);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _etudeFactory = inject(EtudeFactory);
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _venteFactory = inject(VenteFactory);
    private _currentSource = new ReplaySubject<Vente>(1);
    private _current$ = this._currentSource.asObservable().pipe(startWith(undefined as unknown as Vente));
    private _needToBeSavedSource = new Subject<string>();
    private _needToBeSaved$ = this._needToBeSavedSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    get current$(): Observable<Vente> {
        return this._current$;
    }

    get needToBeSaved$(): Observable<string> {
        return this._needToBeSaved$;
    }

    askToBeSaved(reason?: string): void {
        this._needToBeSavedSource.next(reason!);
    }

    cloneCurrent$(keepProprietaires: boolean): Observable<Vente> {
        return this.current$.pipe(filter(vente => !!vente), switchMap(vente => this._venteFactory.clone$(vente, keepProprietaires)));
    }

    createVirgin$(): Observable<Vente> {
        const vente = this._venteFactory.createVirgin();

        return this._bienService.createVirgin$().pipe(tap(bien => vente.bien = bien), map(_ => vente));
    }

    // @todo Rechercher "venteFactory.ngCreate(" et si c'est pour le courant, utiliser cette méthode
    getCurrentFromNg(): Vente {
        if (!this.ngVenteManager.current) {
            return undefined!;
        }

        return this._venteFactory.ngCreate(this.ngVenteManager.current);
    }

    getEtude$(vente: Vente): Observable<Etude> {
        return this._etudeFactory.getByLink$(vente.linkEtude);
    }

    importFromNotestim$(): Observable<Vente> {
        return this._modalService.open$<NotestimBien>(DCNotestimBiensSelectComponent, {}, {
            align: 'top',
            withPadding: false
        }).pipe(
            take(1),
            switchMap(notestimBien => {
                if (!notestimBien) {
                    return of(undefined as unknown as Vente);
                }

                return this._modalService.open$<Vente>(DCNotestimBienImportComponent, {notestimBien}, {allowClose: false});
            })
        );
    }

    initCurrent(idOrUuid: number | string): void {
        this._currentSource.next(undefined!);
        (idOrUuid ? this._venteFactory.get$(idOrUuid.toString()) : this.createVirgin$()).pipe(
            tap(vente => this._cDossierFilesService.initCurrentWithMedia(vente)),
            take(1),
        ).subscribe(vente => this._currentSource.next(vente));
    }

    isCurrentPrintAffichettes$(): Observable<boolean> {
        return this.current$.pipe(
            filter(vente => !!vente),
            switchMap(vente => this._etudeService.last$.pipe(
                switchMap(etude => {
                    if (vente.interne) {
                        return of({etude, etudeConfrere: etude});
                    }

                    return this.getEtude$(vente).pipe(map(etudeConfrere => ({etude, etudeConfrere})));
                }),
            )),
            map(({etude, etudeConfrere}) =>
                etude.imprimerAffichetteConfrere && etudeConfrere.imprimerAffichetteConfrere
            ),
        );
    }
}
