import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgResource} from '@legacy/app/managers/ressources';
import {NgClientPaginate, NgClientService} from '@legacy/app/client/client';
import {NgUtilsManager} from '@legacy/app/managers/managers';
import {cloneDeep} from 'lodash';

export default function getManagersBase(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BaseManager', manager);

        /**
         * Manager parent
         *
         * @param UtilsManager
         * @param ClientService
         * @param ClientPaginate
         * @param $q
         * @returns {BaseManager}
         */
        manager.$inject = ['UtilsManager', 'ClientService', 'ClientPaginate', '$q'];
        function manager(utilsManager: NgUtilsManager,
                         clientService: NgClientService,
                         clientPaginate: NgClientPaginate,
                         $q: IQService) {
            /**
             * BaseManager object
             *
             * @constructor
             */
            BaseManager.prototype.eskManager = {};
            function BaseManager() {
            }

            /**
             * Set all routes for this manager
             * Do NOT override this method
             *
             * @param name
             * @param options
             * @param useCache
             */
            BaseManager.prototype.addRoute = function (name: string, options: unknown, useCache?: boolean) {
                clientService.addRoute(name, options, useCache);
            };

            /**
             * Returns the prefix route of the current Resource
             * Do NOT override this method
             *
             * @returns eskManager.prefixRoute
             */
            BaseManager.prototype.getPrefixRoute = function () {
                if (!angular.isString(this.eskManager.prefixRoute) || this.eskManager.prefixRoute === '') {
                    throw new Error('Aucun préfixe de route spécifié pour la ressource.');
                }

                return this.eskManager.prefixRoute;
            };

            /**
             * Returns the collection name of the current Resource
             * Do NOT override this method
             *
             * @returns eskManager.collectionName
             */
            BaseManager.prototype.getCollectionName = function () {
                if (!angular.isString(this.eskManager.collectionName) || this.eskManager.collectionName === '') {
                    throw new Error('Aucun nom de collection spécifié pour la ressource.');
                }

                return this.eskManager.collectionName;
            };

            /**
             * Returns object Resource
             * Do NOT override this method
             *
             * @returns Resource
             */
            BaseManager.prototype.getClass = function () {
                return Resource;
            };

            /**
             * Return objects collection or object
             *
             * @param data
             * @returns {*}
             */
            BaseManager.prototype.createObject = function (data: any) {
                if (angular.isObject(data) && angular.isArray(data[this.getCollectionName()])) {
                    return clientPaginate.create(this, data);
                }

                return this.create(data);
            };

            /**
             * Returns the promise of GET request
             *
             * @param suffixRouteName
             * @param params
             * @param headers
             * @returns {Promise}
             */
            BaseManager.prototype.get = function (suffixRouteName: string, params: any, headers: string): IPromise<NgResource> {
                const self = this;

                params = angular.isObject(params) ? params : {};

                return clientService.execRoute(self.getPrefixRoute() + '.' + suffixRouteName, params, undefined, headers).then(data => self.createObject(data));
            };

            /**
             * Returns the promise to fetch object
             *
             * @param resourceId
             * @returns {Promise}
             */
            BaseManager.prototype.getOneById = function (resourceId: number) {
                return this.get('get', {id: resourceId});
            };

            /**
             * Returns the promise to fetch object
             *
             * @param resourceUuid
             * @returns {Promise}
             */
            BaseManager.prototype.getOneByUuid = function (resourceUuid: string) {
                return this.get('get-uuid', {uuid: resourceUuid});
            };

            /**
             * Returns the promise to fetch collection of objects
             *
             * @param params
             * @returns {Promise}
             */
            BaseManager.prototype.getAll = function (params: unknown) {
                return this.get('cget', params);
            };

            /**
             * Returns the promise of PATCH request
             *
             * @param suffixRoute
             * @param data
             * @param params
             * @returns {promise}
             */
            BaseManager.prototype.patch = function (suffixRoute: string, data: unknown, params: unknown) {
                return clientService.execRoute(this.getPrefixRoute() + '.' + suffixRoute, params, data);
            };

            /**
             * Returns the promise of GET link
             *
             * @param link
             * @returns {Promise}
             */
            BaseManager.prototype.execLink = function (link: string) {
                const self = this;

                return clientService.execLink(link).then(data => self.createObject(data));
            };

            /**
             * Returns object Resource
             * MUST BE OVERRIDDEN
             *
             * @param data
             * @returns {manager.Resource}
             */
            BaseManager.prototype.create = function (data: unknown) {
                // @ts-ignore
                return new Resource(data);
            };

            /**
             * Resource object
             *
             * @param data
             * @constructor
             */
            Resource.prototype.eskManager = {};
            function Resource(this: NgResource, data: unknown) {
                this.extend(data);
            }

            /**
             * Copy the existing resource with same data
             *
             * @returns {Resource}
             */
            Resource.prototype.copy = function () {
                return angular.copy(this);
            };

            /**
             * Extend the existing resource with new data
             *
             * @param data
             * @returns {Resource}
             */
            Resource.prototype.extend = function (data: unknown) {
                return utilsManager.extendDataToResource(this, data);
            };

            /**
             * Returns the prefix route of the current Resource
             * Do NOT override this method
             *
             * @returns eskManager.prefixRoute
             */
            Resource.prototype.getPrefixRoute = function () {
                if (!angular.isString(this.eskManager.prefixRoute) || this.eskManager.prefixRoute === '') {
                    throw new Error('Aucun préfixe de route spécifié pour la ressource.');
                }

                return this.eskManager.prefixRoute;
            };

            /**
             * Sanitize this Resource before send to API
             *
             * @returns {object}
             */
            Resource.prototype.sanitize = function () {
                let sanitizeObject: NgResource & {etag: string};

                // Récupération seulement des données de la ressource
                sanitizeObject = cloneDeep(this);

                // Suppression des données rajoutées par les managers
                // @ts-ignore
                delete sanitizeObject._esk;

                // Suppression des données inutiles récupérées de l'API
                // @ts-ignore
                delete sanitizeObject.etag;
                utilsManager.removeAttributeRecursively(sanitizeObject, '_links');

                return sanitizeObject;
            };

            /**
             * Returns the promise of GET request
             *
             * @param suffixRouteName
             * @param params
             * @returns {Promise}
             */
            Resource.prototype.get = function (suffixRouteName: string, params: unknown) {
                return clientService.execRoute(this.getPrefixRoute() + '.' + suffixRouteName, angular.extend({}, this._esk.defaultRouteParams, params));
            };

            /**
             * Returns the promise of POST or PUT request
             *
             * @returns {Promise}
             */
            Resource.prototype.save = function () {
                const self = this;
                var promise;

                if (self.id) {
                    promise = clientService.execRoute(self.getPrefixRoute() + '.edit', self._esk.defaultRouteParams, self.sanitize());
                } else {
                    promise = clientService.execRoute(self.getPrefixRoute() + '.insert', self._esk.defaultRouteParams, self.sanitize()).then(function (data) {
                        return self.extend(data);
                    });
                }

                return promise.then(function () {
                    self._esk.persist = true;
                    self._esk.flush = true;
                    if (angular.isDefined(self.id)) {
                        self._esk.defaultRouteParams.id = self.id;
                        if (!self.uuid) {
                            self.uuid = self.id?.toString();
                        }
                    }

                    return self;
                });
            };

            /**
             * Returns the promise of PATCH request
             *
             * @param suffixRoute
             * @param data
             * @param params
             * @returns {promise}
             */
            Resource.prototype.patch = function (suffixRoute: string, data: unknown, params: unknown) {
                if (!angular.isObject(params)) {
                    params = {};
                }

                return clientService.execRoute(this.getPrefixRoute() + '.' + suffixRoute, angular.extend({}, this._esk.defaultRouteParams, params), data);
            };

            /**
             * Returns the promise to update rank of Resource
             *
             * @param rank
             * @returns {promise}
             */
            Resource.prototype.updateRank = function (rank: number) {
                const self = this;

                if (!self._esk.persist) {
                    return $q.resolve();
                }

                return self.patch('update-rank', {rank: rank}).then(function () {
                    self.sortableRank = rank;
                });
            };

            // @ts-ignore
            return new BaseManager();
        }
    })(angularJS);
}
