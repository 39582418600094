import DateFormat from '@shared/date/date.format';
import {IDateFormatDiffOptions, IDateFormatIsNowOlderOptions} from '@shared/date/date.interfaces';

export const getMockDateFormatDiffOptions = (suffix = 'date-format-diff-options'): IDateFormatDiffOptions => ({
    returnUnit: 'returnUnit-' + suffix,
    withoutTime: true,
});

export const getMockDateFormatIsNowOlderOptions = (suffix = 'date-format-is-now-older-options'): IDateFormatIsNowOlderOptions => ({
    ...getMockDateFormatDiffOptions(suffix),
    orSame: true,
});

// Retourne au format "YYYY-MM-DD"
export const getMockDateString = (): string => (DateFormat.dateFromDatetime(getMockDateTimeString()));

// Retourne au format "YYYY-MM-DDTHH:mm:SS.mmmZ"
export const getMockDateTimeString = (): string => ((new Date()).toISOString());

// Retourne au format "YYYY-MM-DDTHH:mm:SSZ"
export const getMockDateTimeForAPI = (): string => (DateFormat.toAPI(getMockDateTimeString()));
