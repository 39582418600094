import {AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgVente} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {delay, firstValueFrom, from, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {VenteService} from '@models/ventes/vente/vente.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {IPromise} from 'angular';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import {ILinkApi} from '@models/links/links.interfaces';
import {AppBienEditDescriptionComponent} from '@features/bien-edit/description/bien.edit-description.component';

@Component({selector: 'layout-vente-edit', templateUrl: 'layout-vente-edit.component.html'})
export class AppLayoutVenteEditComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.<br><br>Pensez à compléter les données obligatoires ' +
            'dans "Général", "Photos", "Construction" et/ou "Formalités et Coûts"',
        TITLE: 'Données incomplètes',
    };
    private _apiImpersonationService = inject(ApiImpersonationService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _venteService = inject(VenteService);
    private _initVente!: NgVente;
    private _hasConstraintOnPhotos = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _saveIsDone = false;
    private _saving = false;
    private _showSaveButton = true;
    private _vente!: NgVente;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    get vente(): NgVente {
        return this._vente;
    }

    get hasConstraintOnPhotos(): boolean {
        return this._hasConstraintOnPhotos;
    }

    get saving(): boolean {
        return this._saving;
    }

    get showSaveButton(): boolean {
        return this._showSaveButton;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        if (this.vente?.mandatPremiereDate) {
            this.editForm.onSubmit(undefined!);
            if (this.ngBienDossierCurrent.hasFieldsInvalid(this.editForm, true)) {
                this._formVenteNotValid();

                return of(false);
            }

            return from(this.savePromise().then(_ => true, _ => false));
        }

        return of(true);
    }

    ngOnInit(): void {
        this.ngVenteManager.current$.pipe(
            tap(ngVente => this._initVente = {...ngVente}),
            tap(_ => this._hasConstraintOnPhotos = this.ngBienDossierCurrent.hasConstraints('photos')),
            takeUntil(this._onDestroy$),
        ).subscribe(ngVente => this._vente = ngVente);
        this._router.events.pipe(
            map(event => event as RouterEvent),
            filter(event => event instanceof NavigationStart),
            map((event: NavigationStart) => !event.url.endsWith('/edit/photos') && !event.url.endsWith('/edit/virtual_visit')),
            takeUntil(this._onDestroy$),
        ).subscribe(showSaveButton => this._showSaveButton = showSaveButton);
    }

    ngAfterViewInit(): void {
        this._venteService.needToBeSaved$.pipe(
            filter(_ => !!this.vente),
            takeUntil(this._onDestroy$),
        ).subscribe(reason => this.save(true, reason));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.vente?.id) {
            this.save();
        }
    }

    save(ignoreSubmitted = false, reason?: string): void {
        const isVenteNew = !this.vente.id;

        if (this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isVenteNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if (!!this.vente.mandatPremiereDate && (this.editForm.submitted || ignoreSubmitted) && this.ngBienDossierCurrent.hasFieldsInvalid(this.editForm, true)) {
            this._formVenteNotValid();
            return;
        }

        /*
                If (this.vente.statut === Vente.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formVenteNotValid();
                }
        */

        this.savePromise(reason).then(_ => {
            if (isVenteNew) {
                return this._router.navigateByUrl('/app/ventes/' + this.vente.id.toString(), {replaceUrl: true}) as unknown;
            } else {
                return this.ngVenteManager.updateIfModified(this._initVente, this.vente).then(() => this._initVente = this.vente);
            }
        }, () => {
        });
    }

    savePromise(reason?: string): IPromise<void> {
        this._saveIsDone = true;
        this._saving = true;

        // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
        return firstValueFrom(of(undefined).pipe(
            delay(600),
            switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.vente.bien.contactsGroup))),
        )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
            .then(contactsGroup => this.vente.bien.contactsGroup = contactsGroup)
/*
            .then(_ => firstValueFrom(this._venteFactory.save$(this._venteFactory.ngCreate(this.vente)).pipe(
                switchMap(vente => {
                    if (reason === AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF) {
                        return this._dossierBienService.updateTitreDescriptif$(vente);
                    }

                    return of(vente);
                }),
            )))
            .then(vente => this.ngVenteManager.getOneByUuid(vente.uuid))
            .then(ngVente => this.ngVenteManager.emitCurrent(ngVente))
            .catch((error: string) => {
*/
            // @todo Code inutile, à supprimer quand le commentaire au-dessus sera supprimé
            .then(_ => {
                let reasonTest = reason;
                reasonTest = reasonTest === AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF ? undefined : reasonTest;
                reason = reasonTest;
            })
            .then(_ => this.vente.save())
            .then(ngVente => {
                this.ngVenteManager.current = ngVente;
                this.vente._links.contactsGroup = {href: '/contacts-groups/' + this.vente.bien.contactsGroup.id.toString()} as ILinkApi;
            }, (error: string) => {
                if (!['backdrop', 'cancel'].includes(error)) {
                    this._toasterService.error('Oups !', 'Une erreur est survenue lors de la mise à jour de votre bien en vente.');
                }

                return Promise.reject(error);
            })
            .finally(() => this._saving = false);
    }

    private _formVenteNotValid(): void {
        this.ngSoqSweetAlert.warningMessage(AppLayoutVenteEditComponent.errorMessages.TITLE, AppLayoutVenteEditComponent.errorMessages.OTHER);

        return;
    }
}
