import {inject, Injectable} from '@angular/core';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {IEmailRecipientApi} from '@models/emails/email/recipients/recipient/email-recipient.interfaces';
import {NgEmailRecipient} from '@legacy/app/managers/ressources';
import {Observable} from 'rxjs';
import {EmailRecipientsApiService} from '@models/emails/email/recipients/email-recipients.api.service';
import Email from '@models/emails/email/email.model';

@Injectable({providedIn: 'root'})
export class EmailRecipientFactory {
    private _emailRecipientsApiService = inject(EmailRecipientsApiService);

    create(emailRecipientApi: IEmailRecipientApi): EmailRecipient {
        const emailRecipient: EmailRecipient = this.createVirgin(emailRecipientApi.id?.toString(), emailRecipientApi.id);

        emailRecipient.emailAddress = emailRecipientApi.emailAddress;
        emailRecipient.name = emailRecipientApi.name;
        emailRecipient.statut = emailRecipientApi.status;
        emailRecipient.type = emailRecipientApi.type;

        return emailRecipient;
    }

    createVirgin(uuid?: string, id?: number): EmailRecipient {
        return new EmailRecipient(uuid ?? EmailRecipient.statuts.NEW, id);
    }

    delete$(email: Email, emailRecipient: EmailRecipient): Observable<void> {
        return this._emailRecipientsApiService.delete$(email.id.toString(), emailRecipient.id.toString());
    }

    ngCreate(ngEmailRecipient: NgEmailRecipient): EmailRecipient {
        const emailRecipient: EmailRecipient = this.createVirgin(ngEmailRecipient.id.toString(), ngEmailRecipient.id);

        emailRecipient.emailAddress = ngEmailRecipient.emailAddress;
        emailRecipient.name = ngEmailRecipient.name;
        emailRecipient.statut = ngEmailRecipient.status;
        emailRecipient.type = ngEmailRecipient.type;

        return emailRecipient;
    }
}
