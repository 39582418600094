import {inject, Injectable} from '@angular/core';
import Reference from '@models/references/reference/reference.model';
import {IReferenceApi} from '@models/references/reference/reference.interfaces';
import {Observable} from 'rxjs';
import {ReferencesApiService} from '@models/references/references.api.service';
import {map} from 'rxjs/operators';
import Estimation from '@models/estimations/estimation/estimation.model';
import DateFormat from '@shared/date/date.format';

@Injectable({providedIn: 'root'})
export class ReferenceFactory {
    private _referencesApiService = inject(ReferencesApiService);

    create(referenceApi: IReferenceApi): Reference {
        const reference = this.createVirgin(referenceApi.uuid);

        reference.adresse = referenceApi.adresse;
        reference.communeId = referenceApi.communeId;
        reference.dateVente = referenceApi.dateVente;
        reference.distance = referenceApi.distance;
        reference.epoqueId = referenceApi.epoqueId;
        reference.linkSelf = '/references/' + reference.uuid;
        reference.loyer = referenceApi.loyer;
        reference.natureId = referenceApi.natureId;
        reference.nombreChambres = referenceApi.nombreChambres;
        reference.nombreEtages = referenceApi.nombreEtages;
        reference.nombrePieces = referenceApi.nombrePieces;
        reference.prixVente = referenceApi.prixVente;
        reference.superficie = referenceApi.superficie;
        reference.surfaceCadastrale = referenceApi.surfaceCadastrale;
        if (referenceApi._links?.commune) {
            reference.linkCommune = referenceApi._links.commune.href;
        }

        return reference;
    }

    createFromEstimation(estimation: Estimation): Reference {
        const reference = this.createVirgin();

        reference.adresse = estimation.bien.inlineAdresse;
        reference.communeId = estimation.bien.commune?.id;
        reference.dateVente = DateFormat.dateFromNow();
        reference.epoqueId = estimation.bien.epoqueConstruction?.id;
        reference.linkCommune = estimation.bien.commune?.linkSelf;
        reference.loyer = estimation.bien.loyerActuel;
        reference.natureId = estimation.bien.nature.id;
        reference.nombreChambres = estimation.bien.nombreChambres;
        reference.nombreEtages = estimation.bien.nombreEtages;
        reference.nombrePieces = estimation.bien.nombrePieces;
        reference.prixVente = estimation.valeurVenale || estimation.valeurVenaleMin || estimation.valeurVenaleMax;
        reference.superficie = estimation.bien.superficie;
        reference.surfaceCadastrale = estimation.bien.surfaceCadastrale;

        return reference;
    }

    createVirgin(uuid?: string): Reference {
        return new Reference(uuid ?? Reference.statuts.NEW);
    }

    forApi(reference: Reference): IReferenceApi {
        const referenceApi = {} as IReferenceApi;

        referenceApi.adresse = reference.adresse;
        referenceApi.dateVente = reference.dateVente;
        referenceApi.communeId = reference.communeId;
        referenceApi.epoqueId = reference.epoqueId;
        referenceApi.loyer = reference.loyer;
        referenceApi.natureId = reference.natureId;
        referenceApi.nombreChambres = reference.nombreChambres;
        referenceApi.nombreEtages = reference.nombreEtages;
        referenceApi.nombrePieces = reference.nombrePieces;
        referenceApi.prixVente = reference.prixVente;
        referenceApi.superficie = reference.superficie;
        referenceApi.surfaceCadastrale = reference.surfaceCadastrale;

        return referenceApi;
    }

    getByLink$(link: string): Observable<Reference> {
        return this._referencesApiService.getByLink$(link).pipe(map(referenceApi => this.create(referenceApi)));
    }

    save$(reference: Reference): Observable<Reference> {
        return this._referencesApiService.save$(this.forApi(reference)).pipe(map(referenceApi => this.create(referenceApi)));
    }
}
