export default `
<div class="container-fluid container-fullw tw-bg-white margin-bottom-30">
  <ng-switch on="$ctrl.location.canBeAvailable()">
    <div ng-switch-when="true">
      <!-- Rendre disponible -->
      <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.BROUILLON || $ctrl.location.statut === $ctrl.STATUTS.SUSPENDU"
           class="row margin-top-15">
        <div class="col-sm-3 text-large text-warning text-justify">
          Votre bien en location est considéré comme occupé et ne peut donc pas être proposé à la diffusion.
        </div>

        <div class="col-sm-3">
          <div class="tw-text-center">
            <button type="button" class="btn btn-primary" ng-click="$ctrl.available()">
              <fa-icon [icon]="'key'"></fa-icon> Rendre disponible
            </button>
          </div>

          <p class="margin-top-30">
            En rendant votre bien en location <span class="text-bold">disponible</span>, vous pourrez en faire sa
            publicité dans la partie
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/locations/' + $ctrl.location.id.toString() + '/diffusion')">
              diffusion
            </a>.
          </p>
        </div>

        <div class="col-sm-2">
          <label>Date de disponibilité</label>

          <esk-select-date model="$ctrl.location.bien.metadata.occupation.dateDisponibilite"
                           on-select="$ctrl.saveLocation()"></esk-select-date>
        </div>
      </div>

      <!-- Rendre indisponible -->
      <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.DIFFUSE || $ctrl.location.statut === $ctrl.STATUTS.DISPONIBLE" class="row margin-top-15">
        <div class="col-sm-3 text-large text-success text-justify">
          Votre bien en location est disponible.<br>Vous pouvez le proposer dans la partie
          <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/locations/' + $ctrl.location.id.toString() + '/diffusion')">
            diffusion
          </a>.
        </div>

        <div class="col-sm-3">
          <div class="tw-text-center">
            <button type="button" class="btn btn-danger btn-o" ng-click="$ctrl.location.suspend()">
              <fa-icon [icon]="'house'"></fa-icon> Rendre indisponible
            </button>
          </div>

          <p class="margin-top-30 text-justify">
            Votre bien en location
            <ng-switch on="!!$ctrl.location.bien.metadata.occupation.dateDisponibilite">
              <ng-switch on="$ctrl.today < $ctrl.location.bien.metadata.occupation.dateDisponibilite"
                         ng-switch-when="true">
                <span ng-switch-when="true">sera <span class="text-bold">disponible</span></span>

                <span ng-switch-default>est <span class="text-bold">disponible</span> depuis</span> le
                <app-ng2-date [date]="$ctrl.location.bien.metadata.occupation.dateDisponibilite" format="shortDate"></app-ng2-date>.
              </ng-switch>

              <span ng-switch-default>est <span class="text-bold">disponible</span>.</span>
            </ng-switch>
            <br>
            Si un locataire a été trouvé, vous pouvez le rendre indisponible. Il sera automatiquement supprimé de toutes
            les diffusions.
          </p>
        </div>
      </div>
    </div>

    <div ng-switch-default>
      <h4>
        <fa-icon [icon]="'exclamation-triangle'"></fa-icon>
        Données manquantes pour rendre disponible votre bien en location
      </h4>

      <p ng-include="'src/app/legacy/templates/locations/disponibilite.missing-information.html'"></p>
    </div>
  </ng-switch>
</div>

<div class="container-fluid container-fullw tw-bg-white margin-bottom-30">
  <ng-switch on="$ctrl.loadingLocataires">
    <div ng-switch-when="true">
      <p class="tw-text-center text-extra-large">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des locataires
      </p>
    </div>

    <fieldset ng-switch-default class="tw-space-y-8">
      <legend>Locataires actuels</legend>

      <esk-dossier-contacts-group-edit changed="$ctrl.onGroupCreated"></esk-dossier-contacts-group-edit>

      <hr ng-if="$ctrl.locataireActuel.locatairesGroup.id">

      <div ng-if="$ctrl.locataireActuel.locatairesGroup.id" class="row">
        <div class="col-sm-2">
          <label>Début de bail</label>

          <esk-select-date model="$ctrl.locataireActuel.bailDate" options="$ctrl.options.bailDate"
                           on-select="$ctrl.onLocataireActuelDateChanged()"></esk-select-date>
        </div>

        <div class="col-sm-2">
          <label>Fin de bail</label>

          <esk-select-date model="$ctrl.locataireActuel.bailDateFin" options="$ctrl.options.bailDateFin"
                           on-select="$ctrl.onLocataireActuelDateChanged()"></esk-select-date>
        </div>

        <div class="col-sm-2 col-sm-offset-1">
          <label>Date d'entrée</label>

          <esk-select-date model="$ctrl.locataireActuel.dateEntree" options="$ctrl.options.dateSortie"
                           on-select="$ctrl.onLocataireActuelDateChanged()"></esk-select-date>
        </div>

        <div class="col-sm-2">
          <label>Date de sortie</label>

          <esk-select-date model="$ctrl.locataireActuel.dateSortie" options="$ctrl.options.dateEntree"
                           on-select="$ctrl.onLocataireActuelDateChanged()"></esk-select-date>
        </div>
      </div>

      <div ng-if="$ctrl.locataireActuel.locatairesGroup.id" class="pull-right">
        <button type="button" class="btn btn-primary" ng-click="$ctrl.openEtatsLieux($ctrl.locataireActuel)">
          <fa-icon [icon]="'house'"></fa-icon> États des lieux
        </button>

        <button type="button" class="btn btn-danger btn-o" ng-click="$ctrl.locataireActuelDisabled()">
          <fa-icon [icon]="'house'"></fa-icon> Sortie des locataires
        </button>
      </div>
    </fieldset>

    <fieldset ng-switch-default ng-if="($ctrl.locataires | filter: {enabled: false}).length > 0">
      <legend>Anciens locataires</legend>

      <table class="table table-hover table-responsive">
        <thead>
        <tr>
          <th>Nom du groupe de locataires</th>

          <th class="tw-text-center">Début de bail</th>

          <th class="tw-text-center">Fin de bail</th>

          <th class="tw-text-center">Date d'entrée</th>

          <th class="tw-text-center">Date de sortie</th>
        </tr>
        </thead>

        <tr ng-repeat="locataire in $ctrl.locataires | filter: {enabled: false}">
          <td>{{locataire.locatairesGroup.nom}}</td>

          <td class="tw-text-center"><app-ng2-date [date]="locataire.bailDate" format="shortDate"></app-ng2-date></td>

          <td class="tw-text-center">
            <app-ng2-date [date]="locataire.bailDateFin" format="shortDate"></app-ng2-date>
          </td>

          <td class="tw-text-center"><app-ng2-date [date]="locataire.dateEntree" format="shortDate"></app-ng2-date></td>

          <td class="tw-text-center"><app-ng2-date [date]="locataire.dateSortie" format="shortDate"></app-ng2-date></td>
        </tr>
      </table>
    </fieldset>
  </ng-switch>
</div>
`;
