@if (currentUser$ | async; as currentUser) {
  @if (currentUser.hasRoleEstimationNotestimV2()) {
    @if (estimation$ | async; as estimation) {
      @if (estimation.bien.isAppartement() || estimation.bien.isMaison()) {
        <!-- Nature du bien APPARTEMENT ou MAISON -->
        @if (estimationNotestim$ | async; as estimationNotestim) {
          @if (estimationNotestim.isInit) {
            <!-- Évaluation Notestim V2 lancée -->
            <!-- tw-mx-[-15px] est utile pour utiliser la largeur complète de l'écran -->
            <div class="tw-h-full tw-mx-[-15px] tw-pt-px">
              <app-estimation-evaluation-notestim [estimation]="estimation" [estimationNotestim]="estimationNotestim"/>
            </div>
          } @else {
            @if (estimation.valeurVenaleBrute <= 0) {
              <!-- Aucune évaluation Noty existante, demande de création pour Notestim V2 -->
              <div class="tw-flex tw-flex-col tw-items-center tw-gap-8 tw-bg-white tw-p-16">
                <app-noteo-logo-notestim class="tw-max-w-[400px] tw-w-full"/>

                <h2 class="tw-text-septeo-blue-900 tw-font-normal">
                  Les données de la fiche de votre bien en estimation vont être transmises à
                  <!-- 23px pour 33px -->
                  <app-noteo-logo-notestim class="tw-h-[23px]"/>.
                </h2>

                <div class="tw-space-y-4 tw-text-center">
                  <button type="button" class="tw-btn-primary-info" (click)="createNotestim(estimation)">
                    <!-- 12px pour 17px -->
                    <span>Évaluer avec</span> <app-noteo-logo-notestim [isWhite]="true" class="tw-h-[12px]"/>
                  </button>

                  <p>
                    Les données relatives au(x) propriétaire(s) restent dans
                    <app-noteo-icon-text-noty [options]="{size: 14}"/>.
                  </p>
                </div>
              </div>
            } @else {
              <!-- Évaluation Noty existante, demande pour créer une évaluation Notestim V2 tout en bas -->
              <div class="container-fluid container-fullw tw-bg-white estimation-evaluation">
                <app-ng-estimation-evaluation/>

                <div class="tw-flex tw-justify-center tw-max-w-[45rem] tw-mx-auto">
                  <div class="tw-flex tw-justify-center tw-items-center tw-gap-4 md:tw-gap-8 tw-bg-blue-100 tw-border tw-border-blue-300 tw-rounded-lg tw-shadow-lg tw-py-4 tw-px-4 md:tw-px-8 tw-text-sm tw-font-semibold">
                    <div>
                      <!-- 12px pour 17px -->
                      Valorisez mon bien en estimation avec <app-noteo-logo-notestim class="tw-h-[12px]"/>
                    </div>

                    <button type="button" class="tw-btn-primary-info" (click)="upgradeToNotestim(estimation)">
                      <!-- 12px pour 17px -->
                      <span>Évaluer avec</span> <app-noteo-logo-notestim [isWhite]="true" class="tw-h-[12px]"/>
                    </button>
                  </div>
                </div>
              </div>
            }
          }
        } @else {
          <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
        }
      } @else {
        <!-- Nature du bien n'est ni APPARTEMENT ni MAISON -->
        <div class="container-fluid container-fullw tw-bg-white estimation-evaluation">
          <app-ng-estimation-evaluation/>
        </div>
      }
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
    }
  } @else {
    <!-- Module Notestim V2 inactif, demande du module tout en bas -->
    <div class="container-fluid container-fullw tw-bg-white estimation-evaluation">
      <app-ng-estimation-evaluation/>

      <div class="tw-flex tw-justify-center tw-max-w-[45rem] tw-mx-auto">
        <div class="tw-flex tw-justify-center tw-items-center tw-gap-4 md:tw-gap-8 tw-bg-blue-100 tw-border tw-border-blue-300 tw-rounded-lg tw-shadow-lg tw-py-4 tw-px-4 md:tw-px-8 tw-text-sm tw-font-semibold">
          <div>
            <!-- 12px pour 17px -->
            Gagnez du temps sur vos évaluations grâce à <app-noteo-logo-notestim class="tw-h-[12px]"/>
          </div>

          <app-noteo-contact [module]="MODULE_NOTESTIM"/>
        </div>
      </div>
    </div>
  }
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
