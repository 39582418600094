import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {ILoadProgressBarOptions} from '@shared/load/load.interfaces';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {AsyncPipe} from '@angular/common';

@Component({
    imports: [AppLoaderComponent, AsyncPipe],
    selector: 'app-load-progress-bar',
    standalone: true,
    templateUrl: 'load-progress-bar.component.html',
})
export class AppLoadProgressBarComponent {
    static readonly initLoadProgressBarOptions: ILoadProgressBarOptions = {waitingLabel: 'Lancement du traitement'};
    private _options: ILoadProgressBarOptions = {...AppLoadProgressBarComponent.initLoadProgressBarOptions};
    private _progress$!: Observable<number>;

    get options(): ILoadProgressBarOptions {
        return this._options;
    }

    @Input()
    set options(value: ILoadProgressBarOptions) {
        this._options = {...AppLoadProgressBarComponent.initLoadProgressBarOptions, ...value};
    }

    get progress$(): Observable<number> {
        return this._progress$;
    }

    @Input()
    set progress$(value: Observable<number>) {
        this._progress$ = value;
    }
}
