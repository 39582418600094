import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    IBienCadastreDoubleMandatApi, IBienCadastreDoubleMandatQueryParameters
} from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.interfaces';
import {IBienCadastreApi} from '@models/bien/cadastres/cadastre/bien-cadastre.interfaces';
import BienCadastre from '@models/bien/cadastres/cadastre/bien-cadastre.model';

@Injectable({providedIn: 'root'})
export class BienCadastresApiService {
    private _modelApiService = inject(ModelApiService);

    checkDoubleMandat$(queryParameters: IBienCadastreDoubleMandatQueryParameters): Observable<IBienCadastreDoubleMandatApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/biens/cadastres/check', queryParameters));
    }

    delete$(dossierBienId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/biens/${dossierBienId}/cadastres/${id}`);
    }

    get$(dossierBienId: string, id: string): Observable<IBienCadastreApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/cadastres/${id}`);
    }

    save$(dossierBienId: string, id: string, bienCadastreApi: IBienCadastreApi): Observable<IBienCadastreApi> {
        if (id === BienCadastre.STATUT_NEW) {
            return this._modelApiService.post$(`/biens/${dossierBienId}/cadastres`, bienCadastreApi);
        }

        return this._modelApiService.put$(`/biens/${dossierBienId}/cadastres/${id}`, bienCadastreApi)
            .pipe(switchMap(_ => this.get$(dossierBienId, id)));
    }
}
