import {IContactApi, IEntrepriseApi, IPersonApi} from '@models/contacts/contacts.interfaces';
import Contact from '@models/contacts/contact/contact.model';
import {getMockDictionaryItem} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import Person from '@models/contacts/person/person.model';
import Entreprise from '@models/contacts/entreprise/entreprise.model';
import {getMockLinks, getMockLinksApi} from '@models/links/links.fixtures';
import {getMockCollectionApi} from '@models/collection/collections.fixtures';
import CContacts from '@models/contacts/collection/contacts.collection.model';
import {ICContactsApi, ICContactsQueryParameters,} from '@models/contacts/collection/contacts.collection.interfaces';
import {getMockQueryParameters, getMockQueryParameterConditionString} from '@shared/texts/texts.fixtures';
import {Observable, of} from 'rxjs';

export const getMockCContacts = (): CContacts => {
    const cContacts = new CContacts();

    cContacts.links = getMockLinks();
    cContacts.page = 4;
    cContacts.pages = 8;
    cContacts.perPage = 2;
    cContacts.results = [getMockContact(), getMockContact()];
    cContacts.total = 5;

    return cContacts;
};

export const getMockCContactsApi = (): ICContactsApi => getMockCollectionApi([
    getMockContactApi(),
    getMockContactApi(),
]);

export const getMockCContactsQueryParameters = (suffix = 'c-contacts-query-parameters'): ICContactsQueryParameters => ({
    ...getMockQueryParameters(suffix),
    ...{source: 'source-' + suffix, type: getMockQueryParameterConditionString(suffix)},
});

export class MockContact extends Contact {
    private _type = 'type';

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    setConvivialName(): void {
    }
}

export const getMockContact = (data = {}, options: { withRepresentant?: boolean } = {}): Contact => {
    const contactData = data as Contact;
    const suffix = 'contact';
    const contact = new MockContact(contactData.uuid ?? 'uuid-' + suffix, 2);

    contact.adresse = 'adresse-' + suffix;
    contact.codePostal = 'codePostal-' + suffix;
    contact.colorVariation = 'colorVariation-' + suffix;
    contact.comments = 'comments-' + suffix;
    contact.communeId = 7;
    contact.externalId = 'externalId-' + suffix;
    contact.externalSource = contactData.externalSource ?? 'externalSource-' + suffix;
    contact.emailAddress = 'emailAddress-' + suffix;
    contact.formuleAdressePostale = 'formuleAdressePostale-' + suffix;
    contact.formuleIntroduction = 'formuleIntroduction-' + suffix;
    contact.formulePolitesse = 'formulePolitesse-' + suffix;
    contact.invalidEmailAddress = true;
    contact.linkCommune = 'linkCommune-' + suffix;
    contact.mobile = 'mobile-' + suffix;
    contact.telephone = 'telephone-' + suffix;
    contact.unsubscribed = true;
    if (options.withRepresentant) {
        contact.representant = getMockContact({}, {withRepresentant: false});
    }

    return contact;
};

export const getMockContactApi = (type = 'type-contact-api', withRepresentant = true): IContactApi => {
    const suffix = 'contact-api';
    let representant = undefined as unknown as IContactApi;

    if (withRepresentant) {
        representant = getMockContactApi(type + '-representant', false);
    }

    return {
        _embedded: {entreprise: getMockEntrepriseApi(), person: getMockPersonApi(), representant},
        entreprise: getMockEntrepriseApi(),
        externalId: 'externalId-' + suffix,
        externalSource: 'externalSource-' + suffix,
        formuleAdressePostale: 'formuleAdressePostale-' + suffix,
        formuleIntroduction: 'formuleIntroduction-' + suffix,
        formulePolitesse: 'formulePolitesse-' + suffix,
        id: 2,
        invalidEmailAddress: true,
        person: getMockPersonApi(),
        representantId: 5,
        type,
        uuid: 'uuid-' + suffix,
        unsubscribed: true,
    };
};

export const getMockEntreprise = (withRepresentant = true): Entreprise => {
    const suffix = Contact.type.ENTREPRISE;
    const entreprise = new Entreprise('uuid-' + suffix, 8);

    entreprise.adresse = 'adresse-' + suffix;
    entreprise.capital = 6;
    entreprise.codePostal = 'codePostal-' + suffix;
    entreprise.colorVariation = 'colorVariation-' + suffix;
    entreprise.comments = 'comments-' + suffix;
    entreprise.communeId = 7;
    entreprise.emailAddress = 'emailAddress-' + suffix;
    entreprise.formeJuridique = 'formeJuridique-' + suffix;
    entreprise.initiales = 'initiales-' + suffix;
    entreprise.linkCommune = 'linkCommune-' + suffix;
    entreprise.mobile = 'mobile-' + suffix;
    entreprise.raisonSociale = 'raisonSociale-' + suffix;
    entreprise.siret = 'siret-' + suffix;
    entreprise.telephone = 'telephone-' + suffix;
    if (withRepresentant) {
        entreprise.representant = getMockEntreprise(false);
    }

    return entreprise;
};

export const getMockEntrepriseApi = (): IEntrepriseApi => {
    const suffix = 'entreprise-api';

    return {
        _links: getMockLinksApi('commune'),
        adresse: 'adresse-' + suffix,
        capital: 6,
        codePostal: 'codePostal-' + suffix,
        colorVariation: 'colorVariation-' + suffix,
        comments: 'comments-' + suffix,
        communeId: 7,
        emailAddress: 'emailAddress-' + suffix,
        formeJuridique: 'formeJuridique-' + suffix,
        initiales: 'initiales-' + suffix,
        mobile: 'mobile-' + suffix,
        raisonSociale: 'raisonSociale-' + suffix,
        siret: 'siret-' + suffix,
        telephone: 'telephone-' + suffix,
    };
};

export const getMockPerson = (withRepresentant = true): Person => {
    const suffix = Contact.type.PERSON;
    const person = new Person('uuid-' + suffix, 8);

    person.adresse = 'adresse-' + suffix;
    person.autresPrenoms = 'autresPrenoms-' + suffix;
    person.codePostal = 'codePostal-' + suffix;
    person.colorVariation = 'colorVariation-' + suffix;
    person.comments = 'comments-' + suffix;
    person.communeId = 7;
    person.communeNaissance = 'communeNaissance-' + suffix;
    person.dateNaissance = 'dateNaissance-' + suffix;
    person.emailAddress = 'emailAddress-' + suffix;
    person.etranger = true;
    person.initiales = 'initiales-' + suffix;
    person.linkCommune = 'linkCommune-' + suffix;
    person.mobile = 'mobile-' + suffix;
    person.nationalite = 'nationalite-' + suffix;
    person.nom = 'nom-' + suffix;
    person.nomJeuneFille = 'nomJeuneFille-' + suffix;
    person.prenom = 'prenom-' + suffix;
    person.profession = 'profession-' + suffix;
    person.telephone = 'telephone-' + suffix;
    person.titre = getMockDictionaryItem();
    if (withRepresentant) {
        person.representant = getMockPerson(false);
    }

    return person;
};

export const getMockPersonApi = (): IPersonApi => {
    const suffix = 'person-api';

    return {
        _links: getMockLinksApi('commune'),
        adresse: 'adresse-' + suffix,
        autresPrenoms: 'autresPrenoms-' + suffix,
        codePostal: 'codePostal-' + suffix,
        colorVariation: 'colorVariation-' + suffix,
        comments: 'comments-' + suffix,
        communeId: 7,
        communeNaissance: 'communeNaissance-' + suffix,
        dateNaissance: 'dateNaissance-' + suffix,
        emailAddress: 'emailAddress-' + suffix,
        etranger: true,
        initiales: 'initiales-' + suffix,
        mobile: 'mobile-' + suffix,
        nationalite: 'nationalite-' + suffix,
        nom: 'nom-' + suffix,
        nomJeuneFille: 'nomJeuneFille-' + suffix,
        prenom: 'prenom-' + suffix,
        profession: 'profession-' + suffix,
        telephone: 'telephone-' + suffix,
        titre: 'titre-' + suffix,
    };
};

export class MockCContactsFactory {
    create(): void {
    }

    createVirgin(): void {
    }

    get$(): void {
    }

    getByLink$(): void {
    }

    getNext$(): void {
    }
}

export class MockCContactsService {
    addNext$(): void {
    }
}

export class MockContactFactory {
    create(): void {
    }

    createFromVenteArchiveInfos(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    getByLink$(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockContactsApiService {
    delete$(): void {
    }

    get$(): void {
    }

    getByLink$(): void {
    }

    getCollectionByLink$(): void {
    }

    getEtudeCollection$(): void {
    }

    save$(): void {
    }
}

export class MockContactsService {
    get nbInvalid$(): Observable<number> {
        return of(8);
    }

    initNbInvalid$(): void {
    }

    selectFromDossierFiles$(): void {
    }
}

export class MockEntrepriseFactory {
    createFromContactApi(): void {
    }

    forApi(): void {
    }

    ngCreate(): void {
    }
}

export class MockPersonFactory {
    createFromContactApi(): void {
    }

    createVirgin(): void {
    }

    forApi(): void {
    }

    ngCreate(): void {
    }
}
