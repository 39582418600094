export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()" title="Fermer">
    <fa-icon [icon]="'times'"></fa-icon>
  </button>

  <h3 class="modal-title"><fa-icon [icon]="'house'"></fa-icon> États des lieux</h3>
</div>

<div ng-switch="$ctrl.loading" class="modal-body">
  <div ng-switch-when="true">
    <p class="tw-text-center text-extra-large">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des états de lieux
    </p>
  </div>

  <div ng-switch-default class="row">
    <div class="col-sm-4">
      <button type="button" class="btn btn-azure btn-block" ng-click="$ctrl.setCurrent()"
              ng-disabled="$ctrl.current !== null && !$ctrl.current._esk.persist">
        <fa-icon [icon]="'plus-circle'"></fa-icon> Nouvel état des lieux
      </button>
      <hr>
      <p class="tw-text-center text-muted" ng-show="$ctrl.etatsLieux.length === 0">
        Aucun état des lieux enregistré actuellement.
      </p>

      <div class="list-group" ng-show="$ctrl.etatsLieux.length > 0">
        <button type="button" class="list-group-item" ng-repeat="etatLieux in $ctrl.etatsLieux"
                ng-click="$ctrl.setCurrent(etatLieux)" ng-class="{active: etatLieux._esk.uuid === $ctrl.current._esk.uuid}">
          <span class="text-bold"><app-ng2-date [date]="etatLieux.date" format="shortDate"></app-ng2-date></span>

          <span ng-if="!!etatLieux.media" class="pull-right text-muted"><fa-icon [icon]="'paperclip'"></fa-icon></span>

          <div ng-if="!!etatLieux.maker" class="text-muted">
            <app-user-cartouche [link]="etatLieux._links.maker.href" [options]="{disableSlideOver: true}"
                                class="tw-block tw-h-4 tw-max-w-full tw-w-fit"></app-user-cartouche>
          </div>
        </button>
      </div>
    </div>

    <div class="col-sm-8">
      <form name="$ctrl.editForm" ng-submit="$ctrl.save()" ng-show="$ctrl.current && !$ctrl.removePromise" novalidate>
        <div class="row">
          <!-- Date de l'état des lieux -->
          <div class="col-sm-6">
            <div class="form-group"
                 ng-class="{'has-error': $ctrl.editForm.$submitted && ($ctrl.editForm.date.$error | json) !== '{}'}">
              <label>{{"Date" | translate}}</label>

              <esk-select-date form="$ctrl.editForm" form-field-name="date" model="$ctrl.current.date"
                               is-required="true"></esk-select-date>

              <span class="error text-small tw-block" ng-messages="$ctrl.editForm.date.$error"
                    ng-if="$ctrl.editForm.$submitted">
                <ng-messages-include src="messages.html"></ng-messages-include>
              </span>
            </div>
          </div>

          <!-- Réalisé par -->
          <div ng-if="!!$ctrl.currentEtude" class="col-sm-6">
            <div class="form-group">
              <label>{{"Réalisé par" | translate}}</label>

              <esk-select-user etudes="[$ctrl.currentEtude.id]" ng-model="$ctrl.current.maker"
                               roles="$ctrl.usersRoles"></esk-select-user>
            </div>
          </div>
        </div>

        <!-- Commentaires -->
        <div class="form-group">
          <label>Commentaires</label>

          <app-ng2-textarea [form-name]="'comments'" [text]="$ctrl.current.comments"
                            (changed)="$ctrl.current.comments = $event"></app-ng2-textarea>
        </div>

        <!-- Document joint -->
        <div ng-switch="!!$ctrl.current.media">
          <label>{{"Document joint" | translate}}</label>

          <ng-switch on="!!$ctrl.mediaAttached.file._esk.uploadXhr" ng-switch-when="false">
            <div ng-switch-default>
              <soq-input-file on-change="$ctrl.upload" id="input_file" id-dnd-field="input_file"
                              options="{multiple: false}"></soq-input-file>

              <p class="text-danger" ng-if="$ctrl.mediaAttached.file._esk.status === 'ERROR'">
                {{$ctrl.mediaAttached.file._esk.error}}
              </p>
            </div>

            <span ng-switch-when="true">
              : {{$ctrl.mediaAttached.file.name}}
              <span class="text-muted">{{$ctrl.mediaAttached.file.size / 1024 / 1024|number:2}} Mo</span>
              <br>

              <uib-progressbar class="progress-striped" value="$ctrl.mediaAttached.file._esk.progress"
                               ng-show="$ctrl.mediaAttached.file._esk.progress && $ctrl.mediaAttached.file._esk.progress < 100">
                {{$ctrl.mediaAttached.file._esk.progress}} %
              </uib-progressbar>
            </span>
          </ng-switch>

          <span ng-switch-when="true">
            : {{$ctrl.current.media.title}}

            <span class="text-muted">{{$ctrl.current.media.totalBytes / 1024 / 1024|number:2}} Mo</span>

            <button type="button" class="btn btn-link margin-left-10" ng-click="$ctrl.downloadMedia($ctrl.current.media)">
              <fa-icon [icon]="['far', 'eye']"></fa-icon> Voir
            </button>

            <button type="button" class="btn btn-link margin-left-10" ng-click="$ctrl.current.media = null">
              <fa-icon [icon]="['far', 'file']"></fa-icon> Retirer
            </button>
          </span>
        </div>

        <hr>

        <!-- Actions -->
        <div ng-hide="$ctrl.sending" class="text-right">
          <button class="btn btn-default" type="button" ng-click="$ctrl.setCurrent(null)">
            <fa-icon [icon]="'times'"></fa-icon> Annuler
          </button>

          <button class="btn btn-o btn-danger" type="button" ng-if="$ctrl.current._esk.persist"
                  ng-click="$ctrl.remove()"><fa-icon [icon]="['far', 'trash-alt']"></fa-icon> Supprimer</button>

          <button class="btn btn-primary" type="submit"><fa-icon [icon]="'check'"></fa-icon> Enregistrer</button>
        </div>

        <p ng-show="$ctrl.sending">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
        </p>

        <p ng-show="$ctrl.removing">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.REMOVE' | translate}}
        </p>
      </form>
    </div>
  </div>
</div>
`;
