import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgContactsGroupManager, NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgLocationLocataire} from '@legacy/app/managers/ressources';
import {NgLocationLocataireCollection} from '@legacy/app/managers/collections';
import DateFormat from '@shared/date/date.format';

export default function getManagersLocationLocataire(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('LocationLocataireManager', manager);

        /**
         * Manager locationLocataire
         *
         * @param BaseManager
         * @param UtilsManager
         * @param ContactsGroupManager
         * @returns {LocationLocataireManager}
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'ContactsGroupManager'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         contactsGroupManager: NgContactsGroupManager) {
            let classResource = baseManager.getClass<NgLocationLocataire>();

            /**
             * Routing
             */
            baseManager.addRoute('location-locataires.cget', {
                path: '/locations/{locationId}/locataires',
                method: 'GET'
            });
            baseManager.addRoute('location-locataires.insert', {
                path: '/locations/{locationId}/locataires/{id}',
                method: 'POST'
            });
            baseManager.addRoute('location-locataires.edit', {
                path: '/locations/{locationId}/locataires/{id}',
                method: 'PUT'
            });

            /**
             * LocationLocataireManager object
             *
             * @constructor
             */
            angular.extend(LocationLocataireManager.prototype, baseManager.__proto__);
            LocationLocataireManager.prototype.eskManager = {
                prefixRoute: 'location-locataires',
                collectionName: 'items'
            };

            function LocationLocataireManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @returns {Promise}
             */
            LocationLocataireManager.prototype.getAll = function (locationId: number) {
                return baseManager.getAll.call(this, {locationId: locationId}).then(locataires => {
                    const locatairesCollection: NgLocationLocataireCollection = locataires as NgLocationLocataireCollection;

                    angular.forEach(locatairesCollection.collection, function (locataire) {
                        locataire._esk.defaultRouteParams!.locationId = locationId;
                    });

                    return locatairesCollection;
                });
            };

            /**
             * Create a LocationLocataire object
             *
             * @param data
             * @param locationId
             * @returns manager.LocationLocataire object || Array of manager.LocationLocataire objects
             */
            LocationLocataireManager.prototype.create = function (data: unknown, locationId: number) {
                if (angular.isArray(data)) {
                    var locataires = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        locataires.push(new LocationLocataire(locationId, data[i]));
                    }

                    return locataires;
                }

                // @ts-ignore
                return new LocationLocataire(locationId, data);
            };

            /**
             * LocationLocataire object
             *
             * @param locationId
             * @param data
             * @constructor
             */
            angular.extend(LocationLocataire.prototype, classResource.prototype);
            LocationLocataire.prototype.eskManager = {prefixRoute: 'location-locataires'};

            function LocationLocataire(this: NgLocationLocataire, locationId: number, data: unknown) {
                this.extend(data);
                this.id = angular.isNumber(this.locatairesGroup.id) as unknown as number;
                this._esk.defaultRouteParams = {id: this.locatairesGroup.id, locationId: locationId};
                this._esk.persist = this.id as unknown as boolean;
                this._esk.flush = this._esk.persist;
            }

            /**
             * Extend the existing LocationLocataire with new data
             *
             * @param data
             * @returns {LocationLocataire}
             */
            LocationLocataire.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isObject(this.locatairesGroup)) {
                    this.locatairesGroup = contactsGroupManager.create(this.locatairesGroup);
                } else {
                    this.locatairesGroup = contactsGroupManager.create();
                }

                if (angular.isString(this.bailDate)) {
                    this.bailDate = DateFormat.toDate(this.bailDate);
                }

                if (angular.isString(this.bailDateFin)) {
                    this.bailDateFin = DateFormat.toDate(this.bailDateFin);
                }

                if (angular.isString(this.dateEntree)) {
                    this.dateEntree = DateFormat.toDate(this.dateEntree);
                }

                if (angular.isString(this.dateSortie)) {
                    this.dateSortie = DateFormat.toDate(this.dateSortie);
                }

                return this;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            LocationLocataire.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self.id = angular.isNumber(self.locatairesGroup.id);
                    self._esk.defaultRouteParams.id = self.locatairesGroup.id;

                    return self;
                });
            };

            /**
             * Sanitize object LocationLocataire before send to API
             *
             * @returns {Object}
             */
            LocationLocataire.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet LocationLocataire pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'locatairesGroup');
                sanitizeObject.bailDate = DateFormat.toAPI(this.bailDate);
                sanitizeObject.bailDateFin = DateFormat.toAPI(this.bailDateFin);
                sanitizeObject.dateEntree = DateFormat.toAPI(this.dateEntree);
                sanitizeObject.dateSortie = DateFormat.toAPI(this.dateSortie);

                return sanitizeObject;
            };

            // @ts-ignore
            return new LocationLocataireManager();
        }
    })(angularJS);
}
