import BienSurface from '@models/bien/surfaces/surface/bien-surface.model';
import {
    getMockDictionaryItem, getMockDictionaryItemApi
} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import {IBienSurfaceApi} from '@models/bien/surfaces/surface/bien-surface.interfaces';

export const getMockBienSurface = (): BienSurface => {
    const suffix = 'bien-surface';
    const bienSurface = new BienSurface('uuid-' + suffix, 3);

    bienSurface.comments = 'comments-' + suffix;
    bienSurface.etage = getMockDictionaryItem();
    bienSurface.otherLabel = 'otherLabel-' + suffix;
    bienSurface.piece = getMockDictionaryItem();
    bienSurface.surface = 3;

    return bienSurface;
};

export const getMockBienSurfaceApi = (): IBienSurfaceApi => {
    const suffix = 'bien-surface-api';

    return {
        _embedded: {etage: getMockDictionaryItemApi(), piece: getMockDictionaryItemApi()},
        comments: 'comments-' + suffix,
        etageId: 9,
        id: 3,
        habitable: true,
        otherLabel: 'otherLabel-' + suffix,
        pieceId: 5,
        ponderation: 4,
        surface: 7,
        uuid: 'uuid-' + suffix,
    };
};

export class MockBienSurfaceFactory {
    create(): void {
    }

    delete$(): void {
    }

    forApi(): void {
    }

    save$(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienSurfacesApiService {
    delete$(): void {
    }

    get$(): void {
    }

    save$(): void {
    }
}

export class MockBienSurfacesService {
    save$(): void {
    }
}
