import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import BienCommodite from '@models/bien/commodites/commodite/bien-commodite.model';
import {NgBienCommodite} from '@legacy/app/managers/ressources';
import {IBienCommoditeApi} from '@models/bien/commodites/commodite/bien-commodite.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BienCommoditesApiService} from '@models/bien/commodites/bien-commodites.api.service';

@Injectable({providedIn: 'root'})
export class BienCommoditeFactory {
    private _bienCommoditesApiService = inject(BienCommoditesApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienCommoditeApi: IBienCommoditeApi): BienCommodite {
        const bienCommodite: BienCommodite = new BienCommodite(bienCommoditeApi.uuid, bienCommoditeApi.id);

        bienCommodite.comments = bienCommoditeApi.comments;
        bienCommodite.distance = bienCommoditeApi.distance;
        bienCommodite.otherLabel = bienCommoditeApi.otherLabel;
        if (bienCommoditeApi._embedded?.commodite) {
            bienCommodite.commodite = this._dictionaryItemFactory.create(bienCommoditeApi._embedded.commodite);
        }

        return bienCommodite;
    }

    delete$(dossierBien: ADossierBien, bienCommodite: BienCommodite): Observable<void> {
        return this._bienCommoditesApiService.delete$(dossierBien.id.toString(), bienCommodite.id.toString());
    }

    forApi(bienCommodite: BienCommodite): IBienCommoditeApi {
        const bienCommoditeApi = {
            comments: bienCommodite.comments,
            distance: bienCommodite.distance,
            otherLabel: bienCommodite.otherLabel,
        } as IBienCommoditeApi;

        if (bienCommodite.commodite) {
            bienCommoditeApi.commoditeId = bienCommodite.commodite.id;
        }

        return bienCommoditeApi;
    }

    save$(dossierBien: ADossierBien, bienCommodite: BienCommodite): Observable<BienCommodite> {
        return this._bienCommoditesApiService.save$(dossierBien.id.toString(), bienCommodite.id?.toString() ?? BienCommodite.STATUT_NEW, this.forApi(bienCommodite))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }

    ngCreate(ngBienCommodite: NgBienCommodite): BienCommodite {
        const bienCommodite: BienCommodite = new BienCommodite(ngBienCommodite.uuid, ngBienCommodite.id);

        bienCommodite.comments = ngBienCommodite.comments;
        bienCommodite.distance = ngBienCommodite.distance;
        if (ngBienCommodite.commodite) {
            if (ngBienCommodite.commodite.id) {
                bienCommodite.commodite = this._dictionaryItemFactory.ngCreate(ngBienCommodite.commodite);
            } else {
                bienCommodite.otherLabel = ngBienCommodite.commodite.label;
            }
        }

        return bienCommodite;
    }
}
