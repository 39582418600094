import angularJS from '@shared/angularJS/global.ng';
import {find, map as lmap} from "lodash";
import {firstValueFrom, from, Observable, of, ReplaySubject} from 'rxjs';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';
import {filter, map, switchMap} from 'rxjs/operators';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import {IModule, IPromise, IQService} from 'angular';
import {NgBienDossierCurrent, NgBienFieldsConstraints} from '@legacy/app/biens/biens';
import {
    NgBienPhoto, NgBienPhotoPasserelle, NgEstimation, NgFilter, NgItemDictionary, NgLocation, NgUser, NgVente,
    NgVentePasserelle
} from '@legacy/app/managers/ressources';
import {
    NgBienManager, NgDictionariesManager, NgManager, NgTemplateManager, NgUserManager, NgUtilsManager,
    NgVenteCompromisManager, NgVenteManager, NgVentePasserelleManager
} from '@legacy/app/managers/managers';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {VenteCreditRentierFactory} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.factory';
import {VenteArchiveInfosFactory} from '@models/ventes/vente/archive-infos/vente-archive-infos.factory';
import * as angular from 'angular';
import VenteCreditRentier from '@models/ventes/vente/credit-rentier/vente-credit-rentier.model';
import {IVenteCreditRentierApi} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.interfaces';
import {HttpRejectionNg} from '@shared/angularJS/http.rejection.ng';
import {NgLocationPasserellesCollection, NgVentePasserellesCollection} from '@legacy/app/managers/collections';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Ng2Vente from '@models/ventes/vente/vente.model';
import User from '@core/models/users/user/user.model';
import {UserService} from '@models/users/user/user.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import { DictionaryItemService } from '@core/models/dictionaries/dictionary/items/item/dictionary-item.service';
import Passerelle from '@models/passerelle/passerelle.model';
import DateFormat from '@shared/date/date.format';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import {IVentePriceQueryParameters} from '@models/ventes/vente/price/vente-price.interfaces';
import Bien from '@models/bien/bien.model';
import Etude from '@models/etudes/etude/etude.model';
import {ILinksApi} from '@models/links/links.interfaces';

export default function getManagersVente(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('VenteManager', manager);

        /**
         * Manager vente
         *
         * @param BaseManager
         * @param BienManager
         * @param UtilsManager
         * @param VenteCompromisManager
         * @param DictionariesManager
         * @param $q
         * @param VentePasserelleManager
         * @param SoqSweetAlert
         * @param $translate
         * @param Ng2VenteFactory
         * @param Ng2VenteArchiveInfosFactory
         * @param Ng2VenteCompromisFactory
         * @param BienDossierCurrent
         * @param BienFieldsConstraints
         * @param Ng2VenteCreditRentierFactory
         * @param Ng2EtudeService
         * @param UserManager
         * @param TemplateManager
         * @param Ng2UserService
         * @param Ng2DictionaryItemService
         * @param Ng2VentePriceFactory
         */
        manager.$inject = ['BaseManager', 'BienManager', 'UtilsManager', 'VenteCompromisManager', 'DictionariesManager', '$q', 'VentePasserelleManager', 'SoqSweetAlert', '$translate', 'Ng2VenteFactory', 'Ng2VenteArchiveInfosFactory', 'Ng2VenteCompromisFactory', 'BienDossierCurrent', 'BienFieldsConstraints', 'Ng2VenteCreditRentierFactory', 'Ng2EtudeService', 'UserManager', 'TemplateManager', 'Ng2UserService', 'Ng2DictionaryItemService', 'Ng2VentePriceFactory'];
        function manager(baseManager: NgManager,
                         bienManager: NgBienManager,
                         utilsManager: NgUtilsManager,
                         venteCompromisManager: NgVenteCompromisManager,
                         dictionariesManager: NgDictionariesManager,
                         $q: IQService,
                         ventePasserelleManager: NgVentePasserelleManager,
                         soqSweetAlert: NgSoqSweetAlert,
                         $translate: angular.translate.ITranslateService,
                         ng2VenteFactory: VenteFactory,
                         ng2VenteArchiveInfosFactory: VenteArchiveInfosFactory,
                         ng2VenteCompromisFactory: VenteCompromisFactory,
                         bienDossierCurrent: NgBienDossierCurrent,
                         bienFieldsConstraints: NgBienFieldsConstraints,
                         ng2VenteCreditRentierFactory: VenteCreditRentierFactory,
                         ng2EtudeService: EtudeService,
                         userManager: NgUserManager,
                         templateManager: NgTemplateManager,
                         ng2UserService: UserService,
                         ng2DictionaryItemService: DictionaryItemService,
                         ng2VentePriceFactory: VentePriceFactory) {
            const classResource = baseManager.getClass();
            let currentSource = new ReplaySubject<NgVente>(1);
            let currentEtude: Etude;

            ng2EtudeService.current$.pipe(filter(etude => !!etude)).subscribe(etude => currentEtude = etude);

            /**
             * Routing
             */
            baseManager.addRoute('ventes.cget', {path: '/ventes', method: 'GET'});
            baseManager.addRoute('ventes.cget-etude', {path: '/etude/ventes', method: 'GET'});
            baseManager.addRoute('ventes.get', {path: '/ventes/{id}', method: 'GET'});
            baseManager.addRoute('ventes.get-uuid', {path: '/ventes/{uuid}', method: 'GET'});
            baseManager.addRoute('ventes.insert', {path: '/user/ventes', method: 'POST'});
            baseManager.addRoute('ventes.edit', {path: '/ventes/{id}', method: 'PUT'});
            baseManager.addRoute('ventes.diffuse', {path: '/ventes/{id}/internal/networks/diffuser', method: 'PATCH'});
            baseManager.addRoute('ventes.archive', {path: '/ventes/{id}/archiver', method: 'PATCH'});
            baseManager.addRoute('ventes.create-estimation', {path: '/ventes/{uuid}/estimation', method: 'PATCH'});
            baseManager.addRoute('ventes.create-location', {path: '/ventes/{id}/location', method: 'PATCH'});
            baseManager.addRoute('ventes.clone', {path: '/ventes/{uuid}/clone', method: 'PATCH'});

            /**
             * VenteManager object
             *
             * @constructor
             */
            angular.extend(VenteManager.prototype, baseManager.__proto__);
            VenteManager.prototype.eskManager = {prefixRoute: 'ventes', collectionName: 'items'};
            function VenteManager(this: NgVenteManager) {
                this.current$ = currentSource.asObservable();
                this.current$.subscribe(current => this.current = current);
                currentSource.next(undefined!);
            }

            /**
             * Create a Vente object
             *
             * @param ng2Vente
             * @returns manager.Vente
             */
            VenteManager.prototype.createFromNg2 = function (ng2Vente: Ng2Vente) {
                if (!ng2Vente) {
                    return $q.resolve(undefined!);
                }

                return bienManager.createFromNg2(DossierBienTypesConst.VENTE, ng2Vente.bien, ng2Vente.reference).then(bien => this.create({
                    _links: {self: {href: ng2Vente.linkSelf}},
                    archivePrixCession: ng2Vente.archivePrixCession,
                    bien,
                    honorairesNego: ng2Vente.honorairesNego,
                    id: ng2Vente.id,
                    prixAffiche: ng2Vente.prixAffiche,
                    referencePublique: ng2Vente.referencePublique,
                    statut: ng2Vente.statut,
                    type: dictionariesManager.createFromNg2(ng2Vente.type),
                    typeCharges: ng2Vente.typeCharges.code,
                    uuid: ng2Vente.uuid,
                    viagerBouquet: ng2Vente.viagerBouquet,
                    viagerValeurBien: ng2Vente.viagerValeurBien,
                }));
            };

            VenteManager.prototype.emitCurrent = function(ngVente: NgVente) {
                currentSource.next(ngVente);
            }

            /**
             * Init current and return promise
             *
             * @param venteId
             * @param currentUser
             * @returns {Promise}
             */
            VenteManager.prototype.initCurrent = function (venteId: number, currentUser: User) {
                const that = this;
                let promise: IPromise<NgVente>;

                if (venteId) {
                    promise = that.getOneById(venteId);
                } else {
                    promise = $q.resolve(that.create({
                        _links: {},
                        bien: {
                            notaire: userManager.createFromNg2(currentUser.defaultNotaire),
                            option: {risquesZone: true},
                            responsableDossier: userManager.createFromNg2(currentUser),
                        },
                        interne: true,
                    }));
                }

                return promise.then((vente: NgVente) => {
                    bienManager.initCurrent(vente.bien);
                    currentSource.next(vente);
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param venteId
             * @returns {Promise}
             */
            VenteManager.prototype.getOneById = function (venteId: number) {
                return this.get('get', {id: venteId}).then(function (vente: NgVente) {
                    const promises = [] as IPromise<unknown>[];

                    promises.push(vente.operatePricing().then(() => {
                        vente._esk.initialInfosPricing = angular.copy(vente._esk.infosPricing);
                    }));
                    if (vente.bien.interne && vente.bien?._links?.modifier?.href) {
                        promises.push(userManager.execLink<NgUser>(vente.bien._links.modifier.href).then(modifier => vente.bien.modifier = modifier));
                    }

                    if (vente.bien?._links?.responsableDossier?.href) {
                        promises.push(userManager.execLink<NgUser>(vente.bien._links.responsableDossier.href).then(responsableDossier => vente.bien.responsableDossier = responsableDossier));
                    }

                    return $q.all(promises).then(() => vente);
                });
            };

            /**
             * Returns the promise to fetch collection of ventes from etude
             *
             * @param params
             * @returns {Promise}
             */
            VenteManager.prototype.getAllEtude = function (params: unknown) {
                return this.get('cget-etude', params);
            };

            /**
             * Returns the promise to fetch collection of filtered ventes from etude
             *
             * @param filter
             * @param params
             * @returns {Promise}
             */
            VenteManager.prototype.getAllEtudeFiltered = function (filter: NgFilter, params: unknown) {
                return this.getAllEtude(angular.extend(filter.getParamsRequest(), params));
            };

            /**
             * Returns the promise to fetch collection of filtered ventes
             *
             * @param filter
             * @param params
             * @returns {Promise}
             */
            VenteManager.prototype.getAllFiltered = function (filter: NgFilter, params: unknown) {
                return this.get('cget', angular.extend(filter.getParamsRequest(), params));
            };

            /**
             * Update if vente is modified
             *
             * @param oldVente
             * @param vente
             */
            VenteManager.prototype.updateIfModified = function (oldVente: NgVente, vente: NgVente) {
                const haveToModified = oldVente.bien.nature.code !== vente.bien.nature.code ||
                    oldVente.typeCharges !== vente.typeCharges ||
                    (oldVente.bien.option.nombrePieces !== vente.bien.option.nombrePieces && vente.bien.nature.code === Bien.natures.APPARTEMENT);

                if (!haveToModified) {
                    return $q.resolve();
                }

                return ventePasserelleManager.getAll(vente.id).then(
                    ventePasserelles => ventePasserelles as NgLocationPasserellesCollection
                ).then(ventePasserelles => $q.all(ventePasserelles.collection.map(
                    ventePasserelle => ventePasserelleManager.getOneById(vente.id, ventePasserelle.passerelle.id).then(
                        thisVentePasserelle => thisVentePasserelle as NgVentePasserelle
                    ).then(thisVentePasserelle => {
                        if (!thisVentePasserelle.natureExternalType ||
                            oldVente.bien.nature.code !== vente.bien.nature.code ||
                            (oldVente.bien.option.nombrePieces !== vente.bien.option.nombrePieces && thisVentePasserelle.passerelle.normalizer === Passerelle.normalizers.PRECOM && vente.bien.nature.code === Bien.natures.APPARTEMENT)) {
                            thisVentePasserelle.natureExternalType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getNatureNormalizer(thisVentePasserelle.passerelle.normalizer, vente.bien._esk.type!, vente.bien.nature.code, vente.bien.option.nombrePieces));
                        }

                        if (!thisVentePasserelle.typePrixExternalType || oldVente.typeCharges.code !== vente.typeCharges.code) {
                            thisVentePasserelle.typePrixExternalType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode('types-prix-' + thisVentePasserelle.passerelle.normalizer, vente.typeCharges.code));
                        }

                        return thisVentePasserelle.save();
                    })))
                );
            }

            /**
             * Create a Vente object
             *
             * @param data
             * @returns Vente object || Array of manager.Vente objects
             */
            VenteManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var ventes = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        ventes.push(new Vente(data[i]));
                    }

                    return ventes;
                }

                // @ts-ignore
                return new Vente(data);
            };

            /**
             * Vente object
             *
             * @param data
             * @constructor
             */
            angular.extend(Vente.prototype, classResource.prototype);
            Vente.prototype.eskManager = {prefixRoute: 'ventes'};
            function Vente(this: NgVente, data: unknown) {
                this._links = {} as ILinksApi;
                this.type = Ng2Vente.types.SIMPLE as unknown as NgItemDictionary;
                this.statut = Ng2Vente.statuts.BROUILLON;
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, uuid: this.uuid};
            }

            /**
             * Extend the existing Vente with new data
             *
             * @param data
             * @returns {Vente}
             */
            Vente.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.bien.interne = this.interne;
                this._esk.typeDossier = DossierBienTypesConst.VENTE;
                this.bien = bienManager.create(this._esk.typeDossier, this.bien);
                if (this.bien._esk) {
                    this.bien._esk.interne = this.bien.interne;
                }

                if (angular.isString(this.type)) {
                    this.type = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_TYPES, this.type));
                }

                if (angular.isArray(this.compromis)) {
                    this.compromis = venteCompromisManager.create(this.compromis, this.id);
                } else {
                    this.compromis = [];
                }

                this.enabledCompromis = find(this.compromis, {enabled: true});
                if (!angular.isObject(this.enabledCompromis)) {
                    this.enabledCompromis = null;
                }

                if (angular.isString(this.mandatPremiereDate)) {
                    this.mandatPremiereDate = DateFormat.toDate(this.mandatPremiereDate);
                }

                if (angular.isString(this.mandatDate)) {
                    this.mandatDate = DateFormat.toDate(this.mandatDate);
                }

                if (angular.isString(this.mandatDateFin)) {
                    this.mandatDateFin = DateFormat.toDate(this.mandatDateFin);
                }

                if (angular.isString(this.mandatType)) {
                    this.mandatType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_MANDAT_TYPES, this.mandatType));
                } else {
                    this.mandatType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_MANDAT_TYPES, Ng2Vente.mandatTypes.NON_EXCLUSIF));
                }

                if (angular.isString(this.typeCharges)) {
                    this.typeCharges = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, this.typeCharges));
                } else {
                    this.typeCharges = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, Ng2Vente.chargesType.NET_VENDEUR));
                }

                if (angular.isString(this.dateDiffusion)) {
                    this.dateDiffusion = DateFormat.toDate(this.dateDiffusion);
                }

                if (angular.isString(this.interactiveSystemeEncheres)) {
                    this.interactiveSystemeEncheres = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_INTERACTIVE_ENCHERES_SYSTEMES, this.interactiveSystemeEncheres));
                } else {
                    this.interactiveSystemeEncheres = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_INTERACTIVE_ENCHERES_SYSTEMES, Ng2Vente.interactiveSystemeEncheres.ASCENDANTES));
                }

                if (angular.isString(this.interactiveDateFin)) {
                    this.interactiveDateFin = DateFormat.toDate(this.interactiveDateFin);
                }

                if (angular.isString(this.archiveType)) {
                    this.archiveType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, this.archiveType));
                }

                if (angular.isString(this.archiveRaisonVenteNegociee)) {
                    this.archiveRaisonVenteNegociee = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, this.archiveRaisonVenteNegociee));
                }

                if (angular.isString(this.archiveRaisonVenteNonNegociee)) {
                    this.archiveRaisonVenteNonNegociee = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, this.archiveRaisonVenteNonNegociee));
                }

                if (angular.isString(this.archiveDateVente)) {
                    this.archiveDateVente = DateFormat.toDate(this.archiveDateVente);
                }

                if (angular.isArray(this.viagerTetes) && this.viagerTetes.length > 0) {
                    let viagerTetes: VenteCreditRentier[] = [];

                    this.viagerTetes.map((viagerTete: IVenteCreditRentierApi) => viagerTetes.push(ng2VenteCreditRentierFactory.create(viagerTete)));
                    this.viagerTetes = viagerTetes;
                } else {
                    this.viagerTetes = [];
                }

                this.updateNbDaysBeforeAutoSendToNetwork();
                this.onChangeViagerNombreTetes();
                this.putNumberToString();

                return this;
            };

            /**
             * Sanitize on change type
             *
             * @returns {Estimation}
             */
            Vente.prototype.sanitizeOnChangeType = function () {
                if (this.type.code === Ng2Vente.types.VIAGER && !angular.isObject(this.viagerPeriodiciteRente)) {
                    this.viagerPeriodiciteRente = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.PERIODICITES, 1));
                }

                this.operatePricing();
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Vente.prototype.getFieldsChanged = function () {
                const fieldsChanged: { values: any[], message: string } = {values: [], message: ''};
                const self = this;
                const sanitizedVente = self.sanitize();

                if (!angular.isObject(self._esk.infosPricing)) self._esk.infosPricing = {};

                if (self.type.code === Ng2Vente.types.VIAGER) {
                    sanitizedVente.viagerBouquetHNI = self._esk.bouquetHNI;
                    self._esk.infosPricing.viagerIndexation = sanitizedVente.viagerIndexation;
                    self._esk.infosPricing.viagerNombreTetes = sanitizedVente.viagerNombreTetes;
                    self._esk.infosPricing.viagerPeriodiciteRente = self.viagerPeriodiciteRente;
                    self._esk.infosPricing.viagerRente = sanitizedVente.viagerRente;
                    self._esk.infosPricing.viagerValeurBien = sanitizedVente.viagerValeurBien;
                } else if (self.type.code === Ng2Vente.types.INTERACTIVE) {
                    self._esk.infosPricing.interactivePremiereOffre = sanitizedVente.interactivePremiereOffre;
                    self._esk.infosPricing.interactiveSystemeEncheres = self.interactiveSystemeEncheres;
                    self._esk.infosPricing.interactivePasOffre = sanitizedVente.interactivePasOffre;
                }

                self._esk.errorsSaving = {};
                if (!!self.mandatPremiereDate) {
                    if (JSON.stringify(self._esk.initialInfosPricing) !== JSON.stringify(self._esk.infosPricing)) {
                        const keysPricing = [
                            'honoraires',
                            'viagerIndexation',
                            'viagerNombreTetes',
                            'viagerRente',
                            'viagerValeurBien',
                            'interactivePremiereOffre',
                            'interactivePasOffre',
                        ];
                        const objectsPricing = ['typeCharge', 'interactiveSystemeEncheres'];

                        // Ajout des clés en fonction des charges
                        if (angular.isObject(self._esk.infosPricing.typeCharge) && self._esk.infosPricing.typeCharge.code === Ng2Vente.chargesType.HONO_NEGO_INCLUS) {
                            keysPricing.push('prixHNI');
                            keysPricing.push('prixReserveHNI');
                            keysPricing.push('bouquetHNI');
                            if (angular.isNumber(self._esk.infosPricing.viagerRente) && self._esk.infosPricing.viagerRente > 0) {
                                objectsPricing.push('viagerPeriodiciteRente');
                            }
                        } else {
                            keysPricing.push('prixNV');
                            keysPricing.push('prixReserveNV');
                            keysPricing.push('bouquetNV');
                        }

                        angular.forEach(self._esk.initialInfosPricing, function (value, key) {
                            // @ts-ignore
                            if ((keysPricing.includes(key) && self._esk.infosPricing[key] !== value) ||
                                // @ts-ignore
                                (objectsPricing.includes(key) && ((angular.isObject(self._esk.infosPricing[key]) !== angular.isObject(value)) || (angular.isObject(self._esk.infosPricing[key]) && angular.isObject(value) && self._esk.infosPricing[key].id !== value.id)))) {
                                fieldsChanged.values.push({
                                    key,
                                    oldValue: value,
                                    newValue: self._esk.infosPricing[key],
                                });
                            }
                        });
                    }
                }

                return fieldsChanged;
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Vente.prototype.save = function (testPriceModification = true) {
                const self = this;
                const sanitizedVente = self.sanitize();
                let promise = $q.resolve();

                if (!angular.isObject(self._esk.infosPricing)) self._esk.infosPricing = {};

                if (self.type.code === Ng2Vente.types.VIAGER) {
                    self._esk.infosPricing.viagerIndexation = sanitizedVente.viagerIndexation;
                    self._esk.infosPricing.viagerNombreTetes = sanitizedVente.viagerNombreTetes;
                    self._esk.infosPricing.viagerPeriodiciteRente = self.viagerPeriodiciteRente;
                    self._esk.infosPricing.viagerRente = sanitizedVente.viagerRente;
                    self._esk.infosPricing.viagerValeurBien = sanitizedVente.viagerValeurBien;
                } else if (self.type.code === Ng2Vente.types.INTERACTIVE) {
                    self._esk.infosPricing.interactivePremiereOffre = sanitizedVente.interactivePremiereOffre;
                    self._esk.infosPricing.interactiveSystemeEncheres = self.interactiveSystemeEncheres;
                    self._esk.infosPricing.interactivePasOffre = sanitizedVente.interactivePasOffre;
                }

                if (testPriceModification) {
                    const fieldsChanged = self.getFieldsChanged();

                    // Affichage du message
                    if (fieldsChanged.values.length > 0) {
                        for (var i = 0; i < fieldsChanged.values.length; i++) {
                            fieldsChanged.message += '- ';
                            switch (fieldsChanged.values[i].key) {
                                case 'typeCharge':
                                    fieldsChanged.message += "Type de charge : " + (angular.isObject(fieldsChanged.values[i].oldValue) ? fieldsChanged.values[i].oldValue.label : 'Non renseigné') + ' => ' + (angular.isObject(fieldsChanged.values[i].newValue) ? fieldsChanged.values[i].newValue.label : 'Non renseigné');
                                    break;
                                case 'prixNV':
                                    fieldsChanged.message += "Prix NV" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'prixHNI':
                                    fieldsChanged.message += "Prix HNI" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'prixReserveNV':
                                    fieldsChanged.message += "Prix de réserve NV" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'prixReserveHNI':
                                    fieldsChanged.message += "Prix de réserve HNI" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'bouquetNV':
                                    fieldsChanged.message += "Bouquet NV" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'bouquetHNI':
                                    fieldsChanged.message += "Bouquet HNI" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'honoraires':
                                    fieldsChanged.message += "Honoraires" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'interactivePremiereOffre':
                                    fieldsChanged.message += "Première offre" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'interactivePasOffre':
                                    fieldsChanged.message += "Pas de l'offre" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'interactiveSystemeEncheres':
                                    fieldsChanged.message += "Système d'enchères : " + (angular.isObject(fieldsChanged.values[i].oldValue) ? fieldsChanged.values[i].oldValue.label : 'Non renseigné') + ' => ' + (angular.isObject(fieldsChanged.values[i].newValue) ? fieldsChanged.values[i].newValue.label : 'Non renseigné');
                                    break;
                                case 'viagerPeriodiciteRente':
                                    fieldsChanged.message += "Périodicité de la rente : " + (angular.isObject(fieldsChanged.values[i].oldValue) ? fieldsChanged.values[i].oldValue.label : 'Non renseignée') + ' => ' + (angular.isObject(fieldsChanged.values[i].newValue) ? fieldsChanged.values[i].newValue.label : 'Non renseignée');
                                    break;
                                case 'viagerIndexation':
                                    fieldsChanged.message += "Indexation de la rente : " + (fieldsChanged.values[i].oldValue ? "Sélectionnée => Désélectionnée" : "Désélectionnée => Sélectionnée");
                                    break;
                                case 'viagerNombreTetes':
                                    fieldsChanged.message += "Nombre de têtes : " + (fieldsChanged.values[i].oldValue || 'Non renseigné') + " => " + (fieldsChanged.values[i].newValue || 'Non renseigné');
                                    break;
                                case 'viagerRente':
                                    fieldsChanged.message += "Rente" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                                case 'viagerValeurBien':
                                    fieldsChanged.message += "Valeur du bien" + getPriceMessage(fieldsChanged.values[i].oldValue, fieldsChanged.values[i].newValue);
                                    break;
                            }
                            if (i < fieldsChanged.values.length - 1) fieldsChanged.message += '<br>';
                        }

                        promise = soqSweetAlert.warningConfirmation($translate.instant('vente.price.change.TITLE', {
                            nbFields: fieldsChanged.values.length
                        }), $translate.instant('vente.price.change.MESSAGE', {
                            reference: self.bien.reference,
                            nbFields: fieldsChanged.values.length,
                            fields: fieldsChanged.message
                        }), {
                            input: 'checkbox',
                            customClass: {input: 'checkbox check-primary text-bold'},
                            inputPlaceholder: $translate.instant('vente.price.change.INPUT'),
                            inputValidator: function (result: unknown) {
                                return !result && $translate.instant('vente.price.change.OBLIGATION');
                            },
                            confirmButtonText: $translate.instant('vente.price.change.CONFIRMATION'),
                        });
                    }
                }

                return promise.then(function () {
                    var selfBien = angular.copy(self.bien);

                    return classResource.prototype.save.call(self).then(function () {
                        if (!angular.isObject(self.mandatPremiereDate) && angular.isDefined(self.mandatDate) && self.mandatDate !== null) {
                            self.mandatPremiereDate = self.mandatDate;
                        }

                        if (self.statut === Ng2Vente.statuts.BROUILLON && angular.isObject(self.mandatPremiereDate)) {
                            self.statut = Ng2Vente.statuts.MANDAT;
                        }

                        self.updateNbDaysBeforeAutoSendToNetwork();

                        return self.bien.save(selfBien).then(function () {
                            self._esk.initialInfosPricing = angular.copy(self._esk.infosPricing);
                            if (self._esk.setTitreDescriptif.need) {
                                return templateManager.setBienTitreDescriptif(self).then(() => self);
                            }

                            return self;
                        });
                    }).catch(function (rejection: HttpRejectionNg) {
                        if (rejection.status === 422 && angular.isObject(rejection.data) && angular.isArray(rejection.data.errors) && rejection.data.errors.length > 0) {
                            self._esk.errorsSaving = {
                                nbErrors: rejection.data.errors.length,
                                message: lmap(rejection.data.errors, 'message').join('<br>- '),
                                list: '<div class="row"><div class="col-lg-3 col-md-4 col-sm-6">- ' + lmap(rejection.data.errors, 'message').join('</div><div class="col-lg-3 col-md-4 col-sm-6">- ') + '</div></div>'
                            };
                        }

                        return $q.reject(rejection);
                    });
                }).then(_ => firstValueFrom(currentSource.asObservable())).then(currentVente => {
                    if (self.id === currentVente.id) {
                        currentSource.next(self);
                    }

                    return self;
                });

                /**
                 * Get message for price
                 *
                 * @param oldPrice
                 * @param newPrice
                 * @return {string}
                 */
                function getPriceMessage(oldPrice: number, newPrice: number) {
                    return ' : ' + (oldPrice || oldPrice === 0 ? (oldPrice + ' €') : 'Non renseigné') + ' => ' + (newPrice || newPrice === 0 ? (newPrice + ' €') : 'Non renseigné');
                }
            };

            /**
             * Sanitize object Vente before send to API
             *
             * @returns {Object}
             */
            Vente.prototype.sanitize = function () {
                let sanitizeObject: any;

                if (this.type?.code === Ng2Vente.types.INTERACTIVE && this.mandatType.code !== Ng2Vente.mandatTypes.EXCLUSIF) {
                    this.mandatType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_MANDAT_TYPES, Ng2Vente.mandatTypes.EXCLUSIF));
                }

                if (this.referencePublique) {
                    if (!this.referencePublique.startsWith(currentEtude.crpcen + '-')) {
                        this.referencePublique = currentEtude.crpcen + '-' + this.referencePublique;
                    }

                    if (this.referencePublique === currentEtude.crpcen + '-') {
                        this.referencePublique = null!;
                    }
                } else {
                    this.referencePublique = null!;
                }

                sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.compromis;
                delete sanitizeObject.passerelles;
                delete sanitizeObject.enabledCompromis;
                sanitizeObject.archiveDateVente = DateFormat.toAPI(this.archiveDateVente);
                sanitizeObject.bien = this.bien.sanitize();
                sanitizeObject.mandatDate = DateFormat.toAPI(this.mandatDate);
                sanitizeObject.mandatDateFin = DateFormat.toAPI(this.mandatDateFin);
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'type');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'mandatType');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'typeCharges');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'interactiveSystemeEncheres');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'archiveType');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'archiveRaisonVenteNegociee');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'archiveRaisonVenteNonNegociee');
                if (sanitizeObject.viagerRente && +(sanitizeObject.viagerRente.toString().replace(',', '.')) > 0) {
                    utilsManager.flattenIdForAttribute(sanitizeObject, 'viagerPeriodiciteRente');
                } else {
                    delete sanitizeObject.viagerRente;
                    delete sanitizeObject.viagerPeriodiciteRente;
                }

                sanitizeObject.viagerNombreTetes = this.viagerNombreTetes || null;
                if (angular.isArray(this.viagerTetes) && this.viagerTetes.length > 0) {
                    sanitizeObject.viagerTetes = [];
                    this.viagerTetes.map((viagerTete: VenteCreditRentier) => {
                        if (!viagerTete.isValid()) return;
                        sanitizeObject.viagerTetes.push(ng2VenteCreditRentierFactory.forApi(viagerTete))
                    });
                } else {
                    delete sanitizeObject.viagerTetes;
                }

                if (this.type.code !== Ng2Vente.types.INTERACTIVE) {
                    delete sanitizeObject.interactiveDateFin;
                    delete sanitizeObject.interactiveFraisPub;
                    delete sanitizeObject.interactivePasOffre;
                    delete sanitizeObject.interactivePremiereOffre;
                    delete sanitizeObject.interactivePrixReserve;
                    delete sanitizeObject.interactiveSystemeEncheres;
                }

                if (this.type.code !== Ng2Vente.types.SIMPLE) {
                    delete sanitizeObject.prixVente;
                }

                if (this.type.code !== Ng2Vente.types.VIAGER) {
                    delete sanitizeObject.viagerBouquet;
                    delete sanitizeObject.viagerIndexation;
                    delete sanitizeObject.viagerNombreTetes;
                    delete sanitizeObject.viagerPeriodiciteRente;
                    delete sanitizeObject.viagerRente;
                    delete sanitizeObject.viagerTetes;
                    delete sanitizeObject.viagerValeurBien;
                }

                if (!!this.estimationMax) {
                    sanitizeObject.estimationMax = +(this.estimationMax.toString().replace(',', '.'));
                } else {
                    sanitizeObject.estimationMax = null!;
                }

                if (!!this.estimationMin) {
                    sanitizeObject.estimationMin = +(this.estimationMin.toString().replace(',', '.'));
                } else {
                    sanitizeObject.estimationMin = null!;
                }

                if (!!this.fraisActe) {
                    sanitizeObject.fraisActe = +(this.fraisActe.toString().replace(',', '.'));
                } else {
                    sanitizeObject.fraisActe = null!;
                }

                if (!!this.honorairesNego) {
                    sanitizeObject.honorairesNego = +(this.honorairesNego.toString().replace(',', '.'));
                } else {
                    sanitizeObject.honorairesNego = null!;
                }

                if (!!this.interactiveFraisPub) {
                    sanitizeObject.interactiveFraisPub = +(this.interactiveFraisPub.toString().replace(',', '.'));
                } else {
                    sanitizeObject.interactiveFraisPub = null!;
                }

                if (!!this.interactivePasOffre) {
                    sanitizeObject.interactivePasOffre = +(this.interactivePasOffre.toString().replace(',', '.'));
                } else {
                    sanitizeObject.interactivePasOffre = null!;
                }

                if (!!this.interactivePremiereOffre) {
                    sanitizeObject.interactivePremiereOffre = +(this.interactivePremiereOffre.toString().replace(',', '.'));
                } else {
                    sanitizeObject.interactivePremiereOffre = null!;
                }

                if (!!this.interactivePrixReserve) {
                    sanitizeObject.interactivePrixReserve = +(this.interactivePrixReserve.toString().replace(',', '.'));
                } else {
                    sanitizeObject.interactivePrixReserve = null!;
                }

                if (!!this.prixVente) {
                    sanitizeObject.prixVente = +(this.prixVente.toString().replace(',', '.'));
                } else {
                    sanitizeObject.prixVente = null!;
                }

                if (!!this.viagerBouquet) {
                    sanitizeObject.viagerBouquet = +(this.viagerBouquet.toString().replace(',', '.'));
                } else {
                    sanitizeObject.viagerBouquet = null!;
                }

                if (!!this.viagerRente && +(this.viagerRente.toString().replace(',', '.')) > 0) {
                    sanitizeObject.viagerRente = +(this.viagerRente.toString().replace(',', '.'));
                } else {
                    delete sanitizeObject.viagerRente;
                }

                if (!!this.viagerValeurBien) {
                    sanitizeObject.viagerValeurBien = +(this.viagerValeurBien.toString().replace(',', '.'));
                } else {
                    sanitizeObject.viagerValeurBien = null!;
                }

                return sanitizeObject;
            };

            /**
             * Sanitize archived infos before send to API
             *
             * @returns {Object}
             */
            Vente.prototype.getSanitizeArchiveInfos = function () {
                return ng2VenteArchiveInfosFactory.forApi(ng2VenteArchiveInfosFactory.create(ng2VenteFactory.ngCreate(this)));
            };

            /**
             * Put number to string
             */
            Vente.prototype.putNumberToString = function () {
                this._esk.prixReserveHNI = this._esk.prixReserveHNI?.toString().replace('.', ',');
                this._esk.prixHNI = this._esk.prixHNI?.toString().replace('.', ',');
                this.estimationMax = this.estimationMax?.toString().replace('.', ',');
                this.estimationMin = this.estimationMin?.toString().replace('.', ',');
                this.fraisActe = this.fraisActe?.toString().replace('.', ',');
                this.honorairesNego = this.honorairesNego?.toString().replace('.', ',');
                this.interactiveFraisPub = this.interactiveFraisPub?.toString().replace('.', ',');
                this.interactivePasOffre = this.interactivePasOffre?.toString().replace('.', ',');
                this.interactivePremiereOffre = this.interactivePremiereOffre?.toString().replace('.', ',');
                this.interactivePrixReserve = this.interactivePrixReserve?.toString().replace('.', ',');
                this.prixVente = this.prixVente?.toString().replace('.', ',');
                this.viagerBouquet = this.viagerBouquet?.toString().replace('.', ',');
                this.viagerRente = this.viagerRente?.toString().replace('.', ',');
                this.viagerValeurBien = this.viagerValeurBien?.toString().replace('.', ',');
            };

            /**
             * Put string to number
             */
            Vente.prototype.putStringToNumber = function () {
                this._esk.bouquetHNI = !!this._esk.bouquetHNI ? +(this._esk.bouquetHNI?.toString().replace(',', '.')) : undefined;
                this._esk.prixReserveHNI = !!this._esk.prixReserveHNI ? +(this._esk.prixReserveHNI?.toString().replace(',', '.')) : undefined;
                this._esk.prixHNI = !!this._esk.prixHNI ? +(this._esk.prixHNI?.toString().replace(',', '.')) : undefined;
                this.estimationMax = !!this.estimationMax ? +(this.estimationMax?.toString().replace(',', '.')) : undefined;
                this.estimationMin = !!this.estimationMin ? +(this.estimationMin?.toString().replace(',', '.')) : undefined;
                this.fraisActe = !!this.fraisActe ? +(this.fraisActe.toString().replace(',', '.')) : undefined;
                this.honorairesNego = !!this.honorairesNego ? +(this.honorairesNego.toString().replace(',', '.')) : undefined;
                this.interactiveFraisPub = !!this.interactiveFraisPub ? +(this.interactiveFraisPub.toString().replace(',', '.')) : undefined;
                this.interactivePasOffre = !!this.interactivePasOffre ? +(this.interactivePasOffre.toString().replace(',', '.')) : undefined;
                this.interactivePremiereOffre = !!this.interactivePremiereOffre ? +(this.interactivePremiereOffre.toString().replace(',', '.')) : undefined;
                this.interactivePrixReserve = !!this.interactivePrixReserve ? +(this.interactivePrixReserve.toString().replace(',', '.')) : undefined;
                this.prixVente = !!this.prixVente ? +(this.prixVente.toString().replace(',', '.')) : undefined;
                this.viagerBouquet = !!this.viagerBouquet ? +(this.viagerBouquet.toString().replace(',', '.')) : undefined;
                this.viagerRente = !!this.viagerRente ? +(this.viagerRente.toString().replace(',', '.')) : undefined;
                this.viagerValeurBien = !!this.viagerValeurBien ? +(this.viagerValeurBien.toString().replace(',', '.')) : undefined;
            };

            /**
             * Operate all information related to the price
             *
             * @param getPrice
             * @param fromPrix
             */
            Vente.prototype.operatePricing = function (getPrice: boolean, fromPrix: boolean) {
                const self = this;
                const bouquetHNI = this._esk.bouquetHNI;
                const prixReserveHNI = this._esk.prixReserveHNI;
                const prixHNI = this._esk.prixHNI;
                const prixVente = this.prixVente;
                const viagerBouquet = this.viagerBouquet;

                return firstValueFrom(ng2UserService.getCurrentCommune$()).then(currentUserCommune => {
                    let promise: IPromise<any>;
                    var codeTypeCharges = self.typeCharges.code;
                    var price;
                    const commune = self.bien.option.commune || currentUserCommune;
                    let options = {
                        codeNature: self.bien.nature.code,
                        communeId: commune.id,
                        typePrix: self.typeCharges.code
                    };

                    self.putStringToNumber();
                    if (self.type.code === Ng2Vente.types.VIAGER) {
                        if (self.viagerBouquet > VentePriceFactory.MAX_PRICE) {
                            self.viagerBouquet = VentePriceFactory.MAX_PRICE;
                        }

                        if (self._esk.bouquetHNI > VentePriceFactory.MAX_PRICE) {
                            self._esk.bouquetHNI = VentePriceFactory.MAX_PRICE;
                        }

                        price = self.viagerValeurBien;
                        if (!angular.isNumber(self.viagerBouquet) || !isFinite(self.viagerBouquet) || self.viagerBouquet < 0) {
                            self.viagerBouquet = 0;
                        }

                        if (!angular.isNumber(self._esk.bouquetHNI) || !isFinite(self._esk.bouquetHNI) || self._esk.bouquetHNI < 0) {
                            self._esk.bouquetHNI = 0;
                        }

                        // @ts-ignore
                        options.valeurBien = self.viagerValeurBien;
                        // @ts-ignore
                        options.bouquet = (codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR) ? self.viagerBouquet : self._esk.bouquetHNI;
                    } else if (self.type.code === Ng2Vente.types.INTERACTIVE) {
                        if (self.interactivePrixReserve > VentePriceFactory.MAX_PRICE) {
                            self.interactivePrixReserve = VentePriceFactory.MAX_PRICE;
                        }

                        if (self._esk.prixReserveHNI > VentePriceFactory.MAX_PRICE) {
                            self._esk.prixReserveHNI = VentePriceFactory.MAX_PRICE;
                        }

                        if (!angular.isNumber(self.interactivePrixReserve) || !isFinite(self.interactivePrixReserve) || self.interactivePrixReserve < 0) {
                            self.interactivePrixReserve = 0;
                        }

                        if (!angular.isNumber(self._esk.prixReserveHNI) || !isFinite(self._esk.prixReserveHNI) || self._esk.prixReserveHNI < 0) {
                            self._esk.prixReserveHNI = 0;
                        }

                        price = (codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR || !getPrice) ? self.interactivePrixReserve : self._esk.prixReserveHNI;

                        // @ts-ignore
                        options.prixReserve = price;
                    } else {
                        if (self.prixVente > VentePriceFactory.MAX_PRICE) {
                            self.prixVente = VentePriceFactory.MAX_PRICE;
                        }

                        if (self._esk.prixHNI > VentePriceFactory.MAX_PRICE) {
                            self._esk.prixHNI = VentePriceFactory.MAX_PRICE;
                        }

                        price = (codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR || !getPrice) ? self.prixVente : self._esk.prixHNI;
                        // @ts-ignore
                        options.prix = price;
                    }

                    // Pas de prix défini, 0 par défaut pour les calculs qui suivent
                    if (!angular.isNumber(price) || !isFinite(price)) {
                        price = 0;
                    }
                    if (!angular.isNumber(self.honorairesNego) || !isFinite(self.honorairesNego)) {
                        self.honorairesNego = 0;
                    }
                    if (!angular.isNumber(self.fraisActe) || !isFinite(self.fraisActe)) {
                        self.fraisActe = 0;
                    }

                    if (getPrice === true && price > 0) {
                        // Récupération des données du prix par rapport à la formule de l'étude
                        if (!fromPrix) {
                            // @ts-ignore
                            options.honoraires = self.honorairesNego;
                            // @ts-ignore
                            options.fraisActe = self.fraisActe;
                        }

                        const ventePriceQueryParameters = {
                            codeNature: options.codeNature,
                            communeId: options.communeId,
                            // @ts-ignore
                            honoraires: options.honoraires,
                            // @ts-ignore
                            fraisActe: options.fraisActe,
                            typePrix: options.typePrix,
                        } as IVentePriceQueryParameters;

                        if (self.type.code === Ng2Vente.types.VIAGER) {
                            // @ts-ignore
                            ventePriceQueryParameters.bouquet = options.bouquet;
                            // @ts-ignore
                            ventePriceQueryParameters.valeurBien = options.valeurBien;
                        } else if (self.type.code === Ng2Vente.types.INTERACTIVE) {
                            // @ts-ignore
                            ventePriceQueryParameters.prixReserve = options.prixReserve;
                        } else {
                            // @ts-ignore
                            ventePriceQueryParameters.prix = options.prix;
                        }

                        promise = firstValueFrom(ng2VentePriceFactory.get$(self.type.code, ventePriceQueryParameters).pipe(
                            map(ventePrice => ({
                                fraisActe: ventePrice.fraisActe,
                                honoraires: ventePrice.honoraires,
                                pourcentageHonoraires: ventePrice.pourcentageHonoraires,
                                bouquetNV: ventePrice.priceNV,
                                bouquetHNI: ventePrice.priceHNI,
                                bouquetTFC: ventePrice.priceTFC,
                                prixNV: ventePrice.priceNV,
                                prixHNI: ventePrice.priceHNI,
                                prixTFC: ventePrice.priceTFC,
                                prixReserveNV: ventePrice.priceNV,
                                prixReserveHNI: ventePrice.priceHNI,
                                prixReserveTFC: ventePrice.priceTFC,
                            }))
                        ));
                    } else {
                        // Cas où l'utilisateur saisit 0 pour le prix
                        if (angular.isObject(self._esk.infosPricing) && price === 0) {
                            self.prixVente = 0;
                            self.interactivePrixReserve = 0;
                            self.viagerValeurBien = 0;
                            self.honorairesNego = 0;
                            self.fraisActe = 0;
                        }

                        // Création de l'objet infosPricing
                        if (self.type.code === Ng2Vente.types.VIAGER) {
                            self._esk.bouquetHNI = (codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR || !getPrice) ? +((self.viagerBouquet + self.honorairesNego).toFixed(2)) : self._esk.bouquetHNI;
                            promise = $q.resolve({
                                bouquetNV: +self.viagerBouquet.toFixed(2),
                                honoraires: +self.honorairesNego.toFixed(2),
                                fraisActe: +self.fraisActe.toFixed(),
                                bouquetHNI: self._esk.bouquetHNI,
                                pourcentageHonoraires: +(((self.viagerValeurBien === 0 ? 0 : (self.honorairesNego / self.viagerValeurBien)) * 100).toFixed(2)),
                                bouquetTFC: +(self.viagerBouquet + self.honorairesNego + +self.fraisActe.toFixed()).toFixed(2),
                            });
                        } else if (self.type.code === Ng2Vente.types.INTERACTIVE) {
                            self._esk.prixReserveHNI = (codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR || !getPrice) ? +((self.interactivePrixReserve + self.honorairesNego).toFixed(2)) : self._esk.prixReserveHNI;
                            promise = $q.resolve({
                                prixReserveNV: +self.interactivePrixReserve.toFixed(2),
                                honoraires: +self.honorairesNego.toFixed(2),
                                fraisActe: +self.fraisActe.toFixed(),
                                prixReserveHNI: self._esk.prixReserveHNI,
                                pourcentageHonoraires: +(((self.interactivePrixReserve === 0 ? 0 : (self.honorairesNego / self.interactivePrixReserve)) * 100).toFixed(2)),
                                prixReserveTFC: +(self.interactivePrixReserve + self.honorairesNego + +self.fraisActe.toFixed()).toFixed(2),
                            });
                        } else {
                            self._esk.prixHNI = +(price + self.honorairesNego).toFixed(2);
                            promise = $q.resolve({
                                prixNV: +price.toFixed(2),
                                honoraires: +self.honorairesNego.toFixed(2),
                                fraisActe: +self.fraisActe.toFixed(),
                                prixHNI: self._esk.prixHNI,
                                pourcentageHonoraires: +(((price === 0 ? 0 : (self.honorairesNego / price)) * 100).toFixed(2)),
                                prixTFC: +(price + self.honorairesNego + +self.fraisActe.toFixed()).toFixed(2),
                            });
                        }
                    }

                    return promise.then(function (infosPricing) {
                        self.putStringToNumber();
                        // Cas où la réponse est arrivée après une nouvelle modification
                        if ((self.type.code === Ng2Vente.types.SIMPLE && ((codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR && self.prixVente !== infosPricing.prixNV) || (codeTypeCharges === Ng2Vente.chargesType.HONO_NEGO_INCLUS && self._esk.prixHNI !== infosPricing.prixHNI)))) {
                            return;
                        }
                        // @ts-ignore
                        if ((self.type.code === Ng2Vente.types.VIAGER && (self.viagerValeurBien !== options.valeurBien || (codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR && self.viagerBouquet !== infosPricing.bouquetNV) || (codeTypeCharges === Ng2Vente.chargesType.HONO_NEGO_INCLUS && self._esk.bouquetHNI !== infosPricing.bouquetHNI)))) {
                            if (self.viagerBouquet) {
                                return;
                            }
                        }
                        // @ts-ignore
                        if ((self.type.code === Ng2Vente.types.INTERACTIVE && ((codeTypeCharges === Ng2Vente.chargesType.NET_VENDEUR && self.interactivePrixReserve !== infosPricing.prixReserveNV) || (codeTypeCharges === Ng2Vente.chargesType.HONO_NEGO_INCLUS && self._esk.prixReserveHNI !== infosPricing.prixReserveHNI)))) {
                            return;
                        }

                        // Actuellement l'API ne prend pas en compte les valeurs négatives pour ces attributs
                        if (infosPricing.prixNV < 0) {
                            infosPricing.prixNV = 0;
                        }
                        if (infosPricing.bouquetNV < 0) {
                            infosPricing.bouquetNV = 0;
                        }
                        if (infosPricing.prixReserveNV < 0) {
                            infosPricing.prixReserveNV = 0;
                        }

                        // Saisie des informations de prix
                        self._esk.infosPricing = infosPricing;
                        self._esk.infosPricing.typeCharge = self.typeCharges;
                        switch (self.type.code + ":" + codeTypeCharges) {
                            case Ng2Vente.types.SIMPLE + ':' + Ng2Vente.chargesType.NET_VENDEUR:
                                self._esk.prixHNI = self._esk.infosPricing.prixHNI;
                                self.prixAffiche = self._esk.infosPricing.prixNV;
                                break;
                            case Ng2Vente.types.SIMPLE + ':' + Ng2Vente.chargesType.HONO_NEGO_INCLUS:
                                self.prixVente = self._esk.infosPricing.prixNV;
                                self.prixAffiche = self._esk.prixHNI;
                                break;
                            case Ng2Vente.types.VIAGER + ':' + Ng2Vente.chargesType.NET_VENDEUR:
                                self._esk.bouquetHNI = self._esk.infosPricing.bouquetHNI;
                                self.prixAffiche = self._esk.infosPricing.bouquetNV;
                                break;
                            case Ng2Vente.types.VIAGER + ':' + Ng2Vente.chargesType.HONO_NEGO_INCLUS:
                                self.viagerBouquet = self._esk.infosPricing.bouquetNV;
                                self.prixAffiche = self._esk.bouquetHNI;
                                break;
                            case Ng2Vente.types.INTERACTIVE + ':' + Ng2Vente.chargesType.NET_VENDEUR:
                                self._esk.prixReserveHNI = self._esk.infosPricing.prixReserveHNI;
                                self.prixAffiche = self.interactivePremiereOffre;
                                break;
                            case Ng2Vente.types.INTERACTIVE + ':' + Ng2Vente.chargesType.HONO_NEGO_INCLUS:
                                self.interactivePrixReserve = self._esk.infosPricing.prixReserveNV;
                                self.prixAffiche = self.interactivePremiereOffre;
                                break;
                        }

                        // Saisie des informations pour un viager
                        if (self.type.code === Ng2Vente.types.VIAGER) {
                            self._esk.infosPricing.viagerIndexation = self.viagerIndexation;
                            self._esk.infosPricing.viagerNombreTetes = self.viagerNombreTetes;
                            self._esk.infosPricing.viagerPeriodiciteRente = self.viagerPeriodiciteRente;
                            self._esk.infosPricing.viagerRente = self.viagerRente;
                            self._esk.infosPricing.viagerValeurBien = self.viagerValeurBien;
                        }

                        // Saisie des informations pour une interactive
                        if (self.type.code === Ng2Vente.types.INTERACTIVE) {
                            self._esk.infosPricing.interactivePremiereOffre = self.interactivePremiereOffre;
                            self._esk.infosPricing.interactiveSystemeEncheres = self.interactiveSystemeEncheres;
                            self._esk.infosPricing.interactivePasOffre = self.interactivePasOffre;
                        }

                        // Saisie des honoraires si besoin
                        if (fromPrix) {
                            self.honorairesNego = self._esk.infosPricing.honoraires;
                            self.fraisActe = self._esk.infosPricing.fraisActe;
                        } else {
                            self._esk.infosPricing.honoraires = self.honorairesNego;
                            self._esk.infosPricing.fraisActe = self.fraisActe;
                        }
                    }).then(() => this.putNumberToString()).then(() => {
                        if (bouquetHNI) {
                            if (this._esk.bouquetHNI !== bouquetHNI && +(this._esk.bouquetHNI?.toString().replace(',', '.')) === +(bouquetHNI?.toString().replace(',', '.'))) {
                                this._esk.bouquetHNI = bouquetHNI;
                            }
                        }

                        if (prixReserveHNI) {
                            if (this._esk.prixReserveHNI !== prixReserveHNI && +(this._esk.prixReserveHNI?.toString().replace(',', '.')) === +(prixReserveHNI?.toString().replace(',', '.'))) {
                                this._esk.prixReserveHNI = prixReserveHNI;
                            }
                        }

                        if (prixHNI) {
                            if (this._esk.prixHNI !== prixHNI && +(this._esk.prixHNI?.toString().replace(',', '.')) === +(prixHNI?.toString().replace(',', '.'))) {
                                this._esk.prixHNI = prixHNI;
                            }
                        }

                        if (prixVente) {
                            if (this.prixVente !== prixVente && +(this.prixVente?.toString().replace(',', '.')) === +(prixVente?.toString().replace(',', '.'))) {
                                this.prixVente = prixVente;
                            }
                        }

                        if (viagerBouquet) {
                            if (this.viagerBouquet !== viagerBouquet && +(this.viagerBouquet?.toString().replace(',', '.')) === +(viagerBouquet?.toString().replace(',', '.'))) {
                                this.viagerBouquet = viagerBouquet;
                            }
                        }
                    });
                });
            };

            /**
             * Say if Mandat is editable or not
             *
             * @return boolean
             */
            Vente.prototype.canEditMandat = function () {
                const hasFieldsInvalid = bienDossierCurrent.hasFieldsInvalid(undefined!, true);
                const sanitizedVente = this.sanitize();

                this._esk.fieldsInvalid = [];
                if (hasFieldsInvalid) {
                    this._esk.fieldsInvalid = bienFieldsConstraints.getFieldsInvalid();
                }

                return angular.isObject(this.mandatPremiereDate) || (
                    angular.isObject(this.bien.option.commune)
                    && angular.isNumber(sanitizedVente.bien.superficie) && sanitizedVente.bien.superficie > 0
                    && angular.isString(this.bien.titre) && this.bien.titre !== ''
                    && angular.isString(this.bien.descriptif) && this.bien.descriptif !== ''
                    && this.bien.hasSavedCadastres()
                    && (
                        (this.type.code === Ng2Vente.types.SIMPLE && angular.isNumber(sanitizedVente.prixVente) && sanitizedVente.prixVente > 0)
                        || (this.type.code === Ng2Vente.types.VIAGER
                            && angular.isNumber(sanitizedVente.viagerValeurBien) && sanitizedVente.viagerValeurBien >= 0
                            && (
                                (angular.isNumber(sanitizedVente.viagerBouquet) && sanitizedVente.viagerBouquet > 0)
                                || (angular.isNumber(sanitizedVente.viagerRente) && sanitizedVente.viagerRente > 0)
                            )
                        )
                        || (this.type.code === Ng2Vente.types.INTERACTIVE
                            && angular.isNumber(sanitizedVente.interactivePrixReserve) && sanitizedVente.interactivePrixReserve > 0
                            && angular.isNumber(sanitizedVente.interactivePremiereOffre) && sanitizedVente.interactivePremiereOffre > 0
                            && angular.isObject(this.interactiveSystemeEncheres)
                            && angular.isNumber(sanitizedVente.interactivePasOffre) && sanitizedVente.interactivePasOffre > 0
                            && this.isInteractiveDateFinValid()
                        )
                    )
                    && !hasFieldsInvalid
                );
            };

            /**
             * Is interactiveDateFin valid
             *
             * @returns {Boolean}
             */
            Vente.prototype.isInteractiveDateFinValid = function () {
                return angular.isObject(this.interactiveDateFin) && !DateFormat.isNowOlder(this.interactiveDateFin, {returnUnit: DateFormat.DAYS, withoutTime: true});
            };

            /**
             * Add new compromis
             *
             * @param newVenteCompromis
             * @returns {Promise}
             */
            Vente.prototype.addCompromis = function (newVenteCompromis: VenteCompromis): IPromise<VenteCompromis> {
                const vente = this;

                return firstValueFrom(ng2VenteCompromisFactory.save$(vente.id, newVenteCompromis).pipe(
                    switchMap(venteCompromis => {
                        return from(venteCompromisManager.getAll(vente.id).then(venteCompromisList => {
                            vente.compromis = venteCompromisList.collection;
                            vente.enabledCompromis = vente.compromis[vente.compromis.length - 1];
                            vente.statut = Ng2Vente.statuts.COMPROMIS;
                        })).pipe(map(_ => venteCompromis));
                    }),
                ));
            };

            /**
             * Disable enabled compromis
             *
             * @returns {Promise}
             */
            Vente.prototype.disableCompromis = function (): IPromise<void> {
                let self = this;
                let enabledCompromis: VenteCompromis = self.enabledCompromis instanceof VenteCompromis ? self.enabledCompromis : ng2VenteCompromisFactory.ngCreate(self.enabledCompromis)

                enabledCompromis.enabled = false;

                return firstValueFrom(ng2VenteCompromisFactory.save$(self.id.toString(), enabledCompromis)).then(() => {
                    self.enabledCompromis = null;
                    self.statut = !!self.dateDiffusion ? Ng2Vente.statuts.DIFFUSE : !!self.mandatPremiereDate ? Ng2Vente.statuts.MANDAT : Ng2Vente.statuts.BROUILLON;
                });
            };

            /**
             * Get information for archive
             *
             * @returns {Observable}
             */
            Vente.prototype.getArchiveInfos = function (): Observable<VenteArchiveInfos> {
                const vente = ng2VenteFactory.ngCreate(this);
                const venteArchiveInfos = ng2VenteArchiveInfosFactory.create(vente);
                let myObs$ = of(undefined);

                if (vente.interne) {
                    // @ts-ignore
                    myObs$ = ng2EtudeService.last$.pipe(map(currentEtude => venteArchiveInfos.reseau = currentEtude.mainNetwork))
                }

                return myObs$.pipe(map(_ => venteArchiveInfos));
            };

            /**
             * Set venteArchiveInfos information
             *
             * @param venteArchiveInfos
             */
            Vente.prototype.setArchiveInfos = function (venteArchiveInfos: VenteArchiveInfos) {
                this.archiveAcquereur = venteArchiveInfos.acquereur;
                this.archiveComments = venteArchiveInfos.comments;
                this.archiveDateVente = venteArchiveInfos.dateVente;
                this.archiveDemandeurId = venteArchiveInfos.demandeurId;
                this.archiveEtudeAcquereurId = venteArchiveInfos.etudeAcquereurId;
                this.archiveHonorairesNego = venteArchiveInfos.honorairesNego;
                this.archiveHonorairesNegoPercus = venteArchiveInfos.honorairesNegoPercus;
                this.linkArchiveDemandeur = venteArchiveInfos.linkDemandeur;
                this.linkArchiveEtudeAcquereur = venteArchiveInfos.linkEtudeAcquereur;
                this._links.archiveDemandeur = {href: venteArchiveInfos.linkDemandeur};
                this._links.archiveEtudeAcquereur = {href: venteArchiveInfos.linkEtudeAcquereur};
                this.archivePrixCession = venteArchiveInfos.prixCession;
                this.archiveRaisonVenteNegociee = angular.isObject(venteArchiveInfos.raisonVenteNegociee) ? dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, venteArchiveInfos.raisonVenteNegociee.code)) : undefined;
                this.archiveRaisonVenteNonNegociee = angular.isObject(venteArchiveInfos.raisonVenteNonNegociee) ? dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, venteArchiveInfos.raisonVenteNonNegociee.code)) : undefined;
                this.archiveType = angular.isObject(venteArchiveInfos.type) ? dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, venteArchiveInfos.type.code)) : undefined;
            };

            /**
             * Save venteArchiveInfos information
             *
             * @param venteArchiveInfos
             * @returns {Promise}
             */
            Vente.prototype.saveArchiveInfos = function (venteArchiveInfos: VenteArchiveInfos): IPromise<NgVente> {
                this.setArchiveInfos(venteArchiveInfos);

                return this.save();
            };

            /**
             * Archive Vente
             *
             * @param venteArchiveInfos
             * @returns {Promise}
             */
            Vente.prototype.archive = function (venteArchiveInfos: VenteArchiveInfos): IPromise<void> {
                this.setArchiveInfos(venteArchiveInfos);

                return this.patch('archive', this.getSanitizeArchiveInfos());
            };

            /**
             * Create Location from Vente
             *
             * @returns {Promise}
             */
            Vente.prototype.createEstimation = function (): IPromise<NgEstimation> {
                return this.patch('create-estimation');
            };

            /**
             * Create Location from Vente
             *
             * @returns {Promise}
             */
            Vente.prototype.createLocation = function (): IPromise<NgLocation> {
                return this.patch('create-location');
            };

            /**
             * Update number of days before auto send to network
             */
            Vente.prototype.updateNbDaysBeforeAutoSendToNetwork = function () {
                ng2EtudeService.last$.subscribe(currentEtude => {
                    const nbDays = currentEtude.minNumberDaysBeforeAutoDiffusion;

                    // Pas de date de mandat ou pas de réseaux paramétrés
                    if (!angular.isObject(this.mandatPremiereDate) || nbDays === null || ![Ng2Vente.statuts.BROUILLON, Ng2Vente.statuts.MANDAT].includes(this.statut)) {
                        this._esk.nbDaysBeforeAutoSendToNetwork = null;
                    } else {
                        const mandatPremiereDateWithAddedDays = DateFormat.addDaysToDateString(angular.copy(this.mandatPremiereDate), nbDays);

                        this._esk.nbDaysBeforeAutoSendToNetwork = DateFormat.diff(mandatPremiereDateWithAddedDays, undefined, {returnUnit: DateFormat.DAYS});
                    }
                });
            };

            /**
             * Diffuse Vente
             *
             * @returns {Promise}
             */
            Vente.prototype.diffuse = function () {
                const self = this;

                return self.patch('diffuse').then(function () {
                    self.dateDiffusion = DateFormat.toDate();
                    self.statut = Ng2Vente.statuts.DIFFUSE;
                    self.updateNbDaysBeforeAutoSendToNetwork();
                });
            };

            /**
             * Clone vente
             *
             * @param keepOwners
             * @returns {Promise}
             */
            Vente.prototype.clone = function (keepOwners: boolean): IPromise<NgVente> {
                return this.patch('clone', {keepProprietaires: Boolean(keepOwners) === true}, {uuid: this.uuid});
            };

            /**
             * Change viagerNombreTetes
             */
            Vente.prototype.onChangeViagerNombreTetes = function () {
                const viagerNombreTetes = this.viagerNombreTetes ?? 0;

                while (viagerNombreTetes >= 0 && viagerNombreTetes !== this.viagerTetes.length) {
                    if (viagerNombreTetes < this.viagerTetes.length) {
                        this.viagerTetes.pop();
                    } else if (viagerNombreTetes > this.viagerTetes.length) {
                        this.viagerTetes.push(ng2VenteCreditRentierFactory.createDefault());
                    }
                }
            };

            /**
             * Get diffusions
             *
             * @returns {*}
             */
            Vente.prototype.getDiffusions = function () {
                const self = this;

                return firstValueFrom(ng2EtudeService.last$).then(currenEtude => {
                    return ventePasserelleManager.getAll(self.id).then(function (ventePasserelles: NgVentePasserellesCollection) {
                        const etudePasserelles = currenEtude.passerelles;

                        self.passerelles = ventePasserelles.collection;
                        angular.forEach(etudePasserelles, function (etudePasserelle: EtudePasserelle) {
                            var natureNormalizer;
                            var typePrixNormalizer;
                            let ventePasserelle = find(self.passerelles, {passerelle: {id: etudePasserelle.passerelle.id}});

                            if (!angular.isObject(ventePasserelle)) {
                                natureNormalizer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getNatureNormalizer(etudePasserelle.passerelle.normalizer, self.bien._esk.type, self.bien.nature.code, self.bien.option.nombrePieces));
                                typePrixNormalizer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode('types-prix-' + etudePasserelle.passerelle.normalizer, self.typeCharges.code));
                                ventePasserelle = ventePasserelleManager.create({
                                    passerelle: etudePasserelle.passerelle,
                                    natureExternalType: etudePasserelle.passerelle.normalizer,
                                    natureExternalTypeId: angular.isObject(natureNormalizer) ? natureNormalizer?.id : null,
                                    typePrixExternalType: etudePasserelle.passerelle.normalizer,
                                    typePrixExternalTypeId: angular.isObject(typePrixNormalizer) ? typePrixNormalizer.id : null,
                                    photos: (function () {
                                        const photos: NgBienPhotoPasserelle[] = [];

                                        angular.forEach(self.bien.photos, function (bienPhoto: NgBienPhoto) {
                                            photos.push({
                                                _links: bienPhoto._links,
                                                bienId: self.id,
                                                photoId: bienPhoto.photo.id,
                                                diffused: true,
                                                photo: bienPhoto.photo,
                                            } as NgBienPhotoPasserelle);
                                        });

                                        return photos;
                                    })(),
                                }, self.id);
                                ventePasserelle.id = false;
                                self.passerelles.push(ventePasserelle);
                            }
                        });

                        return self.passerelles;
                    });
                });
            };

            // @ts-ignore
            return new VenteManager();
        }
    })(angularJS);
}
