class NoteoStatutColor {
    static readonly BLUE = 'blue';
    static readonly GREEN = 'green';
    static readonly ORANGE = 'orange';
    static readonly RED = 'red';
    static readonly ROSE = 'rose';
    static readonly STONE = 'stone';
}

// @todo Ajouter ici les constantes de noms comme par exemple dans AppNoteoIconTextNotyComponent.label
// @todo Rechercher ces valeurs et utiliser cette constante
export const NoteoSepteoConst = {code: 'septeo', label: 'Septeo', srcImage: 'assets/images/septeo/logo.svg'};
export const NoteoStatutColorConst = NoteoStatutColor;
export type NoteoStatutColorType = 'blue' | 'green' | 'orange' | 'red' | 'rose' | 'stone';
