import {inject, Injectable} from '@angular/core';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Passerelle from '@models/passerelle/passerelle.model';
import Bien from '@models/bien/bien.model';
import {DossierBienType, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DictionaryItemService {
    static readonly superficieLabel = {
        [Bien.natures.APPARTEMENT]: 'Surface habitable',
        [Bien.natures.AUTRE_TERRAIN]: 'Surface terrain',
        [Bien.natures.BUREAUX]: 'Surface utile',
        DEFAULT: 'Surface',
        [Bien.natures.IMMEUBLE]: 'Surface habitable',
        [Bien.natures.LOCAUX_COMMERCIAUX]: 'Surface utile',
        [Bien.natures.MAISON]: 'Surface habitable',
        [Bien.natures.TERRAIN_A_BATIR]: 'Surface terrain',
    };

    private _dictionaryFactory = inject(DictionaryFactory);

    getByCode(dictionaryName: string, code: string, withDeprecated = false): DictionaryItem {
        const dictionary = this._dictionaryFactory.getByName(dictionaryName, withDeprecated);

        return dictionary.getItemByCode(code);
    }

    getByCodes(dictionaryName: string, code: string): DictionaryItem {
        const dictionary = this._dictionaryFactory.getByName(dictionaryName);

        return dictionary.getItemByCodes(code);
    }

    getById(dictionaryName: string, id: number): DictionaryItem {
        const dictionary = this._dictionaryFactory.getByName(dictionaryName);

        return dictionary.getItemById(id);
    }

    getEpoqueFromAnnee(anneeConstruction: number): DictionaryItem {
        const dictionaryEpoquesConstruction = this._dictionaryFactory.getByName(Dictionary.names.CONSTRUCTION_EPOQUES, true);
        let epoqueConstruction = dictionaryEpoquesConstruction.items[0];

        if (anneeConstruction) {
            dictionaryEpoquesConstruction.items
                .filter(itemEpoqueConstruction => !itemEpoqueConstruction.anneeMin || itemEpoqueConstruction.anneeMin <= anneeConstruction)
                .filter(itemEpoqueConstruction => !itemEpoqueConstruction.anneeMax || anneeConstruction <= itemEpoqueConstruction.anneeMax)
                .forEach(itemEpoqueConstruction => epoqueConstruction = itemEpoqueConstruction);
        }

        return epoqueConstruction;
    }

    getNatureNormalizer(normalizer: string, dossierBienType: DossierBienType, codeNature: string, nombrePieces = 0): DictionaryItem {
        const natureNormalizer = 'natures-' + normalizer;

        if (normalizer === Passerelle.normalizers.PRECOM && codeNature === Bien.natures.APPARTEMENT) {
            if (dossierBienType === DossierBienTypesConst.LOCATION) {
                return this.getById(natureNormalizer, 43);
            } else if (nombrePieces === 1) {
                return this.getById(natureNormalizer, 11);
            } else if (nombrePieces === 2) {
                return this.getById(natureNormalizer, 12);
            } else if (nombrePieces === 3) {
                return this.getById(natureNormalizer, 13);
            } else if (nombrePieces === 4) {
                return this.getById(natureNormalizer, 14);
            } else if (nombrePieces === 5) {
                return this.getById(natureNormalizer, 15);
            } else if (nombrePieces === 6) {
                return this.getById(natureNormalizer, 16);
            } else {
                return this.getById(natureNormalizer, 17);
            }
        }

        return this.getByCodes(natureNormalizer, dossierBienType.toUpperCase() + '_' + codeNature);
    }

    // @todo Créer AppDictionaryEpoqueConstructionLabelComponent et voir ce qui peut être factorisé
    getOneSuperficieLabel(nature: DictionaryItem): string {
        return DictionaryItemService.superficieLabel[nature?.code] || DictionaryItemService.superficieLabel.DEFAULT;
    }

    getOneSuperficieLabelById(id: number): string {
        return this.getOneSuperficieLabel(this.getById(Dictionary.names.NATURES, id));
    }
}
