import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, from, interval, Observable, of, Subject} from 'rxjs';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {ModalService} from '@shared/modal/modal.service';
import {NgContact} from '@legacy/app/managers/ressources';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import Contact from '@models/contacts/contact/contact.model';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactManager} from '@legacy/app/managers/managers';
import {
    CDossierFileContactsService
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.service';
import {NgContactsEmailAddress} from '@legacy/app/contacts/contacts';

@Injectable({providedIn: 'root'})
export class ContactsService {
    private _cDossierFileContactsService = inject(CDossierFileContactsService);
    private _contactFactory = inject(ContactFactory);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _nbInvalidSource = new BehaviorSubject<number>(0);
    private _nbInvalid$ = this._nbInvalidSource.asObservable();
    private readonly _stopNbInvalid$ = new Subject<void>();

    get nbInvalid$(): Observable<number> {
        return this._nbInvalid$;
    }

    // Supprimer les injections des anciens manager
    private get ngContactManager(): NgContactManager {
        return this._ngInjectorService.getService('ContactManager');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsEmailAddress(): NgContactsEmailAddress {
        return this._ngInjectorService.getService('ContactsEmailAddress');
    }

    initNbInvalid$(): void {
        let previousNbInvalid: number;

        this._stopNbInvalid$.next();
        this.ngContactsEmailAddress.stopRefreshInvalid();
        this.ngContactsEmailAddress.launchRefreshInvalid();
        interval(1000).pipe(
            switchMap(_ => {
                const nbInvalid = this.ngContactsEmailAddress.getNbInvalid();

                if (previousNbInvalid === nbInvalid) {
                    return of(undefined);
                }

                previousNbInvalid = nbInvalid;

                return of(previousNbInvalid);
            }),
            filter(nbInvalid => !!nbInvalid),
            map(nbInvalid    => nbInvalid as number),
            takeUntil(this._stopNbInvalid$),
        ).subscribe(nbInvalid => this._nbInvalidSource.next(nbInvalid));
    }

    selectFromDossierFiles$(dossierFilesToShare: DossierFile[] = []): Observable<Contact[]> {
        if (dossierFilesToShare.length <= 0) {
            return of(undefined as unknown as Contact[]);
        }

        return combineLatest(dossierFilesToShare.map(dossierFileToShare =>
            this._cDossierFileContactsService.getContactsByLink$(dossierFileToShare.linkContacts, {share: true}).pipe(
                switchMap(contacts => {
                    if (contacts?.length > 0) {
                        return combineLatest(contacts.map(contact => from(this.ngContactManager.createFromNg2(contact))));
                    }

                    return of([]);
                }),
                map(ngContacts => ({contacts: ngContacts})),
            ))).pipe(
            switchMap(data => this._modalService.ngOpen$<NgContact[]>('BienModalShareDocuments', {resolve: {documents: () => data}})),
            map(ngContacts => {
                if (!ngContacts) {
                    return undefined!;
                }

                if (ngContacts.length <= 0) {
                    return [];
                }

                return ngContacts.map(ngContact => this._contactFactory.ngCreate(ngContact));
            }),
        );
    }
}
