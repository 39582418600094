export default `
<div class="container-fluid container-fluid-title">
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-5 labels-line-height-25">
      <h3 class="text-bold ellipsis">
        <span ng-if="$ctrl.dossier.bien._esk.interne">{{$ctrl.dossier.bien.contactsGroup.nom}}</span>
      </h3>

      <div class="header-labels ellipsis"
           ng-if="$ctrl.dossier._esk.typeDossier !== $ctrl.DossierBienTypesConst.ESTIMATION || $ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE">
        <div class="header-labels-main">
          <span class="text-bold text-right label-reference"> {{$ctrl.dossier.bien.reference}}</span>

          <span>
            <app-vente-price ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE"
                             [ng-vente]="$ctrl.dossier" [options]="{enabledNV: false}"
                             class="tw-inline-block tw-text-xs"></app-vente-price>

            <esk-location-label-rent ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION"
                                     location="$ctrl.dossier"></esk-location-label-rent>

            <esk-estimation-label-valeur ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION"
                                         estimation="$ctrl.dossier"></esk-estimation-label-valeur>
          </span>

          <span class="label label-nature hidden-xs">{{$ctrl.dossier.bien.nature.label}}</span>
        </div>

        <div popover-trigger="'outsideClick'" popover-placement="bottom"
          ng-if="$ctrl.dossier.bien.option.nomVoie || $ctrl.dossier.bien.option.commune || $ctrl.dossier.bien.option.quartier.name"
          uib-popover-template="'src/app/legacy/templates/biens/components/item/header/localisation.html'"
          ng-mouseenter="$ctrl.dossier._esk.headerPopoverIsOpen = true"
          ng-mouseleave="$ctrl.dossier._esk.headerPopoverIsOpen = false"
          popover-is-open="$ctrl.dossier._esk.headerPopoverIsOpen" class="header-labels-localisation">
          <span class="label label-localisation">
            <span ng-if="$ctrl.dossier.bien.option.commune">{{$ctrl.dossier.bien.option.commune.fullname}}</span>

            <span ng-if="$ctrl.dossier.bien.option.quartier.name" class="hidden-xs">
              - {{$ctrl.dossier.bien.option.quartier.name}}
            </span>

            <span ng-if="$ctrl.dossier.bien.option.commune && $ctrl.dossier.bien.option.nomVoie"
                  class="hidden-xs hidden-sm">
              -
            </span>

            <span ng-if="$ctrl.dossier.bien.option.nomVoie" class="hidden-xs hidden-sm">
              {{$ctrl.dossier.bien.option.nomVoie}}
            </span>
          </span>
        </div>
      </div>

      <span ng-if="$ctrl.dossier.statut === $ctrl.STATUT_ARCHIVE"
            class="text-warning text-large text-bold">Dossier archivé</span>
    </div>

    <div ng-if="$ctrl.dossier.id && $ctrl.dossier.bien._esk.interne" class="col-xs-12 col-md-6 col-lg-7 menu-two-part"
         ng-class="$ctrl.nbDossierFiles > 0 ? 'has-documents' : ''">
      <div class="horizontal-menu">
        <div ng-repeat="navItem in $ctrl.navItems" class="menu-item menu-item-nav tw-relative tw-group"
             ng-class="{'menu-item-active': $ctrl.isActivedRoute(navItem)}">
          <a ng-click="$ctrl.onClick(navItem)">
            <!-- @todo Passer en "<div>" et supprimer "tw-block" ? -->
            <span ng-if="navItem.code === 'DASHBOARD'" class="tw-block">
              <fa-icon [icon]="'tachometer-alt'"></fa-icon>
            </span>

            <span ng-if="navItem.code === 'FICHE'" class="tw-block"><fa-icon [icon]="['far', 'copy']"></fa-icon></span>

            <span ng-if="navItem.code === 'MANDATS'" class="tw-block">
              <fa-icon [icon]="['far', 'edit']"></fa-icon>
            </span>

            <span ng-if="navItem.code === 'GESTION'" class="tw-block"><fa-icon [icon]="'key'"></fa-icon></span>

            <span ng-if="navItem.code === 'EVALUATION'" class="tw-block">
              <fa-icon [icon]="'chart-line'"></fa-icon>
            </span>

            <span ng-if="navItem.code === 'RAPPORTS'" class="tw-block">
              <fa-icon [icon]="['far', 'edit']"></fa-icon>
            </span>

            <span ng-if="navItem.code === 'DIFFUSION'" class="tw-block"><fa-icon [icon]="'rss'"></fa-icon></span>

            <span ng-if="navItem.code === 'DOCUMENTS'" class="tw-block documents">
              <fa-icon [icon]="'file'"></fa-icon>
            </span>

            <span ng-if="navItem.code === 'SUIVI'" class="tw-block">
              <fa-icon [icon]="['far', 'calendar']"></fa-icon>
            </span>

            {{navItem.label | translate: '{nbDocuments: ' + ($ctrl.nbDossierFiles || 0) + '}'}}
          </a>

          <div ng-if="navItem.code === 'EVALUATION' && $ctrl.isActivedRoute(navItem) && $ctrl.estimationNotestim && $ctrl.estimationNotestim.isInit"
               class="tw-absolute tw-top-full tw-right-0 tw-hidden group-hover:tw-block tw-z-100">
            <div class="menu-item-submenu tw-rounded-b-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-py-1">
              <div class="tw-py-1">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.updateNotestim()">
                  <fa-icon [icon]="'arrows-rotate'"></fa-icon> <span>Mettre à jour</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="menu-item menu-item-more">
          <div ng-click="$ctrl.toggleMore = !$ctrl.toggleMore">
            <fa-icon [icon]="'ellipsis-h'"></fa-icon>
          </div>

          <div class="vertical-menu" ng-class="{'active': $ctrl.toggleMore}">
            <div ng-repeat="navItem in $ctrl.navItems" class="menu-item menu-item-nav tw-text-center"
                 ng-class="{'menu-item-active': $ctrl.isActivedRoute(navItem)}">
              <a ng-click="$ctrl.onClick(navItem)">
                <span ng-if="navItem.code === 'DASHBOARD'" class="tw-block">
                  <fa-icon [icon]="'tachometer-alt'"></fa-icon>
                </span>

                <span ng-if="navItem.code === 'FICHE'" class="tw-block">
                  <fa-icon [icon]="['far', 'copy']"></fa-icon>
                </span>

                <span ng-if="navItem.code === 'MANDATS'" class="tw-block">
                  <fa-icon [icon]="['far', 'edit']"></fa-icon>
                </span>

                <span ng-if="navItem.code === 'GESTION'" class="tw-block"><fa-icon [icon]="'key'"></fa-icon></span>

                <span ng-if="navItem.code === 'EVALUATION'" class="tw-block">
                  <fa-icon [icon]="'chart-line'"></fa-icon>
                </span>

                <span ng-if="navItem.code === 'RAPPORTS'" class="tw-block">
                  <fa-icon [icon]="['far', 'edit']"></fa-icon>
                </span>

                <span ng-if="navItem.code === 'DIFFUSION'" class="tw-block"><fa-icon [icon]="'rss'"></fa-icon></span>

                <span ng-if="navItem.code === 'DOCUMENTS'" class="tw-block documents">
                  <fa-icon [icon]="'file'"></fa-icon>
                </span>

                <span ng-if="navItem.code === 'SUIVI'" class="tw-block">
                  <fa-icon [icon]="['far', 'calendar']"></fa-icon>
                </span>

                {{navItem.label | translate: '{nbDocuments: ' + ($ctrl.nbDossierFiles || 0) + '}'}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
