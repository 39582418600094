<div class="tw-form-2col tw-form-2col-without-pb tw-h-full">
  @if (iframeUrl$ | async; as iframeUrl) {
    <!-- Reset des marges ci-dessus pour utiliser la largeur complète de l'écran -->
    <div class="tw-h-[calc(100%_+_2rem)] -tw-my-4 sm:-tw-m-4">
      <app-iframe [src]="iframeUrl" class="tw-h-full tw-pt-px"/>
    </div>
  } @else {
    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
  }
</div>
